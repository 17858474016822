import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import { compose } from "redux";
import { withSnackbar } from "notistack";
import { withRouter } from "react-router-dom";
import {
  Grid,
  Divider,
  TextField,
  Button,
  Typography,
} from "@mui/material";
import {
  AccountCircle as UserIcon,
  ThumbUpAlt as LikeIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { withStyles } from "@mui/styles";

import "./detalleMaterialDocente.css";
import Contenido from "../../../components/Common/Contenido";
import { thousandSeparator } from "../../../utils/utils";
import {
  getRequest,
  postRequest,
  getFileRequest,
  deleteRequest,
} from "../../../utils/axios";

import Comentarios from "../../../components/Comentarios/Comentarios";
import CustomerLinearProgress from "../../../common/linearProgress";
import { LinkButton, CustomerButton } from "../../../common/buttons";
import ReactQuillCustom from "../../../common/ReactQuill";

const styles = (theme) => ({
  Container__padding: {
    textAlign: "left",
    width: "auto",
    height: "fit-content",
    backgroundColor: "transparent",
    paddingLeft: "calc(5% + 20px)",
    paddingRight: "calc(5% + 20px)",
    display: "flex",
    flexDirection: "column",
    margin: "10px 0px",
  },
});

class MaterialGrande extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      usuario: props.usuario,
      material: props.material,
      n_visitas: props.material.n_visitas,
      n_likes: props.material.likes.length,
      oas: [],
      current_OA: 0, //
      current_descripcion: "",
      like: false,
    };
  }

  componentDidMount() {
    let user_en_lista = false;
    for (let index = 0; index < this.state.material.likes.length; index++) {
      const element = this.state.material.likes[index];
      if (element.id === this.state.usuario.id) {
        user_en_lista = true;
        break;
      }
    }
    this.setState({
      like: user_en_lista,
    });
  }

  handleOA = (e) => {
    this.setState({ current_OA: e.target.value });
  };

  handleLike = async (e) => {
    if (e != null) e.preventDefault();
    var data = {
      id_usuario: this.state.usuario.id,
      id_material: this.state.material.id,
      add: !this.state.like,
    };

    const response = await postRequest(
      `${process.env.REACT_APP_API_URL}/api/bdocente/like/`,
      data
    );
    if (response.status === 200) {
      this.setState({
        like: !this.state.like,
        n_likes:
          !this.state.like === true
            ? this.state.n_likes + 1
            : this.state.n_likes - 1,
      });
    }
  };

  handleDownload = async () => {
    try {
      await getFileRequest(
        `${process.env.REACT_APP_API_URL}/api/bdocente/${this.state.material.id}/descargar/`,
        {},
        this.state.material.titulo,
        this.state.material.extension
      );
    } catch (e) {
      //SHOW SNACK ERROR
      this.props.enqueueSnackbar(
        `No se pudo descargar el material ${this.state.material.titulo}`
      );
    }
  };

  handleDelete = async () => {
    try {
      await deleteRequest(
        `${process.env.REACT_APP_API_URL}/api/bdocente/${this.state.material.id}/`
      );

      this.props.enqueueSnackbar(
        `Material ${this.state.material.titulo} eliminado con exito`
      );
      this.props.history.push("/biblioteca-docente/");
    } catch (e) {
      console.log(e);
      //SHOW SNACK ERROR
      this.props.enqueueSnackbar(
        `No se pudo eliminar la material ${this.state.material.titulo}`
      );
    }
  };

  render() {
    const { theme } = this.props;
    return (
      <Grid container spacing={2} style={{ marginTop: "20px" }}>
        {this.state.usuario.id === this.state.material.id_usuario.id ? (
          <div style={{ width: "100%" }}>
            <div className="boton__subirContainerA">
              <LinkButton
                className="boton__subirMaterialA"
                text="Editar"
                to={`/biblioteca-docente/editar/${this.state.material.id}`}
                color={theme.bdocente}
                child={<EditIcon className="boton__subirMaterialIconA" />}
              />
              <CustomerButton
                className="boton__subirMaterialC"
                text="Eliminar"
                handle={this.handleDelete}
                color={theme.eliminar}
                child={<DeleteIcon className="boton__subirMaterialIconC" />}
              />
            </div>
          </div>
        ) : null}
        {/* Preview del pdf, word, o excel */}
        <Grid item xs={12} md={4} className="materialGrande__img_container">
          <img
            src={`${process.env.REACT_APP_API_URL}/miniaturas/bdocente/${this.state.material.id}/`}
            alt="material"
            className="materialGrande__img"
          />
        </Grid>

        {/* Información del documento */}
        <Grid item xs={12} md={8} className="materialGrande__descripcion">
          <Grid
            container
            direction="column"
            className="materialGrande__descripcionContainer"
          >
            {/* Título */}
            <Grid item xs={12}>
              <h2 className="materialGrande__titulo">
                {" "}
                {this.state.material.titulo}
              </h2>
            </Grid>
            {/* Fecha, visitas, likes */}
            <Grid item xs={12}>
              <Grid container spacing={2} justifyContent="space-between">
                <Grid item>
                  <p>
                    {" "}
                    {thousandSeparator(this.state.n_visitas)} vistas • Se
                    publicó el{" "}
                    {moment(this.state.material.fecha_creacion).format("ll")}
                  </p>
                </Grid>
                <Grid item style={{ justifyContent: "flex-end" }}>
                  <Button
                    className="material__detalle_likeButton"
                    onClick={this.handleLike}
                  >
                    <LikeIcon
                      className="material__detalle_likeIcon"
                      style={{
                        color: this.state.like
                          ? this.props.theme.likeBlue
                          : "grey",
                      }}
                    />
                    {thousandSeparator(this.state.n_likes)}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            {/* Divider */}
            <Grid
              item
              xs={12}
              style={{ marginTop: "10px", paddingBottom: "10px" }}
            >
              <Divider />
            </Grid>
            {/* Autor */}
            <Grid item xs={12}>
              <Grid container>
                <Grid item style={{ width: "70px", margin: 0 }}>
                  {this.state.material.id_usuario.foto_perfil &&
                    this.state.material.id_usuario.foto_perfil !== "" ? (
                    <img
                      src={`${process.env.REACT_APP_API_URL}/miniaturas/usuarios_min/${this.state.material.id_usuario.id}`}
                      // className="usuario_icon"
                      style={{
                        height: "50px !important",
                        borderRadius: "50%",
                      }}
                      alt="foto"
                    />
                  ) : (
                    <UserIcon style={{ fontSize: "50px" }} />
                  )}
                </Grid>
                <Grid item style={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    variant="body2"
                    component="p"
                    className="material__detalle__texto_secundario autor"
                  >
                    <span>
                      {this.state.material.id_usuario.primer_nombre +
                        " " +
                        this.state.material.id_usuario.apellido_paterno}
                    </span>
                  </Typography>
                </Grid>
                <Grid
                  item
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "10px",
                  }}
                >
                  {/* <Button
                    color="primary"
                    variant="contained"
                    component={Link}
                    to={`/perfil/${this.state.material.id_usuario.id}`}
                  >
                    {" "}
                    Ver perfil
                  </Button> */}
                </Grid>
              </Grid>
            </Grid>
            {/* Divider */}
            <Grid
              item
              xs={12}
              style={{ marginTop: "10px", paddingBottom: "10px" }}
            >
              {/* <Divider/> */}
            </Grid>
            {/* Descripción */}
            <Grid item xs={12}>
              <div>
                <ReactQuillCustom
                  value={this.props.material.descripcion}
                  readOnly={true}
                  theme={"bubble"}
                />
              </div>
            </Grid>
            {/* Divider */}
            <Grid
              item
              xs={12}
              style={{ marginTop: "10px", paddingBottom: "10px" }}
            >
              <Divider />
            </Grid>
            {/* Propiedades */}
            <Grid item xs={12}>
              <Grid container justifyContent="center">
                <Grid
                  item
                  style={{
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "25px",
                    backgroundColor: "#9510ac",
                    padding: "10px",
                    margin: "10px",
                    color: "white",
                  }}
                >
                  {this.state.material.tipo_material_docente.nombre}
                </Grid>
                <Grid
                  item
                  style={{
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "25px",
                    backgroundColor: "#8fd14f",
                    padding: "10px",
                    margin: "10px",
                    color: "white",
                  }}
                >
                  {this.state.material.id_nivel.nombre}
                </Grid>
                <Grid
                  item
                  style={{
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "25px",
                    backgroundColor: "#2d9bf0",
                    padding: "10px",
                    margin: "10px",
                    color: "white",
                  }}
                >
                  {this.state.material.id_area.nombre}
                </Grid>
                {this.state.oas.map((oa) => (
                  <Grid
                    key={oa.id}
                    item
                    style={{
                      display: "flex",
                      alignItems: "center",
                      borderRadius: "25px",
                      backgroundColor: "#da0063",
                      padding: "10px",
                      margin: "10px",
                      color: "white",
                    }}
                  >
                    {oa.codigo}
                  </Grid>
                ))}
              </Grid>
            </Grid>
            {/* Divider */}
            <Grid
              item
              xs={12}
              style={{ marginTop: "10px", paddingBottom: "10px" }}
            >
              <Divider />
            </Grid>
            {/* Objetivos de aprendizaje */}
            <Grid item xs={12}>
              <Grid container direction="column" spacing={2}>
                {/* Titulo */}
                <Grid item xs={12}>
                  <h3 className="oa__titulo">
                    {" "}
                    Objetivos de Aprendizaje asociados
                  </h3>
                </Grid>
                {/* Selector de OA*/}
                <Grid item xs={12}>
                  <TextField
                    name="selector_de_oa"
                    onChange={this.handleOA}
                    select
                    SelectProps={{ native: true }}
                    InputLabelProps={{ shrink: true }}
                    className="oa__selector"
                    variant="outlined"
                    size="small"
                    defaultValue={0}
                  >
                    {this.state.material.oas.map((oa, index) => (
                      <option key={oa.id} value={index}>
                        {oa.codigo}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                {/* Descripción del OA */}
                <Grid item xs={12}>
                  {this.state.material.oas[this.state.current_OA].descripcion}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* Descargar */}
          <Grid container className="boton__descargarContainer">
            <Grid item xs={12} className="boton__descargar">
              <Button
                color="primary"
                variant="contained"
                style={{
                  width: "100%",
                  margin: "0px",
                  alignSelf: "flex-end",
                  display: "flex",
                }}
                onClick={this.handleDownload}
              >
                {" "}
                Descargar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

class DetalleMaterial extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      material_id: props.match.params.id,
      material: null,
      comentarios_base: [],
      comentarios_hijos: [],
      loading: true,
    };
  }

  async componentDidMount() {
    let component = this;
    let material = null;
    let comentarios_base = [];
    let comentarios_hijos = [];

    async function getMaterial() {
      await getRequest(
        `${process.env.REACT_APP_API_URL}/api/bdocente/${component.props.match.params.id}/`
      ).then((response) => {
        material = response.data;
      });
    }

    async function getComentarios() {
      await getRequest(
        `${process.env.REACT_APP_API_URL}/api/bdocente/comentarios/${component.props.match.params.id}/`
      )
        .then((response) => {
          comentarios_base = response.data.comentarios_base;
          comentarios_hijos = response.data.comentarios_hijos;
        })
        .catch((e) => {
          console.log(e);
        });
    }

    await getMaterial();
    await getComentarios();
    component.setState({
      material,
      comentarios_base,
      comentarios_hijos,
      loading: false,
    });
  }

  render() {
    const { classes, theme } = this.props;
    let componente;
    if (this.state.loading) {
      return <CustomerLinearProgress />;
    } else if (!this.state.material) {
      componente = <div>No se encontró material</div>;
    } else {
      componente = (
        <div>
          {/* Resultados */}
          <div className={classes.Container__padding}>
            {this.state.material !== null ? (
              <div>
                <MaterialGrandeCustomer material={this.state.material} />
                <Comentarios
                  urlPost={`${process.env.REACT_APP_API_URL}/api/bdocente/comentarios/`}
                  comentarios_base={this.state.comentarios_base}
                  comentarios_hijos={this.state.comentarios_hijos}
                  id_recurso={this.state.material_id}
                  tipo_recurso="id_material_docente"
                />
              </div>
            ) : null}
          </div>
        </div>
      );
    }
    return (
      <Contenido
        componente={componente}
        seccion={"Detalle Material Docente"}
        descripcion={"Material hecho por docentes para docente"}
        backgroundColor={theme.bdocente}
      ></Contenido>
    );
  }
}

const mapStateToProps = (state) => ({
  usuario: state.auth.usuario,
});

export var MaterialGrandeCustomer = compose(
  connect(mapStateToProps, {}),
  withStyles(styles, { withTheme: true })
)(withSnackbar(withRouter(MaterialGrande)));

export default compose(
  connect(mapStateToProps, {}),
  withStyles(styles, { withTheme: true })
)(DetalleMaterial);
