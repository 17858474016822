import React from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { getRequest, postRequest } from "../../../utils/axios";
import { Card, TextField, Grid, Button } from "@mui/material";
import { withStyles } from "@mui/styles";
import { withSnackbar } from "notistack";
import FilesDropzone from "../../../common/FileDropzone";
import Contenido from "../../../components/Common/Contenido";
import "./SubirCapsula.css";
import "../../../App.css";
import { CustomerButton } from "../../../common/buttons";
import FormData from "form-data";
import mimeTypes from "./mimeTypes";
import CircularProgressWithLabel from "../../../common/CircularProgressWithLabel";
import ReactQuillCustom from "../../../common/ReactQuill";

const styles = () => ({
  error: {
    color: "red",
    fontSize: "11px",
    paddingLeft: "10px",
  },
});

class SubirCapsula extends React.Component {
  state = {
    capsula: {
      titulo: "",
      autor: `${this.props.usuario.primer_nombre} ${this.props.usuario.apellido_paterno}`,
      id_area: "Seleccionar",
      id_metodologia: "Seleccionar",
      id_nivel: "-1",
      descripcion: "",
      archivo: null,
      extension: "",
      //palabrasclaves: [],
      id_usuario: this.props.usuario.id,
    },
    errors: {
      titulo: "",
      autor: "",
      id_area: "",
      id_metodologia: "",
      id_nivel: "",
      descripcion: "",
      archivo: "",
    },
    progressBarPercentage: 0,
    submited: false,
    loading: false,
    areas: [{ codigo: 0, nombre: "Seleccionar" }],
    metodologias: [{ nombre: "Seleccionar" }],
    niveles: [{ nombre: "Seleccionar", codigo: "-1" }],
  };

  componentDidMount() {
    let component = this;

    async function getAreas() {
      getRequest(
        `${process.env.REACT_APP_API_URL}/api/curriculum_escolar/areas/`
      ).then((response) => {
        const res_areas = response.data;
        res_areas.unshift({ codigo: 0, nombre: "Seleccionar" });
        component.setState({ areas: res_areas });
      });
    }
    async function getMetodologias() {
      getRequest(`${process.env.REACT_APP_API_URL}/api/metodologias/`).then(
        (response) => {
          const res_metodologias = response.data;
          res_metodologias.unshift({ nombre: "Seleccionar" });
          component.setState({ metodologias: res_metodologias });
        }
      );
    }
    async function getNiveles() {
      getRequest(
        `${process.env.REACT_APP_API_URL}/api/curriculum_escolar/niveles/`
      ).then((response) => {
        const res_niveles = response.data;
        res_niveles.unshift({
          codigo: "-1",
          nombre: "Seleccionar",
          ciclo: 0,
        });
        component.setState({ niveles: res_niveles });
      });
    }
    getAreas();
    getMetodologias();
    getNiveles();
  }

  onChange = (e) => {
    this.setState({
      capsula: {
        ...this.state.capsula,
        [e.target.name]: e.target.value,
      },
    });
  };

  setFileInfo = (extension, raw_file) => {
    this.setState({
      capsula: {
        ...this.state.capsula,
        extension: extension,
        archivo: raw_file,
      },
    });
  };

  handleUpload = async (e) => {
    e.preventDefault();
    let isValid = true;
    let errors = {
      titulo: "",
      autor: "",
      id_area: "",
      id_metodologia: "",
      id_nivel: "",
      descripcion: "",
      archivo: "",
    };
    if (!this.state.capsula.titulo) {
      errors.titulo = "Debe ingresar un título";
      isValid = false;
    }
    if (this.state.capsula.titulo.length > 100) {
      errors.titulo = "Puede ingresar hasta 100 caracteres";
      isValid = false;
    }
    if (this.state.capsula.descripcion.length > 1000) {
      errors.descripcion = "Puede ingresar hasta 1000 caracteres";
      isValid = false;
    }
    if (this.state.capsula.id_area === "Seleccionar") {
      errors.id_area = "Debe seleccionar una area";
      isValid = false;
    }
    if (this.state.capsula.id_metodologia === "Seleccionar") {
      errors.id_metodologia = "Debe seleccionar una metodologia";
      isValid = false;
    }
    if (this.state.capsula.id_nivel === "-1") {
      errors.id_nivel = "Debe seleccionar un nivel";
      isValid = false;
    }
    if (!this.state.capsula.descripcion) {
      errors.descripcion = "Debe ingresar una descripción";
      isValid = false;
    }
    if (!this.state.capsula.archivo) {
      errors.archivo = "Debe subir un video";
      isValid = false;
    }
    if (!mimeTypes.includes(this.state.capsula.extension)) {
      errors.archivo =
        "El video no es válido. Debe subir un archivo .mp4, .avi, .mpeg o .mov";
      isValid = false;
    }
    this.setState({ errors: errors });
    if (isValid) {
      const config = {
        onUploadProgress: (progressEvent) => {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          this.setState({ progressBarPercentage: percentCompleted });
        },
      };
      this.setState({ loading: true });
      let response;
      try {
        var data = new FormData();
        data.append("archivo", this.state.capsula.archivo);
        data.append("titulo", this.state.capsula.titulo);
        data.append("descripcion", this.state.capsula.descripcion);
        data.append("extension", this.state.capsula.extension);
        data.append("id_area", this.state.capsula.id_area);
        data.append("id_metodologia", this.state.capsula.id_metodologia);
        data.append("id_nivel", this.state.capsula.id_nivel);
        response = await postRequest(
          `${process.env.REACT_APP_API_URL}/api/capsulas/`,
          data,
          config
        );

        if (response.status === 201) {
          this.setState({
            submited: true,
          });
          this.props.enqueueSnackbar(
            `Capsula ${this.state.capsula.titulo} agregada exitosamente!`
          );
        }
      } catch (e) {
        console.log(e);
        this.props.enqueueSnackbar("No se pudo subir la capsula");
      } finally {
        this.setState({ loading: false });
      }
    }
  };

  render() {
    const { theme, classes } = this.props;
    let componente;
    if (this.state.loading) {
      componente = (
        <div className="root loading">
          <CircularProgressWithLabel
            value={this.state.progressBarPercentage}
          />
          <h3>Subiendo cápsula</h3>
        </div>
      );
    } else if (this.state.submited) {
      return <Redirect to="/capsulas" />;
    } else {
      componente = (
        <div className="root">
          <Card variant="outlined" className="container">
            {this.state.errors.archivo ? (
              <p className="error">{this.state.errors.archivo}</p>
            ) : null}
            <FilesDropzone
              className="dropzone_capsulas"
              setFileInfo={this.setFileInfo}
              color={theme.capsula}
              archivo={this.state.capsula.archivo}
            />
            <form className="form_Capsulas" onSubmit={this.handleUpload}>
              <Grid container spacing={2} justifyContent="center">
                <Grid container item justifyContent="center">
                  <p className="label">Título</p>
                  <TextField
                    variant="outlined"
                    size="small"
                    name="titulo"
                    value={this.state.capsula.titulo}
                    onChange={this.onChange}
                    className="input"
                    helperText={this.state.errors.titulo}
                    error={Boolean(this.state.errors.titulo)}
                  />
                </Grid>

                <Grid container item justifyContent="center">
                  <p className="label">Autor/a</p>
                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    disabled
                    variant="outlined"
                    size="small"
                    name="autor"
                    value={this.state.capsula.autor}
                    className="input"
                  />
                </Grid>
                <Grid container item justifyContent="center">
                  <p className="label">Área</p>
                  <TextField
                    name="id_area"
                    onChange={this.onChange}
                    select
                    SelectProps={{ native: true }}
                    value={this.state.capsula.id_area}
                    defaultValue={this.state.capsula.id_area}
                    variant="outlined"
                    size="small"
                    className="input"
                    error={Boolean(this.state.errors.id_area)}
                    helperText={this.state.errors.id_area}
                  >
                    {this.state.areas.map((area) => {
                      return (
                        <option key={area.codigo} value={area.nombre}>
                          {area.nombre}
                        </option>
                      );
                    })}
                  </TextField>
                </Grid>
                <Grid container item justifyContent="center">
                  <p className="label">Metodologia Activa</p>
                  <TextField
                    name="id_metodologia"
                    onChange={this.onChange}
                    select
                    SelectProps={{ native: true }}
                    value={this.state.capsula.id_metodologia}
                    defaultValue={this.state.capsula.id_metodologia}
                    variant="outlined"
                    size="small"
                    className="input"
                    error={Boolean(this.state.errors.id_metodologia)}
                    helperText={this.state.errors.id_metodologia}
                  >
                    {this.state.metodologias.map((metodologia) => {
                      return (
                        <option
                          key={metodologia.nombre}
                          value={metodologia.nombre}
                        >
                          {metodologia.nombre}
                        </option>
                      );
                    })}
                  </TextField>
                </Grid>
                <Grid container item justifyContent="center">
                  <p className="label">Nivel</p>
                  <TextField
                    name="id_nivel"
                    onChange={this.onChange}
                    select
                    SelectProps={{ native: true }}
                    value={this.state.capsula.id_nivel}
                    defaultValue={this.state.capsula.id_nivel}
                    variant="outlined"
                    size="small"
                    className="input"
                    error={Boolean(this.state.errors.id_nivel)}
                    helperText={this.state.errors.id_nivel}
                  >
                    {this.state.niveles.map((nivel) => {
                      return (
                        <option key={nivel.codigo} value={nivel.codigo}>
                          {nivel.nombre}
                        </option>
                      );
                    })}
                  </TextField>
                </Grid>
                <Grid container item justifyContent="center">
                  <p className="label">Descripción</p>
                  <div className="input">
                    <ReactQuillCustom
                      value={this.state.capsula.descripcion}
                      onChange={(value) =>
                        this.onChange({
                          target: { name: "descripcion", value },
                        })
                      }
                    />
                    <span className={classes.error}>
                      {this.state.errors.descripcion}
                    </span>
                  </div>
                </Grid>
                {/*
                <Grid container item>

                  <p className="label">Palabras claves</p>
                  <TextField
                    variant="outlined"
                    size="small"
                    multiline
                    rows={7}
                    name="descripcion"
                    value={this.state.capsula.palabrasclaves}
                    onChange={this.onChange}
                    className="input"
                  />
                </Grid>
                */}
              </Grid>
            </form>
            <div className="buttonContainer">
              <CustomerButton
                type="submit"
                color={theme.guardar}
                handle={(e) => this.handleUpload(e)}
                text={"Guardar"}
              />
              <Button
                color="secondary"
                variant="contained"
                component={Link}
                to="/capsulas/"
              >
                Cancelar
              </Button>
            </div>
          </Card>
        </div>
      );
    }
    return (
      <Contenido
        componente={componente}
        seccion={"Subir cápsula"}
        descripcion={"Material pedagógico para docentes"}
        backgroundColor={"#f67e66"}
      ></Contenido>
    );
  }
}

const mapStateToProps = (state) => ({
  usuario: state.auth.usuario,
});

export default compose(
  connect(mapStateToProps, {}),
  withStyles(styles, { withTheme: true })
)(withSnackbar(SubirCapsula));
