import React from "react";
import { Link } from "react-router-dom";
import { DrawerContext } from "../../context/DrawerContext";
import Contenido from "../../components/Common/Contenido";
import Material from "../../components/BibliotecaDocente/Material";
import { getRequest } from "../../utils/axios";
import "./BibliotecaDocente.css";
import { TextField, Button, Grid, Divider, Hidden } from "@mui/material";
import { withStyles } from "@mui/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DescriptionIcon from '@mui/icons-material/Description';
import Pagination from '@mui/material/Pagination'; // Paginamiento
import SkeletonDocente from "../../components/BibliotecaDocente/SkeletonDocente";

const styles = (theme) => ({});
class BibliotecaDocente extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buscador: {
        titulo: "",
        tipo_material_docente: "",
        id_nivel: "",
        id_area: "",
        id_unidad: "",
        oa: "",
        pagina: 1,
        tamano_pagina: 10,
        num_pages: -1,
        num_results: -1,
        order: "fecha",
      },
      tipo_material_docente: [{ id: -1, nombre: "Seleccionar" }],
      niveles: [{ nombre: "Seleccionar", codigo: "" }],
      areas: [{ nombre: "Seleccionar", codigo: "" }],
      unidades: [{ id: -1, nombre: "Seleccionar", codigo: "" }],
      oas: [{ id: -1, codigo: "Seleccionar" }],
      materiales: [],
      planificaciones: [],
      guias_didacticas: [],
      guias_ejercicios: [],
      evaluaciones: [],
      otro: [],
      Button: true,
      orders: [
        { nombre: "Fecha", codigo: "fecha" },
        { nombre: "Número de visitas", codigo: "n_visitas" },
      ],
      tamano_pagina_opciones: [
        { id: 0, valor: 5 },
        { id: 1, valor: 10 },
        { id: 2, valor: 20 },
        { id: 3, valor: 50 },
        { id: 4, valor: 100 },
      ],
      cargando: true,
      buscado: false, // Para cambiar Novedades por Resultados
    };
  }

  componentDidMount() {
    this.context.setCurrentDrawerItem("Biblioteca Docente");
    let component = this;

    async function getAreas() {
      getRequest(
        `${process.env.REACT_APP_API_URL}/api/curriculum_escolar/areas/`
      ).then((response) => {
        const res_areas = response.data;
        res_areas.unshift({ nombre: "Seleccionar", codigo: "" });
        component.setState({ areas: res_areas });
      });
    }
    async function getNiveles() {
      getRequest(
        `${process.env.REACT_APP_API_URL}/api/curriculum_escolar/niveles/`
      ).then((response) => {
        const res_niveles = response.data;
        res_niveles.unshift({
          codigo: "",
          nombre: "Seleccionar",
          ciclo: 0,
        });
        component.setState({ niveles: res_niveles });
      });
    }
    async function getTipoMaterialDocente() {
      getRequest(`${process.env.REACT_APP_API_URL}/api/bdocente/tipo/`).then(
        (response) => {
          const res_tipo_material_docente = response.data;
          res_tipo_material_docente.unshift({
            id: -1,
            nombre: "Seleccionar",
          });
          component.setState({
            tipo_material_docente: res_tipo_material_docente,
          });
        }
      );
    }

    async function getMaterialesDocentes() {
      getRequest(`${process.env.REACT_APP_API_URL}/api/bdocente/`, {
        pagina: 1,
        tamano_pagina: 10,
      })
        .then((response) => {
          component.setState({
            materiales: response.data.material,
            buscador: {
              ...component.state.buscador,
              num_pages: response.data.num_pages,
              num_results: response.data.num_results,
            },
            cargando: false,
          });
        })
        .catch((e) => {
          component.setState({ cargando: false });
          console.log(e);
        });
    }

    getAreas();
    getNiveles();
    getTipoMaterialDocente();
    getMaterialesDocentes();
  }

  async updateAreas(component, nivel) {
    getRequest(
      `${process.env.REACT_APP_API_URL}/api/curriculum_escolar/areas/?nivel=${nivel}`
    ).then((response) => {
      const res_areas = response.data;
      res_areas.unshift({ nombre: "Seleccionar", codigo: "" });
      component.setState({ areas: res_areas });
    });
  }
  async updateNiveles(component, area) {
    if (area === "Seleccionar") area = "";

    getRequest(
      `${process.env.REACT_APP_API_URL}/api/curriculum_escolar/niveles/?area=${area}`
    ).then((response) => {
      const res_niveles = response.data;
      res_niveles.unshift({ codigo: "", nombre: "Seleccionar", ciclo: 0 });
      component.setState({ niveles: res_niveles });
    });
  }

  async getUnidades(component, nivel, area) {
    if ((nivel === "") | (area === "")) {
      return;
    }

    var req = () =>
      getRequest(
        `${process.env.REACT_APP_API_URL}/api/curriculum_escolar/unidades/?area=${area}&nivel=${nivel}`
      ).then((response) => {
        const res_unidades = response.data;
        res_unidades.unshift({ id: -1, nombre: "Seleccionar", codigo: "" });
        component.setState(
          {
            unidades: res_unidades,
            // buscador: {...this.state.buscador, ['oa'] : "",}
          },
          () => { }
        );
      });
    this.setState(
      {
        //buscador: {...this.state.buscador, ['oa'] : ""}
      },
      req
    );
  }

  async getOAS(component, unidad) {
    getRequest(
      `${process.env.REACT_APP_API_URL}/api/curriculum_escolar/oas/?unidad=${unidad}`
    ).then((response) => {
      const res_oas = response.data;
      res_oas.unshift({ id: -1, codigo: "Seleccionar" });
      component.setState({ oas: res_oas });
    });
  }

  onChangeOA = (e) => {
    this.setState({
      buscador: {
        ...this.state.buscador,
        [e.target.name]: e.target.value,
      },
    });
  };

  onChangeUnidad = (e) => {
    this.setState(
      {
        buscador: {
          ...this.state.buscador,
          [e.target.name]: e.target.value,
        },
        oas: [{ id: -1, codigo: "Seleccionar" }],
      },
      () => {
        this.getOAS(this, this.state.buscador.id_unidad);
      }
    );
  };

  onChangeArea = (e) => {
    this.setState(
      {
        buscador: {
          ...this.state.buscador,
          [e.target.name]: e.target.value,
          id_unidad: "",
          oa: "",
        },
        unidades: [{ id: -1, nombre: "Seleccionar", codigo: "" }],
        oas: [{ id: -1, codigo: "Seleccionar" }],
      },
      () => {
        this.updateNiveles(this, this.state.buscador.id_area);
        this.getUnidades(
          this,
          this.state.buscador.id_nivel,
          this.state.buscador.id_area
        );
      }
    );
  };

  onChangeNivel = (e) => {
    this.setState(
      {
        buscador: {
          ...this.state.buscador,
          [e.target.name]: e.target.value,
          //['id_area'] : "", // TODO: llamar a las áreas disponibles de ese nivel
          id_unidad: "",
          oa: "",
        },
        unidades: [{ id: -1, nombre: "Seleccionar", codigo: "" }],
        oas: [{ id: -1, codigo: "Seleccionar" }],
      },
      () => {
        this.updateAreas(this, this.state.buscador.id_nivel);
        this.getUnidades(
          this,
          this.state.buscador.id_nivel,
          this.state.buscador.id_area
        );
      }
    );
  };

  onChangeBuscador = (e) => {
    this.setState(
      {
        buscador: {
          ...this.state.buscador,
          [e.target.name]: e.target.value,
        },
      },
      () => {
        if (e.target.name === "order") this.handleSearch();
      }
    );
  };

  handleSearch = (e) => {
    if (e != null) e.preventDefault();
    let query = this.state.buscador;

    if (query.tipo_material_docente === "-1") {
      query.tipo_material_docente = "";
    }
    if (query.id_nivel === "Seleccionar") {
      query.id_nivel = "";
    }
    if (query.id_area === "Seleccionar") {
      query.id_area = "";
    }
    if (query.id_unidad === "Seleccionar") {
      query.id_unidad = "";
    }
    if (query.oa === "Seleccionar") {
      query.oa = "";
    }

    getRequest(`${process.env.REACT_APP_API_URL}/api/bdocente/`, query).then(
      (response) => {
        this.setState({
          materiales: response.data.material,
          Button,
          buscador: {
            ...this.state.buscador,
            num_pages: response.data.num_pages,
            num_results: response.data.num_results,
          },
          buscado: true,
        });
      }
    );
  };

  handlePagination = (e, value) => {
    this.setState(
      { buscador: { ...this.state.buscador, pagina: parseInt(value) } },
      () => this.handleSearch()
    );
  };

  handleResultadosPorPagina = (e) => {
    this.setState(
      {
        buscador: {
          ...this.state.buscador,
          pagina: 1,
          tamano_pagina: parseInt(e.target.value),
        },
      }, // vuelta a pagina 1, pero cambiando tamano_pagina
      () => this.handleSearch()
    ); // Se realiza nueva bpusqueda
  };

  resetSearch = () => {
    this.setState(
      {
        buscador: {
          titulo: "",
          tipo_material_docente: "",
          id_nivel: "",
          id_area: "",
          id_unidad: "",
          oa: "",
          pagina: 1,
          tamano_pagina: 10,
          num_pages: -1,
          num_results: -1,
          order: "fecha",
        },
      },
      () => this.handleSearch()
    );
  };

  displayMateriales = () => {
    if (this.state.cargando) {
      const array = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
      return (
        <div className="material__docente">
          {array.map((i) => (
            <SkeletonDocente key={i} />
          ))}
        </div>
      );
    } else if (this.state.materiales.length > 0)
      return (
        <div className="material__docente">
          {this.state.materiales.map((material) => (
            <Material material={material} key={material.id.toString()} />
          ))}
        </div>
      );
    return (
      <div className="center">
        <p>No se encontraron materiales</p>
      </div>
    );
  };

  render() {
    const { theme } = this.props;
    const componente = (
      <div>
        <div style={{ width: "100%" }}>
          <div className={"boton__subirContainer"}>
            <Button
            variant="contained"
            startIcon={
              <DescriptionIcon className="boton__subirMaterialIcon" />
            }
            className="boton__instructivo"
            target="_blank"
            href="instructivo.pdf"
            style={{ backgroundColor: theme.dorado }}
          >
            Instructivo
          </Button>
            <Button
              variant="contained"
              startIcon={
                <CloudUploadIcon className="boton__subirMaterialIcon" />
              }
              className="boton__subirMaterial"
              to="/biblioteca-docente/subir"
              style={{ backgroundColor: theme.bdocente }}
              component={Link}
            >
              Subir
            </Button>
          </div>
        </div>
        {/* Buscador */}
        <div className={"buscador__container"}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <h2 className="buscador__titulo">Buscador</h2>
            </Grid>
            <Grid item xs={12}>
              <form>
                <Grid container spacing={2}>
                  {/* <Grid item xs={6} md={2}>
                    <TextField
                      variant="outlined"
                      size="small"
                      className="buscador__buscador"
                      label="Palabra clave ..."
                      name="titulo"
                      onChange={this.onChangeBuscador}
                      value={this.state.buscador.titulo}
                    />
                  </Grid> */}
                  <Grid item xs={12} md={4}>
                    <TextField
                      label="Tipo"
                      name="tipo_material_docente"
                      onChange={this.onChangeBuscador}
                      select
                      SelectProps={{ native: true }}
                      InputLabelProps={{ shrink: true }}
                      value={this.state.buscador.tipo_material_docente}
                      variant="outlined"
                      size="small"
                      className={"buscador__area"}
                    >
                      {this.state.tipo_material_docente.map((tipo) => (
                        <option key={tipo.id} value={tipo.id}>
                          {tipo.nombre}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={6} md={2}>
                    <TextField
                      label="Nivel"
                      name="id_nivel"
                      onChange={this.onChangeNivel}
                      select
                      SelectProps={{ native: true }}
                      InputLabelProps={{ shrink: true }}
                      value={this.state.buscador.id_nivel}
                      variant="outlined"
                      size="small"
                      className={"buscador__area"}
                    >
                      {this.state.niveles.map((nivel) => (
                        <option key={nivel.codigo} value={nivel.codigo}>
                          {nivel.nombre}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={6} md={2}>
                    <TextField
                      label="Area"
                      name="id_area"
                      onChange={this.onChangeArea}
                      select
                      SelectProps={{ native: true }}
                      InputLabelProps={{ shrink: true }}
                      value={this.state.buscador.id_area}
                      variant="outlined"
                      size="small"
                      className={"buscador__area"}
                    >
                      {this.state.areas.map((area) => (
                        <option key={area.nombre} value={area.nombre}>
                          {area.nombre}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={6} md={2}>
                    <TextField
                      label="Unidad"
                      name="id_unidad"
                      onChange={this.onChangeUnidad}
                      select
                      SelectProps={{ native: true }}
                      InputLabelProps={{ shrink: true }}
                      value={this.state.buscador.id_unidad}
                      variant="outlined"
                      size="small"
                      className={"buscador__area"}
                    >
                      {this.state.unidades.map((unidad) => (
                        <option key={unidad.codigo} value={unidad.codigo}>
                          {unidad.nombre}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={6} md={2}>
                    <TextField
                      label="OA"
                      name="oa"
                      onChange={this.onChangeOA}
                      select
                      SelectProps={{ native: true }}
                      InputLabelProps={{ shrink: true }}
                      value={this.state.buscador.oa}
                      variant="outlined"
                      size="small"
                      className={"buscador__area"}
                    >
                      {this.state.oas.map((oa) => (
                        <option key={oa.id} value={oa.id}>
                          {oa.codigo}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                  <Hidden only="xs">
                    <Grid item md={8} />
                  </Hidden>
                  <Grid
                    item
                    xs={8}
                    md={2}
                    className="buscador__botonBuscarContainer"
                  >
                    <Button
                      className="buscador__botonBuscar"
                      style={{
                        background: theme.primary,
                        width: "100%",
                      }}
                      variant="contained"
                      type="submit"
                      onClick={this.handleSearch}
                    >
                      Buscar
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    md={2}
                    className="Buscador__botonBuscarContainer"
                  >
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={this.resetSearch}
                      fullWidth
                    >
                      Limpiar
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
            <Grid item xs={12}>
              <div className={"buscador__divider"}>
                <Divider />
              </div>
            </Grid>
            {/* Order */}
            <Grid item xs={12}>
              <Grid
                container
                style={{ justifyContent: "felx-end !important" }}
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                  md={8}
                  xl={8}
                  sx={{ alignSelf: "felx-start !important" }}
                >
                  <h2 className="resultados__titulo">
                    {this.state.buscado ? "Resultados" : "Novedades"}
                  </h2>
                </Grid>
                <Grid item xs={12} md={2} xl={2}>
                  <TextField
                    label="Orden de resultados"
                    name="order"
                    onChange={this.onChangeBuscador}
                    select
                    SelectProps={{ native: true }}
                    InputLabelProps={{ shrink: true }}
                    //value={this.state.nivel}
                    variant="filled"
                    size="small"
                    style={{ width: "100%" }}
                  >
                    {this.state.orders.map((order) => (
                      <option key={order.codigo} value={order.codigo}>
                        {order.nombre}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} md={2} xl={2}>
                  <TextField
                    label="Resultados por página"
                    name="resultados_por_pagina"
                    onChange={this.handleResultadosPorPagina}
                    select
                    SelectProps={{ native: true }}
                    InputLabelProps={{ shrink: true }}
                    variant="filled"
                    size="small"
                    style={{ width: "100%" }}
                    defaultValue={10}
                  >
                    {this.state.tamano_pagina_opciones.map((tamano) => (
                      <option key={tamano.id} value={tamano.valor}>
                        {tamano.valor}
                      </option>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>

        {/* Resultados */}
        <div className={"resultados__padding"}>
          {this.displayMateriales()}
          {/* Paginamiento */}
          <div className="pagination__center">
            <Pagination
              count={this.state.buscador.num_pages}
              page={this.state.buscador.pagina}
              onChange={this.handlePagination}
              className="paginamiento"
            />
          </div>
        </div>
      </div>
    );
    return (
      <Contenido
        componente={componente}
        seccion={"Biblioteca Docente"}
        descripcion={"Material hecho por docentes para docentes"}
        backgroundColor={theme.bdocente}
      />
    );
  }
}
BibliotecaDocente.contextType = DrawerContext;
export default withStyles(styles, { withTheme: true })(BibliotecaDocente);
