import React from "react";
import Skeleton from "react-loading-skeleton";

class SkeletonCapsula extends React.Component {
  render() {
    return <Skeleton className="skeleton__capsula" />;
  }
}

export default SkeletonCapsula;
