import React from "react";
import LinearProgress from "@mui/material/LinearProgress";

function CustomerLinearProgress() {
  return (
    <div>
      <LinearProgress color="secondary" style={{ height: 10 }} />
    </div>
  );
}

export default CustomerLinearProgress;
