import React from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { getRequest, postRequest } from "../../../utils/axios";
import {
  CircularProgress,
  Card,
  TextField,
  Grid,
  Button,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { withSnackbar } from "notistack";
import FilesDropzone from "../../../common/FileDropzone";
import Contenido from "../../../components/Common/Contenido";
import "../../../App.css";
import { CustomerButton } from "../../../common/buttons";
import mimeTypes from "./mimeTypes";
import FormData from "form-data";
import AddBoxIcon from "@mui/icons-material/AddBox";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ReactQuillCustom from "../../../common/ReactQuill";
import "./subirMaterialDocente.css";

const styles = (theme) => ({});
class SubirMaterialDocente extends React.Component {
  state = {
    autor: `${this.props.usuario.primer_nombre} ${this.props.usuario.apellido_paterno}`,
    material: {
      id_usuario: this.props.usuario.id,
      id_area: "-1", // Form
      id_nivel: "-1", // Form
      oas: [], // Form
      titulo: "", // Form
      descripcion: "", // Form
      tipo_material_docente: "", // Form
      archivo: null,
      extension: "",
    },
    unidad: "", // Auxiliar
    errors: {
      id_nivel: "",
      id_area: "",
      titulo: "",
      descripcion: "",
      oas: "",
      tipo_material_docente: "",
      extension: "",
      //Aux
      unidad: "",
      archivo: "",
    },
    submited: false,
    // Dropdown
    tipo_material_docente: [{ id: 0, nombre: "Seleccionar" }],
    niveles: [{ nombre: "Seleccionar", codigo: "-1" }],
    areas: [{ nombre: "Seleccionar", codigo: "-1" }],
    unidades: [{ id: 0, nombre: "Seleccionar" }],
    oas: [{ id: 0, codigo: "Seleccionar" }],

    oas_in: [],
    oas_out: [],

    display_text_oas_in: [],
    display_text_oas_out: [],
  };

  componentDidMount() {
    let component = this;

    async function getAreas() {
      getRequest(
        `${process.env.REACT_APP_API_URL}/api/curriculum_escolar/areas/`
      ).then((response) => {
        const res_areas = response.data;
        res_areas.unshift({ nombre: "Seleccionar", codigo: "-1" });
        component.setState({ areas: res_areas });
      });
    }
    async function getNiveles() {
      getRequest(
        `${process.env.REACT_APP_API_URL}/api/curriculum_escolar/niveles/`
      ).then((response) => {
        const res_niveles = response.data;
        res_niveles.unshift({
          codigo: "-1",
          nombre: "Seleccionar",
          ciclo: 0,
        });
        component.setState({ niveles: res_niveles });
      });
    }
    async function getTipoMaterialDocente() {
      getRequest(`${process.env.REACT_APP_API_URL}/api/bdocente/tipo/`).then(
        (response) => {
          const res_tipo_material_docente = response.data;
          res_tipo_material_docente.unshift({
            id: -1,
            nombre: "Seleccionar",
          });
          component.setState({
            tipo_material_docente: res_tipo_material_docente,
          });
        }
      );
    }

    getAreas();
    getNiveles();
    getTipoMaterialDocente();
  }

  async updateAreas(component, nivel) {
    getRequest(
      `${process.env.REACT_APP_API_URL}/api/curriculum_escolar/areas/?nivel=${nivel}`
    ).then((response) => {
      const res_areas = response.data;
      res_areas.unshift({ nombre: "Seleccionar", codigo: "-1" });
      component.setState({ areas: res_areas });
    });
  }
  async updateNiveles(component, area) {
    if (area === "Seleccionar") area = "";

    getRequest(
      `${process.env.REACT_APP_API_URL}/api/curriculum_escolar/niveles/?area=${area}`
    ).then((response) => {
      const res_niveles = response.data;
      res_niveles.unshift({ codigo: "-1", nombre: "Seleccionar", ciclo: 0 });
      component.setState({ niveles: res_niveles });
    });
  }

  async getUnidades(component, nivel, area) {
    if ((nivel === "") | (area === "")) {
      return;
    }

    var req = () =>
      getRequest(
        `${process.env.REACT_APP_API_URL}/api/curriculum_escolar/unidades/?area=${area}&nivel=${nivel}`
      ).then((response) => {
        const res_unidades = response.data;
        res_unidades.unshift({ id: -1, nombre: "Seleccionar", codigo: "-1" });
        component.setState(
          {
            unidades: res_unidades,
          },
          () => { }
        );
      });
    this.setState(req);
  }

  async getOAS(component, unidad) {
    getRequest(
      `${process.env.REACT_APP_API_URL}/api/curriculum_escolar/oas/?unidad=${unidad}`
    ).then((response) => {
      const res_oas = response.data;
      //res_oas.unshift({ id: 0, codigo: "Seleccionar" });
      let display = [];
      for (let index = 0; index < response.data.length; index++) {
        display.push(false);
      }

      component.setState({
        oas: res_oas,
        oas_in: [],
        display_text_oas_in: [],
        oas_out: response.data,
        display_text_oas_out: display,
      });
    });
  }

  onAddOA = (index) => {
    let inn = [...this.state.oas_in];
    let out = [...this.state.oas_out];
    let element = out[index];
    inn.push(element);
    out.splice(index, 1);

    // Move display also
    let display_inn = [...this.state.display_text_oas_in];
    let display_out = [...this.state.display_text_oas_out];
    let e = display_out[index];
    display_inn.push(e);
    display_out.splice(index, 1);

    this.setState({
      oas_in: inn,
      oas_out: out,
      display_text_oas_in: display_inn,
      display_text_oas_out: display_out,
    });
  };

  onDeleteOA = (index) => {
    let inn = [...this.state.oas_in];
    let out = [...this.state.oas_out];
    let element = inn[index];
    out.push(element);
    inn.splice(index, 1);

    // Move display also
    let display_inn = [...this.state.display_text_oas_in];
    let display_out = [...this.state.display_text_oas_out];
    let e = display_inn[index];
    display_out.push(e);
    display_inn.splice(index, 1);

    this.setState({
      oas_in: inn,
      oas_out: out,
      display_text_oas_in: display_inn,
      display_text_oas_out: display_out,
    });
  };

  onShowDescription = (index, in_or_out) => {
    if (in_or_out === "in") {
      let display_in = [...this.state.display_text_oas_in];
      display_in[index] = !display_in[index];
      this.setState({ display_text_oas_in: display_in });
    } else {
      let display_out = [...this.state.display_text_oas_out];
      display_out[index] = !display_out[index];
      this.setState({ display_text_oas_out: display_out });
    }
  };

  onChangeOA = (e) => {
    let b = [...this.state.material.oas];
    let i = b.indexOf(Number(e.target.value));
    if (i === -1) {
      // Sólo si no está lo agrego
      b.push(Number(e.target.value));
    }

    this.setState({
      material: {
        ...this.state.material,
        oas: b,
      },
    });
  };

  onChangeUnidad = (e) => {
    this.setState(
      {
        unidad: e.target.value,
        oas: [{ id: -1, codigo: "Seleccionar" }],
        material: {
          ...this.state.material,
          oas: [],
        },
        oas_in: [],
        oas_out: [],
        display_text_oas_in: [],
        display_text_oas_out: [],
      },
      () => {
        this.getOAS(this, this.state.unidad);
      }
    );
  };

  onChangeArea = (e) => {
    this.setState(
      {
        material: {
          ...this.state.material,
          [e.target.name]: e.target.value,
          oas: [],
        },
        unidad: "",
        unidades: [{ id: 0, nombre: "Seleccionar" }],
        oas: [{ id: -1, codigo: "Seleccionar" }],
        oas_in: [],
        oas_out: [],
        display_text_oas_in: [],
        display_text_oas_out: [],
      },
      () => {
        this.updateNiveles(this, this.state.material.id_area);
        this.getUnidades(
          this,
          this.state.material.id_nivel,
          this.state.material.id_area
        );
      }
    );
  };

  onChangeNivel = (e) => {
    this.setState(
      {
        material: {
          ...this.state.material,
          [e.target.name]: e.target.value,
          //id_area: "", // TODO: llamar a las áreas disponibles de ese nivel
          oas: [],
        },
        unidad: "",
        unidades: [{ id: -1, nombre: "Seleccionar", codigo: "-1" }],
        oas: [{ id: -1, codigo: "Seleccionar" }],
        oas_in: [],
        oas_out: [],
        display_text_oas_in: [],
        display_text_oas_out: [],
      },
      () => {
        this.updateAreas(this, this.state.material.id_nivel);
        this.getUnidades(
          this,
          this.state.material.id_nivel,
          this.state.material.id_area
        );
      }
    );
  };

  onChange = (e) => {
    this.setState({
      material: {
        ...this.state.material,
        [e.target.name]: e.target.value,
      },
    });
  };

  setFileInfo = (extension, raw_file) => {
    this.setState({
      material: {
        ...this.state.material,
        extension: extension,
        archivo: raw_file,
      },
    });
  };

  handleUpload = async (e) => {
    e.preventDefault();
    let isValid = true;
    let errors = {
      id_nivel: "",
      id_area: "",
      titulo: "",
      descripcion: "",
      oas: "",
      tipo_material_docente: "",
      extension: "",
      // Aux
      unidad: "",
    };
    if (this.state.material.id_area === "-1") {
      errors.id_area = "Debe ingresar una area";
      isValid = false;
    }
    if (this.state.material.id_nivel === "-1") {
      errors.id_nivel = "Debe ingresar un nivel";
      isValid = false;
    }
    if (this.state.unidad === "-1") {
      errors.unidad = "Debe ingresar una unidad";
      isValid = false;
    }
    if (!this.state.material.titulo) {
      errors.titulo = "Debe ingresar un título";
      isValid = false;
    }
    if (this.state.material.titulo.length > 100) {
      errors.titulo = "Puede ingresar hasta 100 caracteres";
      isValid = false;
    }
    if (this.state.material.descripcion.length > 1000) {
      errors.descripcion = "Puede ingresar hasta 1000 caracteres";
      isValid = false;
    }
    if (this.state.material.descripcion.length === 0) {
      errors.descripcion = "Debe ingresar una descripción";
      isValid = false;
    }
    if (this.state.oas_in.length === 0) {
      errors.oas = "Debe ingresar al menos un OA";
      isValid = false;
    }
    if (this.state.material.tipo_material_docente === "") {
      errors.tipo_material_docente =
        "Debe especificar el tipo de material docente";
      isValid = false;
    }
    if (!this.state.material.archivo) {
      errors.archivo = "Debe subir un material";
      isValid = false;
    }

    if (!mimeTypes.includes(this.state.material.extension)) {
      errors.archivo =
        "El material no es válido. Debe subir un archivo word, pdf, ppt o excel.";
      isValid = false;
    }
    this.setState({ errors: errors });
    if (isValid) {
      this.setState({ loading: true });
      let response;
      try {
        var data = new FormData();
        data.append("archivo", this.state.material.archivo);
        data.append("titulo", this.state.material.titulo);
        data.append("descripcion", this.state.material.descripcion);
        data.append("extension", this.state.material.extension);
        data.append("id_area", this.state.material.id_area);
        data.append("id_nivel", this.state.material.id_nivel);
        this.state.oas_in.forEach((oa) => {
          data.append("oas", Number(oa.id));
        });
        data.append(
          "tipo_material_docente",
          this.state.material.tipo_material_docente
        );

        response = await postRequest(
          `${process.env.REACT_APP_API_URL}/api/bdocente/`,
          data
        );
        if (response.status === 201) {
          // 201 = CREATED
          this.setState({
            submited: true,
          });
          this.props.enqueueSnackbar(
            `Material ${this.state.material.titulo} agregado exitosamente!`
          );
        }
      } catch (e) {
        console.log(e);
        this.props.enqueueSnackbar("No se pudo subir el material");
      } finally {
        this.setState({ loading: false });
      }
    }
  };

  render() {
    const { theme, classes } = this.props;
    let componente;
    if (this.state.loading) {
      componente = (
        <div className="root loading">
          <CircularProgress />
          <h3>Subiendo manual</h3>
        </div>
      );
    } else if (this.state.submited) {
      return <Redirect to="/biblioteca-docente/" />;
    } else {
      componente = (
        <div className="root">
          <Card variant="outlined" className="container">
            {this.state.errors.archivo ? (
              <p className="error">{this.state.errors.archivo}</p>
            ) : null}
            <FilesDropzone
              className="dropzone"
              setFileInfo={this.setFileInfo}
              color={theme.bdocente}
              archivo={this.state.material.archivo}
            />
            <form className="form" onSubmit={this.handleUpload}>
              <Grid container spacing={2} justifyContent="center">
                <Grid container item justifyContent="center">
                  <p className="label">Título</p>
                  <TextField
                    variant="outlined"
                    size="small"
                    placeholder="Ingrese nombre del material"
                    name="titulo"
                    value={this.state.material.titulo}
                    onChange={this.onChange}
                    className="input"
                    helperText={this.state.errors.titulo}
                    error={Boolean(this.state.errors.titulo)}
                  />
                </Grid>

                <Grid container item justifyContent="center">
                  <p className="label">Autor/a</p>
                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    disabled
                    variant="outlined"
                    size="small"
                    name="autor"
                    value={this.state.autor}
                    className="input"
                  />
                </Grid>
                <Grid container item justifyContent="center">
                  <p className="label">Descripción</p>
                  <TextField
                    variant="outlined"
                    size="small"
                    multiline
                    rows={7}
                    name="descripcion"
                    value={this.state.material.descripcion}
                    onChange={this.onChange}
                    className="input"
                    error={Boolean(this.state.errors.descripcion)}
                    helperText={this.state.errors.descripcion}
                  />
                </Grid>
                <Grid container item justifyContent="center">
                  <p className="label">Tipo</p>
                  <TextField
                    name="tipo_material_docente"
                    onChange={this.onChange}
                    select
                    SelectProps={{ native: true }}
                    value={this.state.material.tipo_material_docente}
                    variant="outlined"
                    size="small"
                    className="input"
                    error={Boolean(this.state.errors.tipo_material_docente)}
                    helperText={this.state.errors.tipo_material_docente}
                  >
                    {this.state.tipo_material_docente.map((tipo) => (
                      <option key={tipo.id} value={tipo.id}>
                        {tipo.nombre}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid container item justifyContent="center">
                  <p className="label">Nivel</p>
                  <TextField
                    name="id_nivel"
                    onChange={this.onChangeNivel}
                    select
                    SelectProps={{ native: true }}
                    value={this.state.material.id_nivel}
                    variant="outlined"
                    size="small"
                    className="input"
                    error={Boolean(this.state.errors.id_nivel)}
                    helperText={this.state.errors.id_nivel}
                  >
                    {this.state.niveles.map((nivel) => (
                      <option key={nivel.codigo} value={nivel.codigo}>
                        {nivel.nombre}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid container item justifyContent="center">
                  <p className="label">Área</p>
                  <TextField
                    name="id_area"
                    onChange={this.onChangeArea}
                    select
                    SelectProps={{ native: true }}
                    value={this.state.material.id_area}
                    variant="outlined"
                    size="small"
                    className="input"
                    error={Boolean(this.state.errors.id_area)}
                    helperText={this.state.errors.id_area}
                  >
                    {this.state.areas.map((area) => (
                      <option key={area.id} value={area.id}>
                        {area.nombre}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid container item justifyContent="center">
                  <p className="label">Unidad</p>
                  <TextField
                    name="unidad"
                    onChange={this.onChangeUnidad}
                    select
                    SelectProps={{ native: true }}
                    value={this.state.unidad}
                    variant="outlined"
                    size="small"
                    className="input"
                    error={Boolean(this.state.errors.unidad)}
                    helperText={this.state.errors.unidad}
                  >
                    {this.state.unidades.map((unidad) => (
                      <option key={unidad.codigo} value={unidad.codigo}>
                        {unidad.nombre}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid container item justifyContent="center">
                  <p className="label">OA</p>
                  <Grid container className="input">
                    {this.state.oas_in.map((oa, index) => {
                      return (
                        <div style={{ width: "100%" }} key={`oa_in-${index}`}>
                          <Grid container spacing={0}>
                            <Grid
                              item
                              xs={3}
                              sm={2}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-start",
                              }}
                            >
                              <Button
                                style={{
                                  backgroundColor: theme.primary,
                                  width: "100%",
                                  height: "70%",
                                }}
                                variant="contained"
                                onClick={this.onShowDescription.bind(
                                  this,
                                  index,
                                  "in"
                                )}
                              >
                                <ExpandMoreIcon />
                              </Button>
                            </Grid>
                            <Grid item xs={6} sm={8}>
                              <h2
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "700",
                                  textAlign: "left",
                                  paddingLeft: "10%",
                                }}
                              >
                                {oa.codigo}
                              </h2>
                            </Grid>
                            <Grid
                              item
                              xs={3}
                              sm={2}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Button
                                style={{
                                  backgroundColor: theme.eliminar,
                                  width: "100%",
                                  height: "70%",
                                }}
                                variant="contained"
                                onClick={this.onDeleteOA.bind(this, index)}
                              >
                                <RemoveCircleIcon />
                              </Button>
                            </Grid>
                            {this.state.display_text_oas_in[index] ? (
                              <>
                                <Grid item xs={0} sm={2} />
                                <Grid
                                  item
                                  xs={12}
                                  sm={10}
                                  style={{ textAlign: "justify" }}
                                >
                                  {oa.descripcion}
                                </Grid>
                                <Grid item xs={0} sm={0} />
                              </>
                            ) : null}
                          </Grid>
                        </div>
                      );
                    })}
                    {this.state.oas_out.map((oa, index) => {
                      return (
                        <div
                          style={{ width: "100%" }}
                          key={`oa_out-${index}`}
                        >
                          <Grid container spacing={0}>
                            <Grid
                              item
                              xs={3}
                              sm={2}
                              md={1}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-start",
                              }}
                            >
                              <Button
                                style={{
                                  backgroundColor: theme.primary,
                                  width: "100%",
                                  height: "70%",
                                }}
                                variant="contained"
                                onClick={this.onShowDescription.bind(
                                  this,
                                  index,
                                  "out"
                                )}
                              >
                                <ExpandMoreIcon />
                              </Button>
                            </Grid>
                            <Grid item xs={6} sm={8} md={9}>
                              <h2
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "700",
                                  textAlign: "left",
                                  paddingLeft: "10%",
                                }}
                              >
                                {oa.codigo}
                              </h2>
                            </Grid>
                            <Grid
                              item
                              xs={3}
                              sm={2}
                              md={2}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Button
                                style={{
                                  backgroundColor: theme.guardar,
                                  width: "100%",
                                  height: "70%",
                                }}
                                variant="contained"
                                onClick={this.onAddOA.bind(this, index)}
                              >
                                <AddBoxIcon />
                              </Button>
                            </Grid>
                            {this.state.display_text_oas_out[index] ? (
                              <>
                                <Grid item xs={0} sm={2} md={1} />
                                <Grid
                                  item
                                  xs={12}
                                  sm={10}
                                  md={11}
                                  style={{ textAlign: "justify" }}
                                >
                                  {oa.descripcion}
                                </Grid>
                                <Grid item xs={0} sm={0} md={0} />
                              </>
                            ) : null}
                          </Grid>
                        </div>
                      );
                    })}
                  </Grid>
                </Grid>
                <Grid container item justify="center">
                  <p className="label">Descripción</p>
                  <div className="input">
                    <ReactQuillCustom
                      value={this.state.material.descripcion}
                      onChange={(value) =>
                        this.onChange({
                          target: { name: "descripcion", value },
                        })
                      }
                    />
                    <span className={classes.error}>
                      {this.state.errors.descripcion}
                    </span>
                  </div>
                </Grid>
              </Grid>
            </form>
            <div className="buttonContainer">
              <CustomerButton
                type="submit"
                color={theme.guardar}
                handle={this.handleUpload}
                text={"Guardar"}
              />
              <Button
                color="secondary"
                variant="contained"
                component={Link}
                to="/biblioteca-docente/"
              >
                Cancelar
              </Button>
            </div>
          </Card>
        </div>
      );
    }
    return (
      <Contenido
        componente={componente}
        seccion={"Subir material docente"}
        descripcion={""}
        backgroundColor={theme.bdocente}
      ></Contenido>
    );
  }
}

const mapStateToProps = (state) => ({
  usuario: state.auth.usuario,
});

export default compose(
  connect(mapStateToProps, {}),
  withStyles(styles, { withTheme: true })
)(withSnackbar(SubirMaterialDocente));
