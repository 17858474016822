import axios from "axios";
import store from "../store/store";

const getToken = () => {
  return store.getState().auth.access_token;
};

export const getRequest = async (url, params) => {
  const token = getToken();
  const response = await axios.get(url, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const getFileRequest = async (url, params, name, extension) => {
  const token = getToken();
  const response = await axios
    .get(url, {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: "blob",
    })
    .then((res) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", name + "." + extension); //any other extension
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  return response;
};

export const postRequest = async (url, body, config) => {
  const token = getToken();
  const response = await axios.post(url, body, {
    ...config,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const publicPostRequest = async (url, body, config) => {
  const response = await axios.post(url, body, {
    ...config,
  });
  return response;
};

export const publicPutRequest = async (url, body, config) => {
  const response = await axios.put(url, body, {
    ...config,
  });
  return response;
};

export const publicGetRequest = async (url, params) => {
  const response = await axios.get(url, {
    params,
  });
  return response;
};

export const putRequest = async (url, body) => {
  const token = getToken();
  const response = await axios.put(url, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const deleteRequest = async (url) => {
  const token = getToken();
  const response = await axios.delete(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};
