import React from "react";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { withSnackbar } from "notistack";
import { Grid, Divider, Button } from "@mui/material";
import {
  AccountCircle as UserIcon,
  QuestionAnswer as QuestionAnswerIcon,
  ThumbUpAlt,
  CloudDownload as CloudDownloadIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";

import {
  getRequest,
  postRequest,
  getFileRequest,
  deleteRequest,
} from "../../../utils/axios";
import { withStyles } from "@mui/styles";
import "./DetalleCapsula.css";
import Contenido from "../../../components/Common/Contenido";
import Comentarios from "../../../components/Comentarios/Comentarios";
import { thousandSeparator } from "../../../utils/utils";
import VideoPlayer from "./videoPlayer";
import { getAreaColor } from "../../../utils/styleUtils";
import CustomerLinearProgress from "../../../common/linearProgress";
import { LinkButton, CustomerButton } from "../../../common/buttons";
import ReactQuillCustom from "../../../common/ReactQuill";

const styles = () => ({});

class Capsula extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      n_likes: props.capsula.likes.length,
      like: false,
      isMobile:
        Math.min(window.screen.width, window.screen.height) < 768 ||
        navigator.userAgent.indexOf("Mobi") > -1,
    };
  }

  componentDidMount() {
    let user_en_lista = false;
    for (let index = 0; index < this.props.capsula.likes.length; index++) {
      const element = this.props.capsula.likes[index];
      if (element.id === this.props.usuario.id) {
        user_en_lista = true;
        break;
      }
    }
    this.setState({
      like: user_en_lista,
    });
  }

  handleLike = async (e) => {
    if (e != null) e.preventDefault();
    var data = {
      id_usuario: this.props.usuario.id,
      id_capsula: this.props.capsula.id,
      add: !this.state.like,
    };
    const response = await postRequest(
      `${process.env.REACT_APP_API_URL}/api/capsulas/like/`,
      data
    );

    if (response.status === 200) {
      this.setState({
        like: !this.state.like,
        n_likes:
          !this.state.like === true
            ? this.state.n_likes + 1
            : this.state.n_likes - 1,
      });
    }
  };

  handleDownload = async () => {
    try {
      await getFileRequest(
        `${process.env.REACT_APP_API_URL}/api/capsulas/${this.props.capsula.id}/descargar/`,
        {},
        this.props.capsula.titulo,
        this.props.capsula.extension
      );
    } catch (e) {
      //SHOW SNACK ERROR
      this.props.enqueueSnackbar(
        `No se pudo descargar la capsula ${this.props.capsula.titulo}`
      );
    }
  };

  handleDelete = async () => {
    try {
      await deleteRequest(
        `${process.env.REACT_APP_API_URL}/api/capsulas/${this.props.capsula.id}/`
      );

      this.props.enqueueSnackbar(
        `Capsula ${this.props.capsula.titulo} eliminada con exito`
      );
      this.props.history.push("/capsulas");
    } catch (e) {
      console.log(e);
      //SHOW SNACK ERROR
      this.props.enqueueSnackbar(
        `No se pudo eliminar la capsula ${this.props.capsula.titulo}`
      );
    }
  };

  render() {
    const { theme } = this.props;
    return (
      <div className="Capsula__detalle">
        {this.props.usuario.id === this.props.capsula.id_usuario.id ? (
          <div style={{ width: "100%" }}>
            <div className="boton__subirContainerC">
              <LinkButton
                className="boton__subirMaterialC"
                text="Editar"
                to={`/capsulas/editar/${this.props.capsula.id}`}
                color={theme.capsula}
                child={<EditIcon className="boton__subirMaterialIconC" />}
              />
              <CustomerButton
                className="boton__subirMaterialC"
                text="Eliminar"
                handle={this.handleDelete}
                color={theme.eliminar}
                child={<DeleteIcon className="boton__subirMaterialIconC" />}
              />
            </div>
          </div>
        ) : null}
        {/* Video Player */}
        <VideoPlayer capsulaId={this.props.capsula.id} />
        {/* Título, visitas, fecha, n_comentarios, n_likes, decargar */}
        <Grid container spacing={2} className="Capsula__detalle__container">
          {/* Título */}
          <Grid item xs={12}>
            <h2 className="Capsula__detalle__titulo">
              {this.props.capsula.titulo}
            </h2>
          </Grid>
        </Grid>
        {/* N_visitas y fecha */}
        <Grid container spacing={0} className="Capsula__fechaBotones">
          <Grid
            item
            xs={12}
            md={8}
            lg={8}
            className="Capsula__detalle__nvistas"
          >
            <p className="visitas__container">
              {" "}
              {thousandSeparator(this.props.capsula.n_visitas)} vistas •
              {" Se publicó el "}
              {moment(this.props.capsula.fecha_creacion).format("ll")}
            </p>
          </Grid>
          {/* Likes y comentarios */}
          <Grid item xs={12} md={2} lg={2}>
            <Grid container spacing={0} className={"capsula__spaceBetween"}>
              {/* Comentarios */}
              <Grid item xs={6} className={"capsula__center"}>
                <Button className="Capsula__detalle_likeButton">
                  <QuestionAnswerIcon className={"capsula__ChatIcon"} />
                  <div className={"capsula__ChatIconLabel"}>
                    {this.props.n_comentarios}
                  </div>
                </Button>
              </Grid>
              {/* Likes */}
              <Grid item xs={6} className={"capsula__center"}>
                <Button
                  className="Capsula__detalle_likeButton"
                  onClick={this.handleLike}
                >
                  <ThumbUpAlt
                    className="capsula__LikeIcon"
                    style={{
                      color: this.state.like
                        ? this.props.theme.likeBlue
                        : "grey",
                    }}
                  />
                  <div
                    className="capsula__LikeIconLabel"
                    style={{
                      color: this.state.like
                        ? this.props.theme.likeBlue
                        : "grey",
                    }}
                  >
                    {thousandSeparator(this.state.n_likes)}
                  </div>
                </Button>
              </Grid>
            </Grid>
          </Grid>

          {/* Botón descargar */}
          <Grid
            item
            xs={12}
            md={2}
            lg={2}
            className="Capsula__detalle__downloadButton"
          >
            <Button
              color="primary"
              variant="contained"
              onClick={this.handleDownload}
              style={{ width: "100%" }}
              startIcon={<CloudDownloadIcon />}
            >
              Descargar
            </Button>
          </Grid>
          {/* Divider */}
          <Grid item xs={12} style={{ marginTop: "1%", marginBottom: "1%" }}>
            <Divider />
          </Grid>

          <Grid
            item
            xs={3}
            sm={2}
            md={1}
            style={{ display: "flex", width: "100%", justifyContent: "flex-start" }}
          >
            {this.props.capsula.id_usuario.foto_perfil &&
              this.props.capsula.id_usuario.foto_perfil !== "" ? (
              <img
                src={`${process.env.REACT_APP_API_URL}/miniaturas/usuarios_min/${this.props.capsula.id_usuario.id}`}
                // className="usuario_icon"
                style={{ height: "50px !important", borderRadius: "50%" }}
                alt="foto"
              />
            ) : (
              <UserIcon style={{ fontSize: "50px" }} />
            )}
          </Grid>
          <Grid
            item
            xs={9}
            sm={3}
            md={2}
            style={{ display: "flex", width: "100%", justifyContent: "flex-start" }}
          >
            <p className="autor__container">
              {this.props.capsula.id_usuario.primer_nombre +
                " " +
                this.props.capsula.id_usuario.apellido_paterno}
            </p>
          </Grid>
          <Grid item xs={12} sm={7} md={9}>
            <Grid container spacing={1} className={"tagContainer"}>
              <Grid
                item
                className={"tags"}
                style={{
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "25px",
                  backgroundColor: "#F3F3F3",
                  padding: "5px 10px 5px 10px",
                  margin: "6px",
                  color: "black",
                  border: "1px solid #6A6666",
                }}
              >
                {this.props.capsula.id_nivel.nombre}
              </Grid>
              <Grid
                item
                className={"tags"}
                style={{
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "25px",
                  backgroundColor: getAreaColor(
                    this.props.capsula.id_area.nombre
                  ),
                  padding: "5px 10px 5px 10px",
                  margin: "6px",
                  color: "white",
                }}
              >
                {this.props.capsula.id_area.nombre}
              </Grid>

              <Grid
                item
                className={"tags"}
                style={{
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "25px",
                  backgroundColor: "#6A6666",
                  padding: "5px 10px 5px 10px",
                  margin: "6px",
                  color: "white",
                }}
              >
                {this.props.capsula.id_metodologia.nombre}
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid
              container
              spacing={0}
              style={{ justifyContent: "flex-start" }}
            >
              <Grid
                item
                xs={1}
                md={1}
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "flex-start",
                }}
              >
                {/* Empty space */}
              </Grid>
              <Grid item xs={12} md={11} lg={8} xl={8}>
                <ReactQuillCustom
                  value={this.props.capsula.descripcion}
                  readOnly={true}
                  theme={"bubble"}
                />
              </Grid>
            </Grid>
          </Grid>

          {/* Divider */}
          <Grid item xs={12} style={{ marginTop: "1%", marginBottom: "1%" }}>
            <Divider />
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  usuario: state.auth.usuario,
});

export var CapsulaCustomer = withRouter(
  compose(
    connect(mapStateToProps, {}),
    withStyles(styles, { withTheme: true })
  )(withSnackbar(Capsula))
);

class DetalleCapsula extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      capsula: null,
      comentarios_base: [],
      comentarios_hijos: [],
      loading: true,
    };
  }

  async componentDidMount() {
    let component = this;
    let capsula = null;
    let comentarios_base = [];
    let comentarios_hijos = [];
    async function getCapsula() {
      await getRequest(
        `${process.env.REACT_APP_API_URL}/api/capsulas/${component.props.match.params.id}/`
      )
        .then((response) => {
          capsula = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    }
    async function getComentarios() {
      await getRequest(
        `${process.env.REACT_APP_API_URL}/api/capsulas/comentarios/${component.props.match.params.id}/`
      )
        .then((response) => {
          comentarios_base = response.data.comentarios_base;
          comentarios_hijos = response.data.comentarios_hijos;
        })
        .catch((e) => {
          console.log(e);
        });
    }
    await getCapsula();
    await getComentarios();
    component.setState({
      capsula,
      comentarios_base,
      comentarios_hijos,
      loading: false,
    });
  }

  render() {
    let componente;
    if (this.state.loading) {
      return <CustomerLinearProgress />;
    } else if (!this.state.capsula) {
      componente = <div>No se encontró capsula</div>;
    } else {
      componente = (
        <div>
          {/* Resultados */}
          <div className="Container__padding">
            <CapsulaCustomer
              capsula={this.state.capsula}
              n_comentarios={
                this.state.comentarios_base.length +
                this.state.comentarios_hijos.length
              }
              key={this.state.capsula.id}
            />
            <div className="Comentarios_capsula">
              <Comentarios
                urlPost={`${process.env.REACT_APP_API_URL}/api/capsulas/comentarios/`}
                comentarios_base={this.state.comentarios_base}
                comentarios_hijos={this.state.comentarios_hijos}
                id_recurso={this.props.match.params.id}
                tipo_recurso="id_capsula"
              />
            </div>
          </div>
        </div>
      );
    }
    return (
      <Contenido
        componente={componente}
        seccion={"Detalle Cápsula"}
        descripcion={"Material pedagógico para docentes"}
        backgroundColor={"#f67e66"}
      ></Contenido>
    );
  }
}

export default withStyles(styles)(DetalleCapsula);
