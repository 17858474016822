import React from "react";
import { Grid } from "@mui/material";
import "./ConversacionItem.css";
import {
  deltaTime,
  thousandSeparatorShort,
  cutText,
} from "../../utils/utils";
import {
  getRandomColor
} from "../../utils/styleUtils"

import {
  AccessTime as AccessTimeIcon,
  Comment as CommentIcon,
  Visibility as VisibilityIcon,
  AccountCircle as AccountCircleIcon,
} from "@mui/icons-material";
import { ForoIconConversaciones } from "./ForoIcon";
import { withRouter } from "react-router-dom";
import { createStyles } from "@mui/styles";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";

const styles = (theme) => createStyles({});

class ConversacionItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false
    };
  }

  toggleHover = () => {
    this.setState({ hover: !this.state.hover });
  };

  render() {
    const { theme } = this.props;

    const styles = createStyles({
      conversacionitemcontainer: {
        width: "100%",
        backgroundColor: this.state.hover
          ? "#f3f6f4"
          : "transparent",
        boxShadow: "0px 4px 8px 0 rgba(0, 0, 0, 0.2)",
        // boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2) !important",
        borderRadius: "5px",
        padding: "10px 0px 10px 20px",
        margin: "10px 0px",
        textAlign: "left",
        border: this.state.hover
          ? "2px solid #000000"
          : "2px solid " + getRandomColor(this.props.conversacion.id_usuario_creador),
        cursor: this.state.hover ? "pointer" : "",
      }
    });

    const {
      titulo,
      n_gran_idea,
      n_lo_utilizare,
      n_me_asombra,
      n_me_gusta,
      n_me_interesa,
      fecha_creacion,
      n_comentarios,
      n_visitas,
      autor,
    } = this.props.conversacion;
    return (
      <Grid
        container
        width="100%"
        style={styles.conversacionitemcontainer}
        direction="row"
        alignItems="center"
        onClick={() =>
          this.props.history.push(`/foro/categorias/${this.props.match.params.id}/conversacion/${this.props.conversacion.id}/`)
        }
        onMouseEnter={this.toggleHover}
        onMouseLeave={this.toggleHover}
      >
        <Grid item md={8} style={{ borderRight: `1px solid ${theme.grisOscuro}` }}>
          <Grid container>
            <Grid item>
              <p className="ConversacionItem__titulo">
                {cutText(titulo, 100)}
              </p>
            </Grid>
            <Grid container width="100%">
              <div className="ConversacionItem__autor">
                <span
                >
                  {autor.foto_perfil &&
                    autor.foto_perfil !== "" ? (
                    <img
                      id="navbar_profile_photo"
                      src={`${process.env.REACT_APP_API_URL}/miniaturas/usuarios_min/${autor.id}`}
                      className="usuario_icon"
                      style={{ width: "2.5em", height: "100%", marginRight: "10px" }}
                      alt="foto"
                    />
                  ) : (
                    <AccountCircleIcon style={{ width: "2em", height: "100%", marginRight: "10px" }} />
                  )}
                </span>
                <p>{autor.nombre}</p>
              </div>
              <div className="ConversacionItem__autor__text" />
              <div className="ConversacionItem__informacion">
                <div className="ConversacionItem__informacion_estadisticas">
                  <AccessTimeIcon className="ConversacionItem__informacion_estadisticas_icon" />
                  <p>{deltaTime(fecha_creacion)}</p>
                </div>
                <div className="ConversacionItem__informacion_estadisticas">
                  <CommentIcon className="ConversacionItem__informacion_estadisticas_icon" />{" "}
                  <p>{thousandSeparatorShort(n_comentarios)}</p>
                </div>
                <div className="ConversacionItem__informacion_estadisticas">
                  <VisibilityIcon className="ConversacionItem__informacion_estadisticas_icon" />
                  <p>{thousandSeparatorShort(n_visitas)}</p>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={4} xs={12}>
          <Grid
            container
            direction="row"
            justifyContent="space-around"
            wrap="wrap"
            style={{ paddingLeft: "8px" }}
          >
            <ForoIconConversaciones type="Me gusta" cantidad={n_me_gusta} />
            <ForoIconConversaciones type="Gran idea" cantidad={n_gran_idea} />
            <ForoIconConversaciones type="Me interesa" cantidad={n_me_interesa} />
            <ForoIconConversaciones type="Lo utilizaré" cantidad={n_lo_utilizare} />
            <ForoIconConversaciones type="Me asombra" cantidad={n_me_asombra} />
          </Grid>
        </Grid>
      </Grid>
    );
  }
}
const mapStateToProps = (state) => ({
  usuario: state.auth.usuario,
});

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(withStyles(styles, { withTheme: true })(ConversacionItem))
);
