import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import { deltaTime } from "../../../utils/utils";
import { createStyles } from "@mui/styles";
import ForoIcon from "../../../components/Foro/ForoIcon";
import Divider from "@mui/material/Divider";
import { withRouter } from "react-router-dom";
import { LinkButton } from "../../../common/buttons";
import './CategoriaForo.css'

// Icons
import {
  QuestionAnswer as QuestionAnswerIcon,
  Restore as RestoreIcon,
  Update as UpdateIcon,
  Chat as ChatIcon,
  Visibility as VisibilityIcon,
  Favorite as FavoriteIcon,
  Edit as EditIcon
} from "@mui/icons-material";

const styles = (theme) => createStyles({});

class CategoriaForo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
    };
  }

  toggleHover = () => {
    this.setState({ hover: !this.state.hover });
  };

  render() {
    const { theme } = this.props;
    const styles = createStyles({
      categoria__container: {
        borderRadius: "5px",
        border: this.state.hover
          ? "2px solid " + theme.foro
          : "2px solid #dbdbdb",
        "&:hover": {
          cursor: "pointer",
          border: "2px solid" + theme.foro,
        },
      },
      categoria__foto: {
        width: "100%",
        height: "200px",
        marginBottom: "-10px",
      },
      tituloCategoria: {
        padding: "10px",
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: theme.gris,
        fontSize: "14px",
        fontWeight: 700,
        fontFamily: theme.fontFamily,
      },
      categoria__headerContainer: {
        background: theme.foro,
        width: '100%',
        alignItems: 'center',
        paddingLeft: '10px'
      }
    });

    return (
      <Grid
        onMouseEnter={this.toggleHover}
        onMouseLeave={this.toggleHover}
        style={styles.categoria__container}
        container >
        {this.props.edit ?
          <Grid container style={styles.categoria__headerContainer}>
            <Grid xs={6} md={6} item className={"material__HeaderText"}>
              {this.props.categoria.activa ? "Activo" : "No activo"}
            </Grid>
            <Grid xs={6} md={6} item className={"material__HeaderText"}>
              <LinkButton
                className="editar_categoria_button"
                text={'Editar'}
                color={theme.foro}
                child={
                  <EditIcon className="Boton__subirCapsulaIcon" />
                }
                to={`/foro/categorias/editar/${this.props.categoria.id}`}
              />
            </Grid>
          </Grid> : null
        }
        <Grid
          container
          spacing={0}
          onClick={() => this.props.history.push(`/foro/categorias/${this.props.categoria.id}`
          )}
        >
          {/* Foto */}
          <Grid item xs={12}>
            <img
              style={styles.categoria__foto}
              src={`${process.env.REACT_APP_API_URL}/miniaturas/foro/categorias/${this.props.categoria.id}/`}
              alt="categoria"
            />
          </Grid>

          {/* Nombre */}
          <Grid item xs={12} style={styles.tituloCategoria}>
            {this.props.categoria.nombre}
          </Grid>

          {/* Metadata */}
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              wrap="wrap"
              justifyContent="space-around"
              alignItems="center"
            >
              <Grid item sm={7} xs={12}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="flex-start"
                  style={{ marginTop: "5px", marginBottom: "5px" }}
                >
                  <RestoreIcon
                    style={{ marginLeft: "5px", marginRight: "5px" }}
                  />Primer post {this.props.categoria.fecha_primer_post ? deltaTime(this.props.categoria.fecha_primer_post) : '--'}
                </Grid>
              </Grid>
              <Grid item sm={5} xs={12}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="flex-start"
                  style={{ marginTop: "5px", marginBottom: "5px" }}
                >
                  <QuestionAnswerIcon
                    style={{ marginLeft: "5px", marginRight: "5px" }}
                  />{" "}
                  {this.props.categoria.n_conversaciones + " conversaciones"}
                </Grid>
              </Grid>
              <Grid item sm={7} xs={12}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="flex-start"
                  style={{ marginTop: "5px", marginBottom: "5px" }}
                >
                  {" "}
                  <UpdateIcon
                    style={{ marginLeft: "5px", marginRight: "5px" }}
                  />
                  Último post {this.props.categoria.fecha_ultimo_post ? deltaTime(this.props.categoria.fecha_ultimo_post) : '--'}
                </Grid>
              </Grid>
              <Grid item sm={5} xs={12}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="flex-start"
                  style={{ marginTop: "5px", marginBottom: "5px" }}
                >
                  <ChatIcon style={{ marginLeft: "5px", marginRight: "5px" }} />{" "}
                  {this.props.categoria.n_comentarios + " comentarios"}
                </Grid>
              </Grid>
              {/* Visitas y reacciones */}
              <Grid item sm={7} xs={12}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="flex-start"
                  style={{ marginTop: "5px", marginBottom: "5px" }}
                >
                  <FavoriteIcon
                    style={{ marginLeft: "5px", marginRight: "5px" }}
                  />{" "}
                  {this.props.categoria.n_total_reacciones + " reacciones"}
                </Grid>
              </Grid>
              <Grid item sm={5} xs={12}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="flex-start"
                  style={{ marginTop: "5px", marginBottom: "5px" }}
                >
                  <VisibilityIcon
                    style={{ marginLeft: "5px", marginRight: "5px" }}
                  />{" "}
                  {this.props.categoria.n_visitas + " visitas"}
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* Divider */}
          <Grid item xs={12}>
            <Divider style={{ marginBottom: "5px" }} />
          </Grid>

          {/* Me gusta */}
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              wrap="wrap"
              justifyContent="space-around"
            >
              <ForoIcon
                type="Me gusta"
                cantidad={this.props.categoria.n_me_gusta}
              />
              <ForoIcon
                type="Gran idea"
                cantidad={this.props.categoria.n_gran_idea}
              />
              <ForoIcon
                type="Me interesa"
                cantidad={this.props.categoria.n_me_interesa}
              />
              <ForoIcon
                type="Lo utilizaré"
                cantidad={this.props.categoria.n_lo_utilizare}
              />
              <ForoIcon
                type="Me asombra"
                cantidad={this.props.categoria.n_me_asombra}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid >
    );
  }
}

const mapStateToProps = (state) => ({
  usuario: state.auth.usuario,
});

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(withStyles(styles, { withTheme: true })(CategoriaForo))
);
