import React from "react";
import { withStyles, createStyles } from "@mui/styles";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer"; // Conversacion
import ThumbUpAlt from "@mui/icons-material/ThumbUpAlt";
import { Grid } from "@mui/material";
import { getFileRequest } from "../../utils/axios";
import { withSnackbar } from "notistack";
import "./Capsula.css";
import { thousandSeparatorShort } from "../../utils/utils";
import { cutText, countInArray, deltaTime } from "../../utils/utils";
import { getAreaColor } from "../../utils/styleUtils";

const styles = (theme) => createStyles({});

class Capsula extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
    };
  }

  toggleHover = () => {
    this.setState({ hover: !this.state.hover });
  };

  handleDownload = async () => {
    try {
      await getFileRequest(
        `${process.env.REACT_APP_API_URL}/api/capsulas/${this.state.id}/descargar/`,
        {},
        this.state.titulo,
        this.state.extension
      );
    } catch (e) {
      //SHOW SNACK ERROR
      this.props.enqueueSnackbar(
        `No se pudo descargar la capsula ${this.state.titulo}`
      );
    }
  };

  render() {
    const { capsula } = this.props;

    const styles = createStyles({
      capsula__container: {
        width: "300px",
        height: "calc(25px + 168.75px + 110.25px + 0px)",
        boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
        borderRadius: "5px",
        border: this.state.hover
          ? "2px solid " + getAreaColor(capsula.id_area.nombre)
          : "2px solid #dbdbdb",
        backgroundSize: "cover",
        marginBottom: "15px",
        marginRight: "15px",
        cursor: this.state.hover ? "pointer" : "",
        "&:hover": {
          cursor: "pointer",
          border: "2px solid " + getAreaColor(capsula.id_area.nombre),
        },
      },
      capsula__barraColor: {
        width: "calc(100% + 0px)",
        height: "25px",
        backgroundColor: getAreaColor(capsula.id_area.nombre),
        borderRadius: "3px 3px 0px 0px",
        marginTop: "-0px",
        marginLeft: "-0px",
        border: 0,
      },
    });

    return (
      <div
        style={styles.capsula__container}
        onClick={() => this.props.history.push(`/capsulas/${capsula.id}`)}
        onMouseEnter={this.toggleHover}
        onMouseLeave={this.toggleHover}
      >
        {/* Barra superior */}
        <div style={styles.capsula__barraColor}>
          <div className={"capsula__HeaderContainer"}>
            <div className={"capsula__HeaderText"}>
              {capsula.id_area.nombre}
            </div>
            <div className={"capsula__HeaderText"}>
              {capsula.id_nivel.nombre}
            </div>
          </div>
        </div>
        <div className={"capsula__ImagePreview"}>
          {/* Previsualización */}
          <img
            className={"capsula__ImagePreview"}
            src={`${process.env.REACT_APP_API_URL}/miniaturas/capsulas/${capsula.id}/`}
            alt="capsula"
          />
        </div>
        {/* Tag de tipo de metodología */}
        <div className={"capsula__Invisible"}>
          {/* <InsertDriveFileIcon className={"capsula__DocExtensionIconTop}/> */}
          <div className={"capsula__TipoContainer"}>
            <div className={"capsula__TipoText"}>
              {capsula.id_metodologia.nombre}
            </div>
          </div>
        </div>
        {/* Container inferior */}
        <div className={"capsula__FooterContainer"}>
          {/* Titulo */}
          <div className={"capsula__TituloContainer"}>
            <div className={"capsula__Titulo"}>
              {cutText(capsula.titulo, 70)}
            </div>
          </div>
          {/* Icono, autor, likes, comentarios */}
          <div className={"capsula__AutorLikeVisita__Container"}>
            <Grid container spacing={1} style={{ height: "100%" }}>
              {/* Autor y fecha */}
              <Grid item xs={8}>
                <Grid container spacing={1} style={{ height: "100%" }}>
                  <Grid item xs={2} className={"capsula__IconContainer"}>
                    {capsula.id_usuario.foto_perfil && capsula.id_usuario.foto_perfil !== "" ? (
                      <img
                        src={`${process.env.REACT_APP_API_URL}/miniaturas/usuarios_min/${capsula.id_usuario.id}`}
                        className="usuario_icon"
                        alt="foto"
                      />
                    ) : (
                      <AccountCircleIcon style={{ height: "2em" }} />
                    )}
                  </Grid>
                  <Grid item xs={10} className={"capsula__AutorContainer"}>
                    <div className={"capsula__NombreAutor"}>
                      {cutText(
                        capsula.id_usuario.primer_nombre +
                        " " +
                        capsula.id_usuario.apellido_paterno,
                        22
                      )}
                    </div>
                    <br />
                    <div className={"capsula__VisitasFecha"}>
                      {`${thousandSeparatorShort(
                        capsula.n_visitas
                      )} visitas • ${deltaTime(capsula.fecha_creacion)}`}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              {/* Likes y comentarios */}
              <Grid item xs={4}>
                <Grid
                  container
                  spacing={0}
                  className={"capsula__SpaceBetween"}
                >
                  {/* Comentarios */}
                  <Grid item xs={1} className={"capsula__ChatIcon"}>
                    <QuestionAnswerIcon />
                  </Grid>
                  <Grid item xs={1} className={"capsula__ChatIconLabel"}>
                    {countInArray(capsula.comentarios)}
                  </Grid>
                  {/* Likes */}
                  <Grid item xs={1} className={"capsula__LikeIcon"}>
                    <ThumbUpAlt />
                  </Grid>
                  <Grid item xs={1} className={"capsula__LikeIconLabel"}>
                    {countInArray(capsula.likes)}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect()(withStyles(styles)(withSnackbar(Capsula)))
);
