import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withStyles } from "@mui/styles";
import { Grid, Button } from "@mui/material";
import { Link } from "react-router-dom";
import "./CategoriaHeader.css";
import { ForoIconConversaciones } from "../../../components/Foro/ForoIcon";
import { Forum } from "@mui/icons-material";
import { createStyles } from "@mui/styles";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: 0,
    maxWidth: "100%",
  },
});

class CategoriaHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { theme } = this.props;

    const styles = createStyles({
      btn: {
        width: "100%",
        height: "100%",
        backgroundColor: `${this.props.theme.subir} !important` // theme.subir, pero no funciona, TODO
      }
    });

    const componente = (
      <Grid
        container
        className="Caterogia_Conversaciones__Header"
        spacing={1}
        style={{ paddingTop: "calc(0.5% + 10px)" }}
      >
        {/* Title */}
        <Grid item xs={12}>
          <Grid item>
            <div className={"Head__titulo"} style={{ color: theme.foro }}>
              {this.props.categoria.nombre}
            </div>
          </Grid>
        </Grid>

        {/* Imagen + Descripcion */}
        <Grid
          item
          xs={8}
          className="Head__subtitulo"
          style={{ padding: "0px 0px 0px 4px", fontSize: "18px" }}
        >
          <div className="CategoriaHead__ImageSubtitle">
            <img
              src={`${process.env.REACT_APP_API_URL}/miniaturas/foro/categorias/${this.props.categoriaId}/`}
              alt="imagen_categoria"
              className="Caterogia_Conversaciones__imagen"
            />
            <div className="Head__subtitulo" style={{ padding: "0px 0px 0px 8px" }}>
              {this.props.categoria.descripcion}
            </div>
          </div>

        </Grid>

        {/* Stats + Nueva Conversación Button */}
        {this.props.buttonStats ? (
          <Grid container direction="row" alignItems="center" item xs={4} justifyContent="space-between" style={{ padding: "0px 4px 0px 8px" }}>
            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                wrap="wrap"
                justifyContent="space-around"
              >
                <ForoIconConversaciones
                  type="Me gusta"
                  cantidad={this.props.categoria.n_me_gusta}
                />
                <ForoIconConversaciones
                  type="Gran idea"
                  cantidad={this.props.categoria.n_gran_idea}
                />
                <ForoIconConversaciones
                  type="Me interesa"
                  cantidad={this.props.categoria.n_me_interesa}
                />
                <ForoIconConversaciones
                  type="Lo utilizaré"
                  cantidad={this.props.categoria.n_lo_utilizare}
                />
                <ForoIconConversaciones
                  type="Me asombra"
                  cantidad={this.props.categoria.n_me_asombra}
                />
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
            >
              <br />
            </Grid>

            <Grid
              item
              xs={12}
            >
              <Button
                variant="contained"
                startIcon={<Forum />}
                className="Caterogia_Conversaciones__nueva_conversacion"
                to={`/foro/categorias/${this.props.categoriaId}/conversaciones/nueva`}
                component={Link}
              // style={styles.btn} TODO: usar theme.subir pero no funciona
              >
                Nueva conversación
              </Button>
            </Grid>

          </Grid>
        ) : null}
      </Grid>
    );

    return componente;
  }
}

const mapStateToProps = (state) => ({
  usuario: state.auth.usuario,
});

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, {})
)(CategoriaHeader);
