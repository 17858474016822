import React, { useContext } from "react";
import { Link } from "react-router-dom";
import {
  ListItem,
  List,
  ListItemIcon,
  ListItemText,
  Drawer as MuiDrawer
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  QuestionAnswer as QuestionAnswerIcon,
  FileCopy as FileCopyIcon,
} from "@mui/icons-material";
import { styled } from '@mui/material/styles';

import {
  ManualesSVG,
  CapsulasSVG,
  BibliotecaDocenteSVG,
  ComunidadDocenteSVG,
  PerfilSVG,
  InicioSVG,
} from "../../common/svgIcon";
import { connect } from "react-redux";
import "./Navbar.css";
import { DrawerContext } from "../../context/DrawerContext";

const drawerWidth = 270;

const openedMixin = (theme) => ({
  width: drawerWidth,
  top: "48px",
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  backgroundColor: "#FFFFFF",
  boxShadow: "5px 0px 4px -1px rgb(0 0 0 / 20%)",
});

const closedMixin = (theme) => ({
  top: "48px",
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: `calc(${theme.spacing(7)} + 1px)`,
  "@media(max-width: 480px)": {
    width: 0
  }
});


const StyledDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    position: "absolute",
    left: "0px",
    top: "0px",
    zindex: 1250,
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const useStyles = makeStyles((theme) => ({
  itemDrawer: {
    color: theme.primary,
    textDecoration: "none",
    marginBottom: "10px",
    height: "50px",
  },
  selectedItemDrawer: {
    color: "#1a5885",
    fill: "#1a5885",
    textDecoration: "none",
    marginBottom: "10px",
    fontWeight: "bold",
    background: "#ddd !important",
    height: "50px",
  },
  iconDrawer: {
    color: theme.primary,
    fill: theme.primary,
    fontSize: "25px",
    width: "32px",
  },
  selectedIconDrawer: {
    color: "#1a5885",
    fontSize: "25px",
    width: "32px",
  },
  "@media (max-width:480px)": {
    itemDrawer: {
      marginBottom: "0px",
      height: "50px",
    },
    selectedItemDrawer: {
      marginBottom: "0px",
      height: "50px",
    },
  },
}));

function Drawer(props) {
  const DrawerCxt = useContext(DrawerContext);
  const classes = useStyles();
  const { isAuthenticated } = props.auth;
  const { open } = props;
  const { setOpen } = props;
  const currentDrawerItem = DrawerCxt.currentDrawerItem;

  function handleClick() {
    if (open) {
      setOpen(!open);
    }
  }
  return isAuthenticated ? (
    <StyledDrawer
      open={open}
      variant="permanent"
      anchor="left"
    >
      {/* <Divider /> */}
      < List sx={{
        visibility: 'visible',
        right: 0,
        left: 0,
      }}>
        {
          [
            { text: "Inicio", Icon: InicioSVG, url: "/" },
            {
              text: "Cápsulas Docentes",
              Icon: CapsulasSVG,
              url: "/capsulas/",
            },
            {
              text: "Manuales Pedagógicos",
              Icon: ManualesSVG,
              url: "/manuales/",
            },
            {
              text: "Biblioteca Docente",
              Icon: BibliotecaDocenteSVG,
              url: "/biblioteca-docente/",
            },
            {
              text: "Comunidad Docente",
              Icon: ComunidadDocenteSVG,
              url: "/comunidad/",
            },

            {
              text: "Foro",
              Icon: QuestionAnswerIcon,
              url: "/foro/"
            },
            // {
            //   text: "Biblioteca Académica",
            //   Icon: FileCopyIcon,
            //   url: "/biblioteca-academica/",
            // },
            { text: "Perfil", Icon: PerfilSVG, url: "/perfil/editar" },
          ].map((item) => {
            const Icon = item.Icon;
            return (
              <Link
                to={item.url}
                className={
                  currentDrawerItem === item.text
                    ? classes.selectedItemDrawer
                    : classes.itemDrawer
                }
                key={item.text}
                onClick={handleClick}
              >
                <ListItem
                  button
                  key={item.text}
                  className={
                    currentDrawerItem === item.text
                      ? classes.selectedItemDrawer
                      : classes.itemDrawer
                  }
                >
                  <ListItemIcon>
                    <Icon
                      className={
                        currentDrawerItem === item.text
                          ? classes.selectedIconDrawer
                          : classes.iconDrawer
                      }
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={item.text}
                    style={{ display: "flex", flexWrap: "wrap" }}
                  />
                </ListItem>
              </Link>
            );
          })
        }
      </List >
    </StyledDrawer>
  ) : null;
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export var DrawerCustumer = connect(mapStateToProps)(Drawer);
