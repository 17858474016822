import React from "react";
import "./Common.css";
import Head from "./Head";

export default function Contenido(props) {
  const { componente, seccion, descripcion, backgroundColor } = props;

  return (
    <div className={"Common__container"}>
      <Head
        seccion={seccion}
        descripcion={descripcion}
        backgroundColor={backgroundColor}
      ></Head>
      <div className={"Component"}>{componente}</div>
    </div>
  );
}
