import React from "react";
import "./Head.css";
import { makeStyles } from "@mui/styles";

export default function Head(props) {
  const { seccion, descripcion, backgroundColor } = props;
  const estilos = makeStyles({
    Head__back: {
      width: "100%",
      height: "fit-content",
    },
    Head_titulo: {
      color: backgroundColor,
    },
  });
  const classes = estilos();

  return (
    <div className={classes.Head__back}>
      <div className={"Head__textContainer"}>
        <div className={"Head__padding"}>
          <div className={"Head__titulo"} style={{ color: backgroundColor }}>
            {seccion}
          </div>
          <div className={"Head__subtitulo"}>{descripcion}</div>
        </div>
      </div>
    </div>
  );
}
