import React from "react";
import { Grid } from "@mui/material";
import {
  cutText,
  countInArray,
  thousandSeparatorShort,
  deltaTime,
} from "../../utils/utils";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { CustomerButton } from "../../common/buttons";
import { withStyles } from "@mui/styles";
import { withSnackbar } from "notistack";
import { createStyles } from "@mui/styles";
import { getAreaColor, getBackgroundColor } from "../../utils/styleUtils";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer"; // Conversacion
import ThumbUpAlt from "@mui/icons-material/ThumbUpAlt";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import ReactQuillCustom from "../../common/ReactQuill";
import "./Manual.css";
import {cssVariables} from "../../theme";

const styles = (theme) => createStyles({});

class Manual extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDescription: false,
      like: false,
      hover: false,
    };
  }

  componentDidMount() {
    let user_en_lista = false;
    for (let index = 0; index < this.props.manual.likes.length; index++) {
      const element = this.props.manual.likes[index];
      if (element.id === this.props.usuario.id) {
        user_en_lista = true;
        break;
      }
    }
    this.setState({
      like: user_en_lista,
    });
  }

  toggleHover = () => {
    this.setState({ hover: !this.state.hover });
  };

  setShowDescription() {
    this.setState({
      showDescription: !this.state.showDescription,
    });
  }

  getManualAreaColor(area) {
    switch (area) {
      case "Manual Metodológico Técnico Pedagógico de Proyecto FIC, Financiado por el Gobierno Regional de Tarapacá":
        return cssVariables.dorado;
      default:
        return getAreaColor('Matemáticas');
    }
  }

  render() {
    const { theme, manual } = this.props;

    const styles = createStyles({
      manual__box: {
        borderRadius: "5px",
        border: this.state.hover
          ? "2px solid " + this.getManualAreaColor(manual.id_area.nombre)
          : "2px solid #dbdbdb",
        "&:hover": {
          cursor: "pointer",
          border: "2px solid" + this.getManualAreaColor(manual.id_area.nombre),
        },
      },
      manual__barraColor: {
        width: "calc(100% + 0px)",
        height: "25px",
        backgroundColor: this.getManualAreaColor(manual.id_area.nombre),
        backgroundImage: manual.id_area.nombre === "Nueva Categoría" ? "linear-gradient(180deg, #f7d000, #c49e46)" : "",
        borderRadius: "3px 3px 0px 0px",
        marginTop: "-0px",
        marginLeft: "-0px",
        border: 0,
      },
      manual__tituloContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "5px 0px 5px 0px",
        backgroundColor: theme.gris,
        width: "100%",
        height: "25%",
      },
      manual__docExtensionIconBot: {
        display: "flex",
        justifyContent: "center",
        color: getBackgroundColor(manual.extension),
        marginLeft: "5px",
      },
    });

    return (
      <Grid
        container
        spacing={0}
        style={styles.manual__box}
        className={"manual__width"}
        onMouseEnter={this.toggleHover}
        onMouseLeave={this.toggleHover}
      >
        {/* Barra superior */}
        <Grid
          item
          xs={12}
          style={styles.manual__barraColor}
          onClick={() => this.props.history.push(`/manuales/${manual.id}`)}
        >
          <div className={"manual__headerContainer"}>
            <div className={"manual__headerText"}>
              {manual.id_area.nombre}
            </div>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={3}
          md={3}
          className="manual__imageSection"
          onClick={() => this.props.history.push(`/manuales/${manual.id}`)}
        >
          <img
            src={`${process.env.REACT_APP_API_URL}/miniaturas/manuales/${manual.id}/`}
            // src="https://i.imgur.com/5W1D7S9.png"
            alt="manual"
            className="manual__image"
          />
        </Grid>
        {/* Titulo */}
        <Grid item xs={12} sm={9} md={9}>
          <div
            style={styles.manual__tituloContainer}
            onClick={() => this.props.history.push(`/manuales/${manual.id}`)}
          >
            <div className={"manual__titulo"}>
              {cutText(manual.titulo, 65)}
            </div>
          </div>
          {/* Icono, autor, likes, comentarios */}
          <div
            className={"manual__autorLikeVisita__container"}
            onClick={() => this.props.history.push(`/manuales/${manual.id}`)}
          >
            <Grid container spacing={1} style={{ height: "100%" }}>
              {/* Autor y fecha */}
              <Grid item xs={6}>
                <Grid container spacing={1} style={{ height: "100%" }}>
                  <Grid item xs={2} className={"capsula__IconContainer"}>
                    {manual.id_usuario.foto_perfil && manual.id_usuario.foto_perfil !== "" ? (
                      <img
                        src={`${process.env.REACT_APP_API_URL}/miniaturas/usuarios_min/${manual.id_usuario.id}`}
                        className="usuario_icon"
                        alt="foto"
                      />
                    ) : (
                      <AccountCircleIcon style={{ height: "2em" }} />
                    )}
                  </Grid>
                  <Grid item xs={10} className={"capsula__AutorContainer"}>
                    <div className={"capsula__NombreAutor"}>
                      {cutText(
                        manual.id_usuario.primer_nombre +
                        " " +
                        manual.id_usuario.apellido_paterno,
                        22
                      )}
                    </div>
                    <br />
                    <div className={"capsula__VisitasFecha"}>
                      {`${thousandSeparatorShort(
                        manual.n_visitas
                      )} visitas • ${deltaTime(manual.fecha_creacion)}`}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              {/* Likes y comentarios */}
              <Grid item xs={6}>
                <Grid
                  container
                  spacing={0}
                  className={"capsula__SpaceBetween"}
                >
                  {/* Comentarios */}
                  <Grid item xs={1} className={"capsula__ChatIcon"}>
                    <QuestionAnswerIcon />
                  </Grid>
                  <Grid item xs={2} className={"capsula__ChatIconLabel"}>
                    {countInArray(manual.comentarios)}
                  </Grid>
                  {/* Likes */}
                  <Grid item xs={1} className={"capsula__LikeIcon"}>
                    <ThumbUpAlt />
                  </Grid>
                  <Grid item xs={2} className={"capsula__LikeIconLabel"}>
                    {countInArray(manual.likes)}
                  </Grid>
                  <Grid item xs={4} className={"capsula__LikeIconLabel"}>
                    <div style={styles.manual__docExtensionIconBot}>
                      <InsertDriveFileIcon />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
          {/* Botón de resumen */}
          <div className={"botonResumenContainer"}>
            <CustomerButton
              className="manual__button"
              color={theme.secondary}
              text={
                this.state.showDescription ? "Ocultar resumen" : "Ver resumen"
              }
              handle={this.setShowDescription.bind(this)}
            />
          </div>
        </Grid>
        {/* Resumen */}
        {this.state.showDescription ? (
          <>
            <div
              style={{
                width: "100%",
                height: "2px",
                backgroundColor: theme.gris,
              }}
            />
            <div
              style={{ width: "100%", paddingLeft: "5%", paddingRight: "5%" }}
            >
              <ReactQuillCustom
                value={manual.descripcion}
                readOnly={true}
                theme={"bubble"}
                style={{ height: "100% important!" }}
              />
            </div>
          </>
        ) : null}
      </Grid>
    );
  }
}

export default withRouter(
  connect()(withStyles(styles)(withSnackbar(Manual)))
);
