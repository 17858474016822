import React from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { withStyles } from "@mui/styles";
import { CircularProgress, TextField, Button, Grid } from "@mui/material";
import { withSnackbar } from "notistack";
import { getRequest, postRequest } from "../../../../utils/axios";
import ReactQuillCustom from "../../../../common/ReactQuill";
import CategoriaHeader from "../../CategoriaHeader/CategoriaHeader";

const styles = (theme) => ({
  Container__padding: {
    width: "auto",
    height: "inherit",
    textAlign: "left",
    backgroundColor: "transparent",
    paddingLeft: "calc(5% + 20px)",
    paddingRight: "calc(5% + 20px)",
  },
  Nueva_Conversacion__form: {
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.03)!important",
    borderRadius: "5px",
    padding: "20px",
  },
  Nueva_Conversacion__text_left: {
    textAlign: "left",
  },
  Nueva_Conversacion__buttons: {
    display: "flex",
    padding: "20px !important",
    justifyContent: "center",
  },
  Nueva_Conversacion__button: {
    margin: "10px",
  },
  input: {
    width: "100%",
  },
  textfield: {
    width: "100%",
  },
  error: {
    color: "red",
    fontSize: "11px",
    paddingLeft: "10px",
  },
  "@media (max-width:950px)": {
    // eslint-disable-line no-useless-computed-key
    Nueva_Conversacion__form: {
      textAlign: "left",
    },
  },
});

class NuevaConversacion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editorRef: React.createRef(),
      categoria: {
        id: "",
        nombre: "",
        descripcion: "",
        url_imagen: "",
        n_me_gusta: 0,
        n_gran_idea: 0,
        n_me_interesa: 0,
        n_lo_utilizare: 0,
        n_me_asombra: 0,
        n_conversaciones: 0,
      },
      form: {
        id_usuario: this.props.usuario.id,
        titulo: "",
        contenido: "",
        id_categoria: this.props.match.params.idCategoria,
      },
      errors: {
        titulo: "",
        contenido: "",
      },
    };
  }

  componentDidMount() {
    let component = this;
    async function getCategoria() {
      await getRequest(
        `${process.env.REACT_APP_API_URL}/api/foro/categorias/${component.props.match.params.idCategoria}/`
      )
        .then((response) => {
          component.setState({
            categoria: response.data,
          });
        })
        .catch((e) => {
          console.log(e);
        });
    }
    getCategoria();
  }

  onChange = (name, value) => {
    this.setState({
      form: {
        ...this.state.form,
        [name]: value,
      },
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    let errors = {
      titulo: "",
      contenido: "",
    };
    let isValid = true;
    if (!this.state.form.titulo) {
      errors.titulo = "Debe ingresar un titulo";
      isValid = false;
    }
    if (this.state.form.titulo.length > 120) {
      errors.titulo = "Se permiten hasta 120 caractéres";
      isValid = false;
    }
    if (!this.state.form.contenido) {
      errors.contenido = "Debe ingresar el contenido de la conversación";
      isValid = false;
    }
    if (this.state.form.contenido.length > 3000) {
      errors.contenido = "Se permiten hasta 3000 caractéres";
      isValid = false;
    }
    this.setState({ errors });
    if (isValid) {
      try {
        const response = await postRequest(
          `${process.env.REACT_APP_API_URL}/api/foro/conversacion/nueva/`,
          this.state.form
        );

        if (response.status === 201) {
          this.setState({
            submited: true,
          });
          this.props.enqueueSnackbar(`Nueva conversación creada`);
        } else {
          this.props.enqueueSnackbar(
            `Hubo un error al crear la conversación. Intenteló más tarde.`
          );
        }
      } catch (e) {
        this.props.enqueueSnackbar(
          `Hubo un error al crear la conversación. Intenteló más tarde.`
        );
      }
    }
  };

  imageHandler = async () => {
    var quill = this.state.editorRef.current.getEditor()
    const input = document.createElement('input');

    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      const formData = new FormData();

      formData.append('image', file);

      // Save current cursor state
      const range = quill.getSelection(true);

      // Insert temporary loading placeholder image
      quill.insertEmbed(range.index, 'image', `${window.location.origin}/images/loaders/placeholder.gif`);

      // Move cursor to right side of image (easier to continue typing)
      quill.setSelection(range.index + 1);

      const res = await this.apiPostNewsImage(formData); // API post, returns image location as string e.g. 'http://www.example.com/images/foo.png'

      // Remove placeholder image
      quill.deleteText(range.index, 1);

      // Insert uploaded image
      quill.insertEmbed(range.index, 'image', res.body.image);
      quill.insertEmbed(range.index, 'image', res);
    };
  }

  apiPostNewsImage = async () => {
    // API post, returns image location as string e.g. 'http://www.example.com/images/foo.png'
    return "https://i.imgur.com/fSgnUKW.jpeg"
  }

  render() {
    const { classes } = this.props;
    var toolbarOptions = [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      // ['blockquote', 'code-block'],

      [{ 'header': 1 }, { 'header': 2 }],               // custom button values
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
      // [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
      // [{ 'direction': 'rtl' }],                         // text direction

      // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
      // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      // [{ 'font': [] }],
      [{ 'align': [] }],

      ['clean'],                                         // remove formatting button
      ['link', 'image']
    ];

    let componente;
    if (this.state.loading) {
      componente = (
        <div className="root loading">
          <CircularProgress />
          <h3>Subiendo nueva conversación</h3>
        </div>
      );
    } else if (this.state.submited) {
      return <Redirect to={`/foro/categorias/${this.props.match.params.idCategoria}`} />;
    } else {
      componente = (
        <Grid container direction="column" justifyContent="flex-start">
          <h4 className={classes.Nueva_Conversacion__text_left}>
            Nuevo post
          </h4>
          <form className={classes.Nueva_Conversacion__form}>
            <Grid
              container
              spacing={1}
              className={classes.Nueva_Conversacion__text_left}
            >
              <Grid container>
                <Grid item xs={12} md={12}>
                  <p> Título </p>
                </Grid>
                <Grid item xs={12} md={12} className={classes.input}>
                  <TextField
                    name="titulo"
                    onChange={(ev) =>
                      this.onChange(ev.target.name, ev.target.value)
                    }
                    className={classes.textfield}
                    variant="outlined"
                    size="small"
                    placeholder="Escribe un título conciso que refleje lo que quieres compartir..."
                    helperText={this.state.errors.titulo}
                    error={Boolean(this.state.errors.titulo)}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} md={12}>
                  <p> Contenido </p>
                </Grid>
                <Grid item xs={12} md={12}>
                  <ReactQuillCustom
                    ref={this.state.editorRef}
                    value={this.state.form.contenido}
                    onChange={(value) => this.onChange("contenido", value)}
                    modules={{
                      toolbar: {
                        container: toolbarOptions,
                        handlers: {
                          image: this.imageHandler
                        }
                      }
                    }}
                    placeholder={"Escribe tu publicación aquí"}
                  />
                  <span className={classes.error}>
                    {this.state.errors.descripcion}
                  </span>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                className={classes.Nueva_Conversacion__buttons}
              >
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.Nueva_Conversacion__button}
                  onClick={this.handleSubmit}
                >
                  Publicar
                </Button>
                <Button
                  color="secondary"
                  variant="contained"
                  className={classes.Nueva_Conversacion__button}
                  component={Link}
                  to={`/foro/categorias/${this.props.match.params.idCategoria}`}
                >
                  Cancelar
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      );
    }

    return (
      <div className={classes.Container__padding}>
        <Grid container spacing={0} justifyContent="center">
          <Grid item xs={12}>
            <CategoriaHeader categoria={this.state.categoria} buttonStats={false} categoriaId={this.props.match.params.idCategoria} />
          </Grid>
          <Grid item xs={12}>
            {componente}
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  usuario: state.auth.usuario,
});

export default compose(
  connect(mapStateToProps, {}),
  withStyles(styles, { withTheme: true })
)(withSnackbar(NuevaConversacion));
