import React from "react";
import { Grid } from "@mui/material";
import "./ForoIcon.css";
import {
  MeAsombraSVG,
  MeGustaSVG,
  LoUtilizareSVG,
  MeInteresaSVG,
  GranIdeaSVG,
} from "../../common/svgIcon";
import { thousandSeparatorShort } from "../../utils/utils";

export function ForoIcon(props) {
  const renderIcon = () => {
    switch (props.type) {
      case "Me gusta":
        return <MeGustaSVG className="ForoIcon__svg" />;
      case "Gran idea":
        return <GranIdeaSVG className="ForoIcon__svg" />;
      case "Me interesa":
        return <MeInteresaSVG className="ForoIcon__svg" />;
      case "Lo utilizaré":
        return <LoUtilizareSVG className="ForoIcon__svg" />;
      default:
        return <MeAsombraSVG className="ForoIcon__svg" />;
    }
  };

  return (
    <Grid
      container
      justifyContent="center"
      direction="column"
      alignItems="center"
      className="ForoIcon__container"
    >
      {renderIcon()}
      <Grid item>
        <p className="ForoIcon__cantidad">
          {thousandSeparatorShort(props.cantidad)}
        </p>
      </Grid>
      <Grid item>
        <p className="ForoIcon__text">{props.type}</p>
      </Grid>
    </Grid>
  );
}

export function ForoIconConversaciones(props) {
  const renderIcon = () => {
    switch (props.type) {
      case "Me gusta":
        return <MeGustaSVG className="ForoIconConversaciones__svg" />;
      case "Gran idea":
        return <GranIdeaSVG className="ForoIconConversaciones__svg" />;
      case "Me interesa":
        return <MeInteresaSVG className="ForoIconConversaciones__svg" />;
      case "Lo utilizaré":
        return <LoUtilizareSVG className="ForoIconConversaciones__svg" />;
      default:
        return <MeAsombraSVG className="ForoIconConversaciones__svg" />;
    }
  };

  return (
    <Grid
      container
      justifyContent="center"
      direction="column"
      alignItems="center"
      className="ForoIconConversaciones__container"
    >
      {renderIcon()}
      <Grid item>
        <p className="ForoIconConversaciones__cantidad">
          {thousandSeparatorShort(props.cantidad)}
        </p>
      </Grid>
      <Grid item>
        <p className="ForoIconConversaciones__text">{props.type}</p>
      </Grid>
    </Grid>
  );
}

export function ForoIconPost(props) {
  const renderIcon = () => {
    switch (props.type) {
      case "Me gusta":
        return <MeGustaSVG className="ForoIconPost__svg" style={{ filter: props.colored ? "contrast(100%)" : "contrast(0%)" }} />;
      case "Gran idea":
        return <GranIdeaSVG className="ForoIconPost__svg" style={{ filter: props.colored ? "contrast(100%)" : "contrast(0%)" }} />;
      case "Me interesa":
        return <MeInteresaSVG className="ForoIconPost__svg" style={{ filter: props.colored ? "contrast(100%)" : "contrast(0%)" }} />;
      case "Lo utilizaré":
        return <LoUtilizareSVG className="ForoIconPost__svg" style={{ filter: props.colored ? "contrast(100%)" : "contrast(0%)" }} />;
      default:
        return <MeAsombraSVG className="ForoIconPost__svg" style={{ filter: props.colored ? "contrast(100%)" : "contrast(0%)" }} />;
    }
  };

  return (
    <Grid
      container
      justifyContent="center"
      direction="column"
      alignItems="center"
      className="ForoIconPost__container"
      onClick={props.onClick}
      style={props.style}
    >
      {renderIcon()}
      <Grid item>
        <p className="ForoIconPost__cantidad">
          {thousandSeparatorShort(props.cantidad)}
        </p>
      </Grid>
      <Grid item>
        <p className="ForoIconPost__text">{props.type}</p>
      </Grid>
    </Grid>
  );
}


export default ForoIcon;
