import React from "react";
import { Grid, Button, TextField, Divider } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Pagination from '@mui/material/Pagination'; // Paginamiento
import { DrawerContext } from "../../../context/DrawerContext";
import ConversacionItem from "../../../components/Foro/ConversacionItem";
import { getRequest } from "../../../utils/axios";
import "./Categoria_Conversaciones.css";
import { withStyles } from "@mui/styles";
import CategoriaHeader from "../CategoriaHeader/CategoriaHeader";
import moment from "moment";

const styles = (theme) => ({});

class Categoria_Conversaciones extends React.Component {
    state = {
        categoria: {
            id: "",
            nombre: "",
            descripcion: "",
            url_imagen: "",
            n_me_gusta: 0,
            n_gran_idea: 0,
            n_me_interesa: 0,
            n_lo_utilizare: 0,
            n_me_asombra: 0,
            n_conversaciones: 0,
        },
        buscador: {
            palabra_clave: "",
            fecha_desde: null,
            fecha_hasta: null,
            pagina: 1,
            tamano_pagina: 10,
            num_pages: 1,
            num_results: 0,
            order: "fecha",
            error: false
        },
        buscador_fechas_estados: {
            invalid_message_desde: "",
            invalid_message_hasta: "",
            error_desde: false,
            error_hasta: false,
        },
        tamano_pagina_opciones: [
            { id: 0, valor: 5 },
            { id: 1, valor: 10 },
            { id: 2, valor: 20 },
            { id: 3, valor: 50 },
            { id: 4, valor: 100 },
        ],
        orders: [
            { nombre: "Fecha", codigo: "fecha" },
            { nombre: "Número de visitas", codigo: "n_visitas" },
        ],
        conversaciones: [],
    };

    componentDidMount() {
        this.context.setCurrentDrawerItem("Foro");
        let component = this;

        async function getConversaciones() {
            await getRequest(
                `${process.env.REACT_APP_API_URL}/api/foro/categorias/${component.props.match.params.id}/`
            ).then((response) => {
                component.setState({
                    categoria: response.data,
                })
            })
        }

        async function getCategoria() {
            await getRequest(
                `${process.env.REACT_APP_API_URL}/api/foro/categorias/${component.props.match.params.id}/conversaciones/`,
                {
                    pagina: component.state.buscador.pagina,
                    tamano_pagina: component.state.buscador.tamano_pagina,
                }
            )
                .then((response) => {
                    component.setState({
                        conversaciones: response.data.conversaciones,
                        buscador: {
                            ...component.state.buscador,
                            num_pages: response.data.num_pages,
                            num_results: response.data.num_results,
                        },
                    });
                })
                .catch((e) => {
                    console.log(e);
                });
        }

        getCategoria();
        getConversaciones();
    }

    handleSearch = (e) => {
        if (e != null) e.preventDefault();
        getRequest(
            `${process.env.REACT_APP_API_URL}/api/foro/categorias/${this.state.categoria.id}/conversaciones/`,
            this.state.buscador
        ).then((response) => {
            this.setState({
                conversaciones: response.data.conversaciones,
                buscador: {
                    ...this.state.buscador,
                    num_pages: response.data.num_pages,
                    num_results: response.data.num_results,
                },
                buscado: true,
            });
        });
    };

    validateFecha = (key, fecha_desde, fecha_hasta) => {
        fecha_desde = moment(fecha_desde, 'YYYY-MM-DD')
        fecha_hasta = moment(fecha_hasta, 'YYYY-MM-DD')

        if (key === 'fecha_desde') {
            if (fecha_desde !== "" && !fecha_desde.isValid()) {
                this.setState({
                    buscador: {
                        ...this.state.buscador,
                        [key]: fecha_desde.format('YYYY-MM-DD'),
                    },
                    buscador_fechas_estados: {
                        ...this.state.buscador_fechas_estados,
                        error_desde: true,
                        invalid_message_desde: "Formato de fecha inválido"
                    }
                })
            } else if (fecha_desde.isAfter(moment(), 'day')) {
                this.setState({
                    buscador: {
                        ...this.state.buscador,
                        [key]: fecha_desde.format('YYYY-MM-DD'),
                    },
                    buscador_fechas_estados: {
                        ...this.state.buscador_fechas_estados,
                        error_desde: true,
                        invalid_message_desde: "La fecha ingresada no puede ser superior a hoy"
                    }
                })
            } else if (fecha_desde.isAfter(fecha_hasta, 'day')) {
                this.setState({
                    buscador: {
                        ...this.state.buscador,
                        [key]: fecha_desde.format('YYYY-MM-DD'),

                    },
                    buscador_fechas_estados: {
                        ...this.state.buscador_fechas_estados,
                        error_desde: true,
                        invalid_message_desde: "La fecha de inicio no puede ser superior a la de término"
                    }
                })
            } else {
                this.setState({
                    buscador: {
                        ...this.state.buscador,
                        [key]: fecha_desde.format('YYYY-MM-DD'),
                    },
                    buscador_fechas_estados: {
                        ...this.state.buscador_fechas_estados,
                        invalid_message_desde: "",
                        invalid_message_hasta: "",
                        error_desde: false,
                        error_hasta: false,
                    }
                });
            }

        } else if (key === 'fecha_hasta') {
            if (fecha_hasta != null && fecha_hasta !== "" && !fecha_hasta.isValid()) {
                this.setState({
                    buscador: {
                        ...this.state.buscador,
                        [key]: fecha_hasta.format('YYYY-MM-DD'),
                    },
                    buscador_fechas_estados: {
                        ...this.state.buscador_fechas_estados,
                        error_hasta: true,
                        invalid_message_hasta: "Formato de fecha inválido"
                    }
                })
            } else if (fecha_hasta.isAfter(moment(), 'day')) {
                this.setState({
                    buscador: {
                        ...this.state.buscador,
                        [key]: fecha_hasta.format('YYYY-MM-DD'),
                    },
                    buscador_fechas_estados: {
                        ...this.state.buscador_fechas_estados,
                        error_hasta: true,
                        invalid_message_hasta: "La fecha ingresada no puede ser superior a hoy"
                    }
                })
            } else if (fecha_desde.isAfter(fecha_hasta, 'day')) {
                this.setState({
                    buscador: {
                        ...this.state.buscador,
                        [key]: fecha_hasta.format('YYYY-MM-DD'),
                    },
                    buscador_fechas_estados: {
                        ...this.state.buscador_fechas_estados,
                        error_hasta: true,
                        invalid_message_hasta: "La fecha de término no puede ser inferior a la de inicio"
                    }
                })
            } else {
                this.setState({
                    buscador: {
                        ...this.state.buscador,
                        [key]: fecha_hasta.format('YYYY-MM-DD'),
                    },
                    buscador_fechas_estados: {
                        ...this.state.buscador_fechas_estados,
                        invalid_message_desde: "",
                        invalid_message_hasta: "",
                        error_desde: false,
                        error_hasta: false,
                    }
                });
            }
        }
    };

    handleDateChange = (key, date) => {
        if (key === 'fecha_desde') {
            if (date == null) {
                this.setState({
                    buscador: {
                        ...this.state.buscador,
                        [key]: date,
                    },
                    buscador_fechas_estados: {
                        error_desde: false,
                        invalid_message_desde: "",
                    }
                })
            } else {
                this.validateFecha(key, date, this.state.buscador.fecha_hasta)
            }
        }

        if (key === 'fecha_hasta') {
            if (date == null) {
                this.setState({
                    buscador: {
                        ...this.state.buscador,
                        [key]: date,
                    },
                    buscador_fechas_estados: {
                        error_hasta: false,
                        invalid_message_hasta: "",
                    }
                })
            } else {
                this.validateFecha(key, this.state.buscador.fecha_desde, date)
            }
        }

    }

    handleSearchTextChange = (e) => {
        this.setState({
            buscador: { ...this.state.buscador, palabra_clave: e.target.value },
        });
    };

    handlePagination = (e, value) => {
        this.setState(
            { buscador: { ...this.state.buscador, pagina: parseInt(value) } },
            () => this.handleSearch()
        );
    };

    onChange = (e) => {
        this.setState(
            {
                buscador: {
                    ...this.state.buscador,
                    [e.target.name]: e.target.value,
                },
            },
            () => {
                if (e.target.name === "order") this.handleSearch();
            }
        );
    }

    handleResultadosPorPagina = (e) => {
        this.setState(
            {
                buscador: {
                    ...this.state.buscador,
                    pagina: 1,
                    tamano_pagina: parseInt(e.target.value),
                },
            }, // vuelta a pagina 1, pero cambiando tamano_pagina
            () => this.handleSearch()
        ); // Se realiza nueva bpusqueda
    };

    render() {
        const componente = (
            <div className="Caterogia_Conversaciones__Container">
                <Grid container spacing={0}>
                    <CategoriaHeader
                        categoria={this.state.categoria}
                        buttonStats={true}
                        categoriaId={this.props.match.params.id}
                    />
                    <Grid
                        item
                        md={12}
                        sm={12}
                        xs={12}
                        style={{
                            marginTop: "20px",
                            marginBottom: "10px",
                            paddingBottom: "0px",
                        }}
                    >
                        <Divider />
                    </Grid>
                    <Grid container spacing={2} className="Categoria_Conversaciones_buscador__container">
                        {/* <Grid item md={4} sm={12} xs={12}>
                            <TextField
                                variant="outlined"
                                size="small"
                                label="Buscador"
                                className="Categoria_Conversaciones_text_field__container"
                                fullWidth
                                placeholder="Buscar conversación por palabra"
                                onChange={this.handleSearchTextChange}
                            />
                        </Grid> */}
                        <Grid item md={4} sm={6} xs={12} className={"Caterogia_Conversaciones__fecha_container"}>
                            <DatePicker
                                className="Caterogia_Conversaciones__keyboard_date_picker"
                                disableToolbar
                                disableFuture
                                error={this.state.buscador_fechas_estados.error_desde}
                                helperText={this.state.buscador_fechas_estados.invalid_message_desde}
                                autoOk
                                views={['day']}
                                variant="inline"
                                label="Fecha desde"
                                placeholder="31/12/2021"
                                emptyLabel="Seleccione"
                                invalidDateMessage="Formato de fecha inválido"
                                value={this.state.buscador.fecha_desde}
                                onChange={(e) => this.handleDateChange("fecha_desde", e)}
                                KeyboardButtonProps={{
                                    "aria-label": "change date",
                                }}
                                renderInput={(params) => <TextField {...params} helperText={null} />}
                            />
                        </Grid>
                        <Grid item md={4} sm={6} xs={12} className={"Caterogia_Conversaciones__fecha_container"}>
                            <DatePicker
                                className="Caterogia_Conversaciones__keyboard_date_picker"
                                disableToolbar
                                disableFuture
                                error={this.state.buscador_fechas_estados.error_hasta}
                                helperText={this.state.buscador_fechas_estados.invalid_message_hasta}
                                autoOk
                                variant="inline"
                                views={['day']}
                                label="Fecha hasta"
                                placeholder="31/12/2021"
                                emptyLabel="Seleccione"
                                invalidDateMessage="Formato de fecha inválido"
                                value={this.state.buscador.fecha_hasta}
                                onChange={(e) => this.handleDateChange("fecha_hasta", e)}
                                KeyboardButtonProps={{
                                    "aria-label": "change date",
                                }}
                                renderInput={(params) => <TextField {...params} helperText={null} />}
                            />
                        </Grid>
                        <Grid item md={4} sm={12} xs={12} className="">
                            <Button
                                variant="contained"
                                type="submit"
                                className="Caterogia_Conversaciones__boton_buscar"
                                fullWidth
                                onClick={this.handleSearch}

                            >
                                Buscar
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container width="100%">
                        <Grid
                            container
                            spacing={2}
                            style={{ marginTop: "20px", paddingBottom: "10px" }}
                        >
                            <Grid
                                item
                                xs={12}
                                md={8}
                                xl={8}
                                style={{ textAlign: "left" }}
                            >
                                <h3>
                                    Conversaciones ({this.state.buscador.num_results})
                                </h3>
                            </Grid>
                            <Grid item xs={12} md={2} xl={2}>
                                <TextField
                                    label="Orden de resultados"
                                    name="order"
                                    onChange={this.onChange}
                                    select
                                    SelectProps={{ native: true }}
                                    InputLabelProps={{ shrink: true }}
                                    variant="filled"
                                    size="small"
                                    style={{ width: "100%" }}
                                >
                                    {this.state.orders.map((order) => (
                                        <option key={order.codigo} value={order.codigo}>
                                            {order.nombre}
                                        </option>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} md={2} xl={2}>
                                <TextField
                                    label="Resultados por página"
                                    name="resultados_por_pagina"
                                    onChange={this.handleResultadosPorPagina}
                                    select
                                    SelectProps={{ native: true }}
                                    InputLabelProps={{ shrink: true }}
                                    variant="filled"
                                    size="small"
                                    style={{ width: "100%" }}
                                    defaultValue={10}
                                >
                                    {this.state.tamano_pagina_opciones.map((tamano) => (
                                        <option key={tamano.id} value={tamano.valor}>
                                            {tamano.valor}
                                        </option>
                                    ))}
                                </TextField>
                            </Grid>
                        </Grid>
                        {/*conversaciones*/}
                        {this.state.conversaciones.map((conversacion) => (
                            <ConversacionItem
                                key={conversacion.id}
                                conversacion={conversacion}
                            />
                        ))}
                        <div className="pagination__center">
                            <Pagination
                                count={this.state.buscador.num_pages}
                                page={this.state.buscador.pagina}
                                onChange={this.handlePagination}
                                className="paginamiento"
                            />
                        </div>
                    </Grid>
                </Grid>
            </div>
        );

        return componente;
    }
}

Categoria_Conversaciones.contextType = DrawerContext;

export default withStyles(styles,
    {
        withTheme: true
    }
)(
    Categoria_Conversaciones
);
