import moment from "moment";
import "moment/locale/es";

export function thousandSeparator(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function thousandSeparatorShort(x) {
  if (x === undefined)
    return 0

  var w;
  if (x >= 1000000000) {
    w = Math.trunc(x / 1000000000);
    console.log(w)
    return w.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + "B";
  }
  if (x >= 1000000) {
    w = Math.trunc(x / 1000000);
    console.log(w)
    return w.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + "M";
  }
  if (x > 100000) {
    w = Math.trunc(x / 1000);
    return w.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " mil";
  }

  if (x > 1000) {
    w = Math.trunc(x / 1000);
    return w.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " mil";
  }

  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function countInArray(array) {
  var count = 0;
  for (let index = 0; index < array.length; index++) {
    count++;
  }
  return count;
}

export function cutText(title, size) {
  const cutTitle = title.slice(0, size);
  if (title.length > cutTitle.length) {
    return cutTitle + "...";
  }
  return title;
}

export function deltaTime(capsulaDate) {
  return moment(capsulaDate).fromNow();
}
