import React from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { getRequest, putRequest } from "../../../utils/axios";
import { Card, TextField, Grid, Button } from "@mui/material";
import { withStyles } from "@mui/styles";
import { withSnackbar } from "notistack";
import Contenido from "../../../components/Common/Contenido";
import "./editarCapsula.css";
import "../../../App.css";
import { CustomerButton } from "../../../common/buttons";
import FormData from "form-data";
import CircularProgressWithLabel from "../../../common/CircularProgressWithLabel";
const styles = () => ({});

class EditarCapsula extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      autor: `${this.props.usuario.primer_nombre} ${this.props.usuario.apellido_paterno}`,
      id_usuario: this.props.usuario.id,
      capsula_id: props.match.params.id,
      capsula: {
        titulo: "",
        autor: `${this.props.usuario.primer_nombre} ${this.props.usuario.apellido_paterno}`,
        id_area: "Seleccionar",
        id_metodologia: "Seleccionar",
        id_nivel: "-1",
        descripcion: "",
        archivo: null,
        //palabrasclaves: [],
        id_usuario: this.props.usuario.id,
      },
      errors: {
        titulo: "",
        autor: "",
        id_area: "",
        id_metodologia: "",
        id_nivel: "",
        descripcion: "",
        archivo: "",
      },
      progressBarPercentage: 0,
      submited: false,
      loading: false,
      areas: [{ codigo: 0, nombre: "Seleccionar" }],
      metodologias: [{ nombre: "Seleccionar" }],
      niveles: [{ nombre: "Seleccionar", codigo: "-1" }],
    };
  }

  async componentDidMount() {
    let component = this;

    async function getCapsula() {
      await getRequest(
        `${process.env.REACT_APP_API_URL}/api/capsulas/${component.props.match.params.id}/`
      )
        .then((response) => {
          component.setState({ capsula: response.data }, () => {
            component.setState({
              capsula: {
                ...component.state.capsula,
                id_nivel: String(component.state.capsula.id_nivel.codigo),
                id_area: String(component.state.capsula.id_area.nombre),
                id_metodologia: String(
                  component.state.capsula.id_metodologia.nombre
                ),
              },
            });
          });
        })
        .catch((e) => {
          console.log(e);
        });
    }

    async function getAreas() {
      getRequest(
        `${process.env.REACT_APP_API_URL}/api/curriculum_escolar/areas/`
      ).then((response) => {
        const res_areas = response.data;
        res_areas.unshift({ codigo: 0, nombre: "Seleccionar" });
        component.setState({ areas: res_areas });
      });
    }
    async function getMetodologias() {
      getRequest(`${process.env.REACT_APP_API_URL}/api/metodologias/`).then(
        (response) => {
          const res_metodologias = response.data;
          res_metodologias.unshift({ nombre: "Seleccionar" });
          component.setState({ metodologias: res_metodologias });
        }
      );
    }
    async function getNiveles() {
      getRequest(
        `${process.env.REACT_APP_API_URL}/api/curriculum_escolar/niveles/`
      ).then((response) => {
        const res_niveles = response.data;
        res_niveles.unshift({
          codigo: "-1",
          nombre: "Seleccionar",
          ciclo: 0,
        });
        component.setState({ niveles: res_niveles });
      });
    }
    await getAreas();
    await getMetodologias();
    await getNiveles();
    await getCapsula();
  }

  onChange = (e) => {
    this.setState({
      capsula: {
        ...this.state.capsula,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleUpload = async (e) => {
    e.preventDefault();
    let isValid = true;
    let errors = {
      titulo: "",
      autor: "",
      id_area: "",
      id_metodologia: "",
      id_nivel: "",
      descripcion: "",
    };
    if (!this.state.capsula.titulo) {
      errors.titulo = "Debe ingresar un título";
      isValid = false;
    }
    if (this.state.capsula.titulo.length > 100) {
      errors.titulo = "Puede ingresar hasta 100 caracteres";
      isValid = false;
    }
    if (this.state.capsula.descripcion.length > 1000) {
      errors.descripcion = "Puede ingresar hasta 1000 caracteres";
      isValid = false;
    }
    if (this.state.capsula.id_area === "Seleccionar") {
      errors.id_area = "Debe seleccionar una area";
      isValid = false;
    }
    if (this.state.capsula.id_metodologia === "Seleccionar") {
      errors.id_metodologia = "Debe seleccionar una metodologia";
      isValid = false;
    }
    if (this.state.capsula.id_nivel === "-1") {
      errors.id_nivel = "Debe seleccionar un nivel";
      isValid = false;
    }
    if (!this.state.capsula.descripcion) {
      errors.descripcion = "Debe ingresar una descripción";
      isValid = false;
    }
    this.setState({ errors: errors });
    if (isValid) {
      const config = {
        onUploadProgress: (progressEvent) => {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          this.setState({ progressBarPercentage: percentCompleted });
        },
      };
      this.setState({ loading: true });
      let response;
      try {
        var data = new FormData();
        data.append("id", this.state.capsula.id);
        data.append("titulo", this.state.capsula.titulo);
        data.append("descripcion", this.state.capsula.descripcion);
        data.append("id_area", this.state.capsula.id_area);
        data.append("id_metodologia", this.state.capsula.id_metodologia);
        data.append("id_nivel", this.state.capsula.id_nivel);
        response = await putRequest(
          `${process.env.REACT_APP_API_URL}/api/capsulas/`,
          data,
          config
        );

        if (response.status === 204) {
          this.setState({
            submited: true,
          });
          this.props.enqueueSnackbar(
            `Cápsula ${this.state.capsula.titulo} editada exitosamente!`
          );
        }
      } catch (e) {
        console.log(e);
        this.props.enqueueSnackbar("No se pudo editar la cápsula");
      } finally {
        this.setState({ loading: false });
      }
    }
  };

  render() {
    const { theme } = this.props;
    let componente;
    if (this.state.loading) {
      componente = (
        <div className="root loading">
          <CircularProgressWithLabel
            value={this.state.progressBarPercentage}
          />
          <h3>Guardando cambios</h3>
        </div>
      );
    } else if (this.state.submited) {
      return <Redirect to={`/capsulas/${this.state.capsula.id}`} />;
    } else {
      componente = (
        <div className="root">
          <Card variant="outlined" className="container">
            <form className="form_Capsulas" onSubmit={this.handleUpload}>
              <Grid container spacing={2} justifyContent="center">
                <Grid container item justifyContent="center">
                  <p className="label">Título</p>
                  <TextField
                    variant="outlined"
                    size="small"
                    name="titulo"
                    value={this.state.capsula.titulo}
                    onChange={this.onChange}
                    className="input"
                    helperText={this.state.errors.titulo}
                    error={Boolean(this.state.errors.titulo)}
                  />
                </Grid>

                <Grid container item justifyContent="center">
                  <p className="label">Autor/a</p>
                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    disabled
                    variant="outlined"
                    size="small"
                    name="autor"
                    value={this.state.autor}
                    className="input"
                  />
                </Grid>
                <Grid container item justifyContent="center">
                  <p className="label">Área</p>
                  <TextField
                    name="id_area"
                    onChange={this.onChange}
                    select
                    value={this.state.capsula.id_area}
                    defaultValue={this.state.capsula.id_area}
                    variant="outlined"
                    size="small"
                    className="input"
                    error={Boolean(this.state.errors.id_area)}
                    helperText={this.state.errors.id_area}
                  >
                    {this.state.areas.map((area) => {
                      return (
                        <option key={area.codigo} value={area.nombre}>
                          {area.nombre}
                        </option>
                      );
                    })}
                  </TextField>
                </Grid>
                <Grid container item justifyContent="center">
                  <p className="label">Metodologia Activa</p>
                  <TextField
                    name="id_metodologia"
                    onChange={this.onChange}
                    select
                    value={this.state.capsula.id_metodologia}
                    defaultValue={this.state.capsula.id_metodologia}
                    variant="outlined"
                    size="small"
                    className="input"
                    error={Boolean(this.state.errors.id_metodologia)}
                    helperText={this.state.errors.id_metodologia}
                  >
                    {this.state.metodologias.map((metodologia) => {
                      return (
                        <option
                          key={metodologia.nombre}
                          value={metodologia.nombre}
                        >
                          {metodologia.nombre}
                        </option>
                      );
                    })}
                  </TextField>
                </Grid>
                <Grid container item justifyContent="center">
                  <p className="label">Nivel</p>
                  <TextField
                    name="id_nivel"
                    onChange={this.onChange}
                    select
                    value={this.state.capsula.id_nivel}
                    defaultValue={this.state.capsula.id_nivel}
                    variant="outlined"
                    size="small"
                    className="input"
                    error={Boolean(this.state.errors.id_nivel)}
                    helperText={this.state.errors.id_nivel}
                  >
                    {this.state.niveles.map((nivel) => {
                      return (
                        <option key={nivel.codigo} value={nivel.codigo}>
                          {nivel.nombre}
                        </option>
                      );
                    })}
                  </TextField>
                </Grid>
                <Grid container item justifyContent="center">
                  <p className="label">Descripción</p>
                  <TextField
                    variant="outlined"
                    size="small"
                    multiline
                    rows={7}
                    name="descripcion"
                    value={this.state.capsula.descripcion}
                    onChange={this.onChange}
                    className="input"
                    error={Boolean(this.state.errors.descripcion)}
                    helperText={this.state.errors.descripcion}
                  />
                </Grid>
                {/*
                <Grid container item>

                  <p className="label">Palabras claves</p>
                  <TextField
                    variant="outlined"
                    size="small"
                    multiline
                    rows={7}
                    name="descripcion"
                    value={this.state.capsula.palabrasclaves}
                    onChange={this.onChange}
                    className="input"
                  />
                </Grid>
                */}
              </Grid>
            </form>
            <div className="buttonContainer">
              <CustomerButton
                type="submit"
                color={theme.guardar}
                handle={(e) => this.handleUpload(e)}
                text={"Guardar"}
              />
              <Button
                color="secondary"
                variant="contained"
                component={Link}
                to={`/capsulas/${this.state.capsula.id}`}
              >
                Cancelar
              </Button>
            </div>
          </Card>
        </div>
      );
    }
    return (
      <Contenido
        componente={componente}
        seccion={"Editar cápsula"}
        descripcion={"Material pedagógico para docentes"}
        backgroundColor={theme.capsula}
      ></Contenido>
    );
  }
}

const mapStateToProps = (state) => ({
  usuario: state.auth.usuario,
});

export default compose(
  connect(mapStateToProps, {}),
  withStyles(styles, { withTheme: true })
)(withSnackbar(EditarCapsula));
