import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { logout } from "../../actions/auth";
import {
  Card,
  CardContent,
  CardHeader,
  Button,
  Grid,
  TextField,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { AccountCircle } from "@mui/icons-material";
import { validateEmail } from "../../utils/validator";
import { publicPostRequest } from "../../utils/axios";
import "./index.css";

const styles = () => ({});

export class RestorePassword extends React.Component {
  state = {
    email: "",
    loading: false,
    sended: false,
    errors: {
      email: "",
      form: "",
    },
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onSubmit = async (e) => {
    e.preventDefault();
    let errors = { email: "" };
    let isValid = true;
    if (!this.state.email) {
      errors.email = "Debe ingresar un email";
      isValid = false;
    } else if (!validateEmail(this.state.email)) {
      errors.email = "Debe ingresar un email válido";
      isValid = false;
    }
    this.setState({ errors });
    if (isValid) {
      this.setState({ loading: true });
      try {
        const response = await publicPostRequest(
          `${process.env.REACT_APP_API_URL}/api/usuarios/recuperar-cuenta/`,
          {
            email: this.state.email,
          }
        );
        if (response.status === 200) {
          if (this.props.usuario) {
            this.props.logout();
          }
          this.setState({ sended: true, loading: false });
        } else {
          this.setState({
            loading: false,
            errors: {
              ...this.state.errors,
              form: "El email ingresado no es válido",
            },
          });
        }
      } catch (e) {
        let errorForm;
        if (e.response) {
          if (e.response.status === 404) {
            errorForm = "Usuario no encontrado";
          }
          if (e.response.status === 403) {
            errorForm = "Usuario no activo";
          }
        } else {
          errorForm = "Hubo un error, no se pudo enviar el mail";
        }
        this.setState({
          loading: false,
          errors: {
            ...this.state.errors,
            form: errorForm,
          },
        });
      }
    }
  };

  render() {
    if (this.state.sended) {
      return (
        <div className="root__login">
          <div class="login_image_transition">
            <img class="image_transition" id="image_transition_1" src="/login/Imagen1.jpg" alt="imagen-login-1" />
            <img class="image_transition" id="image_transition_2" src="/login/Imagen2.jpg" alt="imagen-login-2" />
            <img class="image_transition" id="image_transition_3" src="/login/Imagen3.jpg" alt="imagen-login-3" />
            <img class="image_transition" id="image_transition_4" src="/login/Imagen4.jpg" alt="imagen-login-4" />
          </div>
          <Card className="card__login">
            <p style={{ color: "white"}}>
              Te hemos enviado un mail al email {this.state.email} para que
              recuperes tu cuenta
            </p>
            <Button
              color="primary"
              variant="contained"
              component={Link}
              to="/ingresar"
              style={{ width: "calc(100%)", margin: "0px 0px", maxWidth: "227px" }}
            >
              Ingresar
            </Button>
          </Card>
        </div>
      );
    }
    return (
      <div className="root__login">
        <div class="login_image_transition">
          <img class="image_transition" id="image_transition_1" src="/login/Imagen1.jpg" alt="imagen-login-1" />
          <img class="image_transition" id="image_transition_2" src="/login/Imagen2.jpg" alt="imagen-login-2" />
          <img class="image_transition" id="image_transition_3" src="/login/Imagen3.jpg" alt="imagen-login-3" />
          <img class="image_transition" id="image_transition_4" src="/login/Imagen4.jpg" alt="imagen-login-4" />
        </div>
        <Card className="card__login">
          <CardHeader
            title="Reestablecer contraseña"
            variant="h2"
            classes={{
              title: "title__login",
            }}
          />
          <CardContent>
            <p style={{ color: "white"}}>
              {" "}
              Para reestablecer la contraseña, ingresa el email electrónico
              con el cual estás registrado en la plataforma. Te enviaremos un
              email para reestablecer la contraseña
            </p>
            <form className="form" name="form" onSubmit={this.onSubmit}>
              <Grid
                container
                alignItems="flex-start"
                className="formGroup"
              >
                <TextField
                  style={{ background: "white", width: "calc(100% - 20px)", marginLeft: "-20px", maxWidth: "227px" }}
                  variant="outlined"
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircle style={{ color: "gray" }} />
                      </InputAdornment>
                    ),
                  }}
                  name="email"
                  placeholder="Correo"
                  onChange={this.onChange}
                  value={this.state.email}
                  helperText={this.state.errors.email}
                  error={Boolean(this.state.errors.email)}
                />
              </Grid>
              <p className="errorForm">{this.state.errors.form}</p>
              {this.state.loading ? <CircularProgress /> : null}
              <div className="formGroup">
                <Button
                  color="primary"
                  type="submit"
                  variant="contained"
                  className="button"
                  disabled={this.state.loading}
                  style={{ width: "calc(100%)", margin: "0px 0px", maxWidth: "227px" }}
                >
                  Enviar
                </Button>
              </div>
              <Link to={"/ingresar/"} className="white-link">Volver</Link>
            </form>
          </CardContent>
        </Card>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  usuario: state.auth.usuario,
});

export default compose(
  connect(mapStateToProps, { logout }),
  withStyles(styles, { withTheme: true })
)(RestorePassword);
