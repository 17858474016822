import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Link } from "react-router-dom";
import { DrawerContext } from "../../context/DrawerContext";
import { getRequest } from "../../utils/axios";
import { Grid, Divider, TextField, Button } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { withStyles } from "@mui/styles";
import Contenido from "../../components/Common/Contenido";
import Pagination from '@mui/material/Pagination'; // Paginamiento
import Manual from "../../components/Manuales/Manual";
import SkeletonManual from "../../components/Manuales/SkeletonManual";
import "./Manuales.css";

const styles = (theme) => ({});

class Manuales extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buscador: {
        titulo: "",
        area: "",
        pagina: 1,
        tamano_pagina: 10,
        num_pages: -1,
        num_results: -1,
        order: "fecha",
      },
      areas: [{ codigo: 0, nombre: "Seleccionar" }],
      manuales: [],
      tamano_pagina_opciones: [
        { id: 0, valor: 5 },
        { id: 1, valor: 10 },
        { id: 2, valor: 20 },
        { id: 3, valor: 50 },
        { id: 4, valor: 100 },
      ],
      orders: [
        { nombre: "Fecha", codigo: "fecha" },
        { nombre: "Número de visitas", codigo: "n_visitas" },
      ],
      cargando: true,
      buscado: false, // Para cambiar Novedades por Resultados
    };
  }

  componentDidMount() {
    this.context.setCurrentDrawerItem("Manuales Pedagógicos");
    let component = this;

    async function getManuales() {
      await getRequest(`${process.env.REACT_APP_API_URL}/api/manuales/`, {
        pagina: 1,
        tamano_pagina: 10,
      })
        .then((response) => {
          component.setState({
            manuales: response.data.manuales,
            buscador: {
              ...component.state.buscador,
              num_pages: response.data.num_pages,
              num_results: response.data.num_results,
            },
            cargando: false,
          });
        })
        .catch((e) => {
          component.setState({ cargando: false });
          console.log(e);
        });
    }
    async function getAreas() {
      getRequest(
        `${process.env.REACT_APP_API_URL}/api/curriculum_escolar/areas/`
      ).then((response) => {
        const res_areas = response.data;
        res_areas.unshift({ codigo: 0, nombre: "Seleccionar" });
        component.setState({ areas: res_areas });
      });
    }
    getManuales();
    getAreas();
  }

  onChange = (e) => {
    this.setState(
      {
        buscador: {
          ...this.state.buscador,
          [e.target.name]: e.target.value,
        },
      },
      () => {
        if (e.target.name === "order") this.handleSearch();
      }
    );
  };

  handleSearch = (e) => {
    if (e != null) e.preventDefault();
    getRequest(
      `${process.env.REACT_APP_API_URL}/api/manuales/`,
      this.state.buscador
    ).then((response) => {
      this.setState({
        manuales: response.data.manuales,
        buscador: {
          ...this.state.buscador,
          num_pages: response.data.num_pages,
          num_results: response.data.num_results,
        },
        buscado: true,
        cargando: false,
      });
    });
  };

  handlePagination = (e, value) => {
    this.setState(
      { buscador: { ...this.state.buscador, pagina: parseInt(value) } },
      () => this.handleSearch()
    );
  };

  handleResultadosPorPagina = (e) => {
    this.setState(
      {
        buscador: {
          ...this.state.buscador,
          pagina: 1,
          tamano_pagina: parseInt(e.target.value),
        },
      }, // vuelta a pagina 1, pero cambiando tamano_pagina
      () => this.handleSearch()
    ); // Se realiza nueva bpusqueda
  };

  resetSearch = () => {
    this.setState(
      {
        buscador: {
          titulo: "",
          area: "",
          pagina: 1,
          tamano_pagina: 10,
          num_pages: -1,
          num_results: -1,
          order: "fecha",
        },
      },
      () => this.handleSearch()
    );
  };

  displayManuales = (theme) => {
    if (this.state.cargando) {
      const array = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
      return (
        <div className="manualresultados__resultadoscontainer">
          {array.map((i) => (
            <SkeletonManual key={i} />
          ))}
        </div>
      );
    } else if (this.state.manuales.length > 0)
      return (
        <div className="manualresultados__resultadoscontainer">
          {this.state.manuales.map((manual) => {
            return (
              <Manual
                key={manual.id}
                manual={manual}
                theme={theme}
                usuario={this.props.usuario}
              />
            );
          })}
        </div>
      );

    return (
      <div className="center">
        <p>No se encontraron manuales</p>
      </div>
    );
  };

  render() {
    const { theme } = this.props;
    const componente = (
      <div>
        {this.props.usuario && this.props.usuario.groups.includes('admin') ?
          <div style={{ width: "100%" }}>
            <div className="boton__subirContainer">
              <Button
                variant="contained"
                startIcon={
                  <CloudUploadIcon className="boton__subirManualIcon" />
                }
                className="boton__subirManual"
                to="/manuales/subir"
                style={{ backgroundColor: theme.manual }}
                component={Link}
              >
                Subir
              </Button>
            </div>
          </div> : null}
        {/* Buscador */}
        <div className="buscador__container">
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <h2 className="buscador__titulo">Buscador</h2>
            </Grid>
            <Grid item xs={12}>
              <form>
                <Grid container spacing={2}>
                  {/* <Grid item xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      size="small"
                      label="Palabra clave ..."
                      name="titulo"
                      onChange={this.onChange}
                      value={this.state.buscador.titulo}
                      className="buscador__buscador"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid> */}
                  <Grid item xs={12} md={9}>
                    <TextField
                      label="Area"
                      name="area"
                      onChange={this.onChange}
                      select
                      SelectProps={{ native: true }}
                      value={this.state.buscador.area}
                      variant="outlined"
                      size="small"
                      className="buscador__area"
                      InputLabelProps={{ shrink: true }}
                    >
                      {this.state.areas.map((area) => (
                        <option key={area.codigo} value={area.id}>
                          {area.nombre}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    md={2}
                    className="buscador__botonBuscarContainer"
                  >
                    <Button
                      className="buscador__botonBuscar"
                      style={{
                        background: theme.primary,
                        width: "100%",
                      }}
                      variant="contained"
                      type="submit"
                      onClick={this.handleSearch}
                    >
                      Buscar
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    md={1}
                    className="Buscador__botonBuscarContainer"
                  >
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={this.resetSearch}
                      fullWidth
                    >
                      Limpiar
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
            <Grid item xs={12}>
              <div className="buscador__divider">
                <Divider />
              </div>
            </Grid>
            {/* Order */}
            <Grid item xs={12}>
              <Grid
                container
                style={{ justifyContent: "flex-end !important" }}
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                  md={8}
                  xl={8}
                  sx={{ alignSelf: "flex-start !important" }}
                >
                  <h2 className="resultados__titulo">
                    {this.state.buscado ? "Resultados" : "Novedades"}
                  </h2>
                </Grid>
                <Grid item xs={12} md={2} xl={2}>
                  <TextField
                    label="Orden de resultados"
                    name="order"
                    onChange={this.onChange}
                    select
                    SelectProps={{ native: true }}
                    InputLabelProps={{ shrink: true }}
                    //value={this.state.nivel}
                    variant="filled"
                    size="small"
                    style={{ width: "100%" }}
                  >
                    {this.state.orders.map((order) => (
                      <option key={order.codigo} value={order.codigo}>
                        {order.nombre}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} md={2} xl={2}>
                  <TextField
                    label="Resultados por página"
                    name="resultados_por_pagina"
                    onChange={this.handleResultadosPorPagina}
                    select
                    SelectProps={{ native: true }}
                    InputLabelProps={{ shrink: true }}
                    variant="filled"
                    size="small"
                    style={{ width: "100%" }}
                    defaultValue={10}
                  >
                    {this.state.tamano_pagina_opciones.map((tamano) => (
                      <option key={tamano.id} value={tamano.valor}>
                        {tamano.valor}
                      </option>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        {/* Resultados */}
        <div className="capsulas__resultados">
          {this.displayManuales(theme)}
          {/* Paginamiento */}
          <div className="pagination__center">
            <Pagination
              count={this.state.buscador.num_pages}
              page={this.state.buscador.pagina}
              onChange={this.handlePagination}
              className="paginamiento"
            />
          </div>
        </div>
      </div>
    );
    return (
      <Contenido
        componente={componente}
        seccion={"Manuales Pedagógicos"}
        descripcion={"Manual pedagógico para docentes"}
        backgroundColor={theme.manual}
      ></Contenido>
    );
  }
}
const mapStateToProps = (state) => ({
  usuario: state.auth.usuario,
});

Manuales.contextType = DrawerContext;
export default compose(
  connect(mapStateToProps, {}),
  withStyles(styles, { withTheme: true })
)(Manuales);
