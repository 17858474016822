import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import Contenido from "../../components/Common/Contenido";
import { withStyles } from "@mui/styles";
import { TextField, Button, Grid } from "@mui/material";
import { withSnackbar } from "notistack";
import { getRequest, postRequest } from "../../utils/axios";

const styles = (theme) => ({
  Container__padding: {
    width: "auto",
    height: "inherit",
    textAlign: "left",
    backgroundColor: "transparent",
    paddingLeft: "calc(5% + 20px)",
    paddingRight: "calc(5% + 20px)",
  },
  Contactar__form: {
    textAlign: "center",
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.03)!important",
    bordeRadius: "5px",
    padding: "20px",
  },
  Contactar__buttons: {
    display: "flex",
    padding: "20px !important",
    justifyContent: "center",
  },
  Contactar__button: {
    margin: "10px",
  },
  input: {
    width: "100%",
  },
  textfield: {
    width: "100%",
  },
  Contactar__response: {
    height: "300px",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  "@media (max-width:950px)": {
    // eslint-disable-line no-useless-computed-key
    Contactar__form: {
      textAlign: "left",
    },
  },
});

class Contactar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        id_usuario_receiver: this.props.match.params.id,
        id_usuario_sender: this.props.usuario.id,
        asunto: "",
        mensaje: "",
      },
      errors: {
        asunto: "",
        mensaje: "",
      },
      email_send: false,
      usuario_receiver: null,
    };
  }

  async getUsuario() {
    const component = this;
    const url = `${process.env.REACT_APP_API_URL}/api/usuarios/${component.props.match.params.id}/`;
    await getRequest(url).then((response) => {
      component.setState({ usuario_receiver: response.data });
    });
  }

  componentDidMount() {
    this.getUsuario();
  }

  onChange = (e) => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    let errors = {
      asunto: "",
      mensaje: "",
    };
    let isValid = true;
    if (!this.state.form.asunto) {
      errors.asunto = "Debe ingresar un asunto";
      isValid = false;
    }
    if (!this.state.form.mensaje) {
      errors.mensaje = "Debe ingresar un mensaje";
      isValid = false;
    }
    this.setState({ errors });
    if (isValid) {
      //Send email
      this.props.enqueueSnackbar(`Enviando`);
      try {
        const response = await postRequest(
          `${process.env.REACT_APP_API_URL}/api/usuarios/${this.props.usuario.id}/enviar-email/`,
          this.state.form
        );

        if (response.status === 200) {
          this.setState({
            email_send: true,
          });
          this.props.enqueueSnackbar(`Enviado`);
        } else {
          this.props.enqueueSnackbar(`Hubo un error al enviar el correo`);
        }
      } catch (e) {
        this.props.enqueueSnackbar(`Hubo un error al enviar el correo`);
      }
    }
  };

  handleCancel = (e) => {
    //const url = this.props.match.url.split("/");
    // if (url[1] === "perfil") {
    //   this.props.history.push(`/perfil/${this.props.match.params.id}`);
    // } else {
    this.props.history.push("/comunidad");
    // }
  };

  render() {
    const { classes, theme } = this.props;
    let contenido;

    if (!this.state.usuario_receiver) {
      return null;
    }
    if (this.state.email_send) {
      contenido = (
        <div className={classes.Contactar__response}>
          <p>Mensaje enviado!</p>
        </div>
      );
    } else {
      contenido = (
        <form className={classes.Contactar__form}>
          <Grid container spacing={1}>
            <Grid container>
              <Grid item xs={12} md={3}>
                <p> Asunto </p>
              </Grid>
              <Grid item xs={12} md={9} className={classes.input}>
                <TextField
                  name="asunto"
                  onChange={this.onChange}
                  className={classes.textfield}
                  variant="outlined"
                  size="small"
                  placeholder="Ingrese asunto"
                  helperText={this.state.errors.asunto}
                  error={Boolean(this.state.errors.asunto)}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} md={3}>
                <p> Mensaje </p>
              </Grid>
              <Grid item xs={12} md={9} className={classes.input}>
                <TextField
                  name="mensaje"
                  onChange={this.onChange}
                  className={classes.textfield}
                  variant="outlined"
                  size="small"
                  multiline
                  rows={7}
                  helperText={this.state.errors.mensaje}
                  error={Boolean(this.state.errors.mensaje)}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} className={classes.Contactar__buttons}>
              <Button
                color="primary"
                variant="contained"
                className={classes.Contactar__button}
                onClick={this.handleSubmit}
              >
                {" "}
                Enviar{" "}
              </Button>
              <Button
                color="secondary"
                variant="contained"
                className={classes.Contactar__button}
                onClick={this.handleCancel}
              >
                {" "}
                Cancelar{" "}
              </Button>
            </Grid>
          </Grid>
        </form>
      );
    }

    const componente = (
      <div className={classes.Container__padding}>
        <h3>
          Enviar email a {this.state.usuario_receiver.primer_nombre}{" "}
          {this.state.usuario_receiver.apellido_paterno}
        </h3>

        {contenido}
      </div>
    );

    return (
      <Contenido
        componente={componente}
        seccion={"Comunidad Docente"}
        descripcion={"Conectando a profesores en nuestra red"}
        backgroundColor={theme.comunidad}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  usuario: state.auth.usuario,
});

export default compose(
  connect(mapStateToProps, {}),
  withStyles(styles, { withTheme: true })
)(withSnackbar(Contactar));
