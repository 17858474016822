import React from "react";
import "video.js/dist/video-js.css";
import videojs from "video.js";
import "videojs-contrib-hls";

export default class VideoPlayer extends React.Component {
  componentDidMount() {
    // instantiate Video.js
    this.player = videojs("video-capsula", {
      autoplay: true,
      controls: true,
      playbackRates: [0.5, 1, 1.5, 2],
      responsive: true,
      fluid: true,
    });
    videojs.addLanguage("en", {
      "The media could not be loaded, either because the server or network failed or because the format is not supported.":
        "Hubo un error, no se pudo cargar el video:(",
    });
  }

  // destroy player on unmount
  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }

  // wrap the player in a div with a `data-vjs-player` attribute
  // so videojs won't create additional wrapper in the DOM
  // see https://github.com/videojs/video.js/pull/3856
  render() {
    return (
      <div className="Capsula__detalle__video_container">
        <div data-vjs-player>
          <video
            id={"video-capsula"}
            className="video-js vjs-big-play-centered"
            data-setup="{}"
          >
            <source
              src={`${process.env.REACT_APP_API_URL}/streaming/${this.props.capsulaId}/manifest.m3u8`}
              type="application/x-mpegURL"
            />
          </video>
        </div>
      </div>
    );
  }
}
