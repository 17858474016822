const validateEmail = (email) => {
  const regexp =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
  return regexp.test(email);
};

const validatePassword = (password) => {
  const regexp = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,100}$/i;
  return regexp.test(password);
};

const validatePhone = () => {
  return true;
};
module.exports = {
  validateEmail,
  validatePhone,
  validatePassword,
};
