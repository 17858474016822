import axios from "axios";

import {
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
} from "./types";

// CHECK TOKEN & LOAD USER
export const loadUser = () => async (dispatch, getState) => {
  // User Loading
  //dispatch({ type: USER_LOADING });
  const refresh_token = getState().auth.refresh_token;
  if (refresh_token) {
    const body = { refresh: refresh_token };
    await axios
      .post(`${process.env.REACT_APP_API_URL}/auth/renovar_token/`, body)
      .then((res) => {
        if (res.data.statusCode === 200) {
          dispatch({
            type: USER_LOADED,
            payload: res.data,
          });
        } else {
          dispatch({
            type: AUTH_ERROR,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: AUTH_ERROR,
        });
      });
  } else {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// LOGIN USER
export const login = (email, password) => async (dispatch) => {
  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  // Request Body
  const body = {
    email: email,
    password: password,
  };

  await axios
    .post(process.env.REACT_APP_API_URL + "/auth/login/", body, config)
    .then((res) => {
      if (res.data.statusCode === 200) {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: LOGIN_FAIL,
          payload: "Usuario y/o contraseña incorrectos",
        });
      }
    })
    .catch((err) => {
      console.log(err);
      let error = "Usuario y/o contraseña incorrectos";
      if (
        err.response.data &&
        err.response.data.non_field_errors &&
        err.response.data.non_field_errors.length > 0
      ) {
        error = err.response.data.non_field_errors[0];
      }
      dispatch({
        type: LOGIN_FAIL,
        payload: error,
      });
    });
};

// LOGOUT USER
export const logout = () => async (dispatch, getState) => {
  const refresh_token = getState().auth.refresh_token;
  const body = { refresh: refresh_token };
  await axios
    .post(`${process.env.REACT_APP_API_URL}/auth/logout/`, body)
    .then((res) => {
      dispatch({
        type: LOGOUT_SUCCESS,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

// Setup config with token - helper function
export const tokenConfig = (getState) => {
  // Get token from state
  const access_token = getState().auth.access_token;

  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  // If token, add to headers config
  if (access_token) {
    config.headers["Authorization"] = `Token ${access_token}`;
  }

  return config;
};
