import React from "react";
import { DrawerContext } from "../../context/DrawerContext";
import { connect } from "react-redux";
import { compose } from "redux";
import { Link } from "react-router-dom";
import { getRequest } from "./../../utils/axios";
import { Button, Card, Container, Grid, Tooltip } from "@mui/material";
import { withStyles } from "@mui/styles";
import {
  AccountCircle,
  MenuBook,
  School,
  HomeWork,
  PermIdentity,
  Email,
} from "@mui/icons-material";
import Slider from "../../components/Slider/Slider";
import Contenido from "../../components/Common/Contenido";
//import { LinkButton } from "../../common/buttons";
import "./Perfil.css";

const styles = (theme) => ({});

class Item extends React.Component {
  constructor(props) {
    super();
    this.state = { titulo: props.item.titulo, url: props.item.url };
  }
  render() {
    return (
      <Card
        key={this.state.titulo}
        style={{ margin: "0 10px 0 10px", height: "150px" }}
      ></Card>
    );
  }
}
class Perfil extends React.Component {
  state = {
    capsulas: [
      { titulo: "Enseñar a  ..", url: "" },
      { titulo: "Enseñar a  ..", url: "" },
      { titulo: "Enseñar a  ..", url: "" },
      { titulo: "Enseñar a  ..", url: "" },
    ],
    manuales: [
      { titulo: "Enseñar a  ..", url: "" },
      { titulo: "Enseñar a  ..", url: "" },
      { titulo: "Enseñar a  ..", url: "" },
      { titulo: "Enseñar a  ..", url: "" },
    ],
    usuario: null,
    showAllSchool: false,
  };

  async componentDidMount() {
    this.context.setCurrentDrawerItem("Perfil");
    const component = this;
    const id = this.props.match.params.id
      ? this.props.match.params.id
      : component.props.usuario.id;

    await this.getUsuario(id);
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      const id = this.props.match.params.id
        ? this.props.match.params.id
        : this.props.usuario.id;
      await this.getUsuario(id);
    }
  }

  async getUsuario(id) {
    const url = `${process.env.REACT_APP_API_URL}/api/usuarios/${id}/`;
    await getRequest(url).then((response) => {
      this.setState({ usuario: response.data });
    });
  }

  slider(items) {
    const arrayElements = [];
    items.forEach((item) =>
      arrayElements.push(<Item item={item} key={item.titulo} />)
    );
    return (
      <div className={this.props.classes.customSlider}>
        <Slider style={{ height: "100%" }} items={arrayElements} />
      </div>
    );
  }

  displayColegios(colegios, showAll) {
    if (showAll) {
      return colegios.map((colegio) => <span key={colegio}>{colegio}</span>);
    }
    if (colegios.length > 1) {
      return `${colegios[0]} y ${colegios.length - 1} más`;
    } else if (colegios.length === 1) {
      return colegios;
    }
    return "Sin colegio";
  }
  render() {
    if (!this.state.usuario) {
      return null;
    }
    const id_usuario = this.props.match.params.id;
    const { theme } = this.props;
    const colegios = this.state.usuario.usuario_asociado
      ? this.state.usuario.usuario_asociado.colegio
      : [];
    const especialidades = this.state.usuario.usuario_asociado
      ? this.state.usuario.usuario_asociado.especialidad
      : [];
    const componente = (
      <div className="Perfil__root">
        <Container className="Perfil__container">
          <div className="Perfil__box">
            <Grid container spacing={1}>
              <Grid item xs={12} md={3} className="Perfil__imageSection">
                {this.state.usuario.foto_perfil ? (
                  <img
                    src={`${process.env.REACT_APP_API_URL
                      }/miniaturas/usuarios/${this.state.usuario.id
                      }?${new Date().getTime()};`}
                    className="Perfil__imageAccount"
                    alt="foto"
                  />
                ) : (
                  <AccountCircle className="Perfil__iconAccount" />
                )}
              </Grid>
              <Grid item xs={12} md={5} className="Perfil__Info">
                <span className="Perfil__textInfo">
                  <Tooltip title="Nombre">
                    <PermIdentity className="Perfil__icon" />
                  </Tooltip>
                  {this.state.usuario.primer_nombre +
                    " " +
                    this.state.usuario.apellido_paterno}
                </span>
                {this.state.usuario.rol === "Profesor" &&
                  this.state.usuario.usuario_asociado ? (
                  <div>
                    <div className="Perfil__textInfo">
                      <Tooltip title="Colegios">
                        <HomeWork className="Perfil__icon" />
                      </Tooltip>
                      <div className="Perfil__list">
                        {this.state.showAllSchool
                          ? this.displayColegios(colegios, true)
                          : this.displayColegios(colegios, false)}
                        {colegios.length > 1 ? (
                          this.state.showAllSchool ? (
                            <span
                              className="Perfil__colegios_link"
                              onClick={() =>
                                this.setState({ showAllSchool: false })
                              }
                            >
                              Ocultar colegios
                            </span>
                          ) : (
                            <span
                              className="Perfil__colegios_link"
                              onClick={() =>
                                this.setState({ showAllSchool: true })
                              }
                            >
                              Mostrar todos
                            </span>
                          )
                        ) : null}
                      </div>
                    </div>

                    <span className="Perfil__textInfo">
                      <Tooltip title="Titulo profesional">
                        <School className="Perfil__icon" />
                      </Tooltip>
                      {this.state.usuario.usuario_asociado.titulo_profesional}
                    </span>
                    <span className="Perfil__textInfo">
                      <Tooltip title="Especialidad">
                        <MenuBook className="Perfil__icon" />
                      </Tooltip>
                      <div className="Perfil__list">
                        {especialidades.map((especialidad) => (
                          <span key={especialidad}>{especialidad}</span>
                        ))}
                      </div>
                    </span>
                  </div>
                ) : null}
              </Grid>
              <Grid item xs={12} md={4} className="Perfil__row_button">
                {id_usuario ? (
                  <Button
                    color="primary"
                    variant="contained"
                    component={Link}
                    to={`/perfil/${id_usuario}/contactar`}
                  >
                    {" "}
                    <Email /> Contactar
                  </Button>
                ) : (
                  <Button
                    color="primary"
                    variant="contained"
                    className="Perfil__button"
                    component={Link}
                    to={"/perfil/editar"}
                  >
                    Editar mi perfil
                  </Button>
                )}
              </Grid>
            </Grid>
            {id_usuario ? null : (
              <Grid className="Perfil__row_button Perfil__fullWidth">
                {/*
                <LinkButton
                  to={"/capsulas/subir"}
                  text={"Subir cápsula"}
                  className="Perfil__button"
                  color={theme.capsula}
                />*/}
                {/* <LinkButton
                  to={"/manuales/subir"}
                  text={"Subir manual"}
                  className="Perfil__button"
                  color={theme.manual}
                /> */}
              </Grid>
            )}
          </div>
          {/*
          <div className="Perfil__box Perfil__boxBigger">
            <Card className="manuales}>
              <div className="section}>
                <h5>Manuales subidos</h5>
                {this.slider(this.state.manuales)}
              </div>
              <div className="section}>
                <h5>Manuales más valorados</h5>
                {this.slider(this.state.manuales)}
              </div>
            </Card>
          </div>
          <div className="Perfil__box Perfil__boxBigger">
            <Card className="capsulas}>
              <div className="section}>
                <h5>Capsulas subidas</h5>
                {this.slider(this.state.capsulas)}
              </div>
              <div className="section}>
                <h5>Capsulas más valorados</h5>
                {this.slider(this.state.capsulas)}
              </div>
            </Card>
          </div>
          */}
        </Container>
      </div>
    );
    return (
      <Contenido
        componente={componente}
        seccion={id_usuario ? "Perfil" : "Mi Perfil"}
        descripcion={" "}
        backgroundColor={theme.comunidad}
      />
    );
  }
}
Perfil.contextType = DrawerContext;

const mapStateToProps = (state) => ({
  usuario: state.auth.usuario,
});

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, {})
)(Perfil);
