import React from "react";
import FormData from "form-data";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { getRequest, putRequest } from "../../../utils/axios";
import {
  CircularProgress,
  Card,
  TextField,
  Grid,
  Button,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { withSnackbar } from "notistack";
import Contenido from "../../../components/Common/Contenido";
import "../../../App.css";
import { CustomerButton } from "../../../common/buttons";
import "./editarManual.css";

const styles = (theme) => ({});

class EditarManual extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      autor: `${this.props.usuario.primer_nombre} ${this.props.usuario.apellido_paterno}`,
      manual_id: this.props.match.params.id,
      manual: {
        titulo: "",
        autor: `${this.props.usuario.primer_nombre} ${this.props.usuario.apellido_paterno}`,
        id_area: "Seleccionar",
        descripcion: "",
        //palabrasclaves: [],
        id_usuario: this.props.usuario.id,
      },
      errors: {
        titulo: "",
        autor: "",
        id_area: "",
        descripcion: "",
      },
      submited: false,
      loading: false,
      areas: [{ codigo: 0, nombre: "Seleccionar" }],
    };
  }

  async componentDidMount() {
    let component = this;

    async function getManual() {
      await getRequest(
        `${process.env.REACT_APP_API_URL}/api/manuales/${component.props.match.params.id}/`
      )
        .then((response) => {
          component.setState({ manual: response.data }, () => {
            component.setState({
              manual: {
                ...component.state.manual,
                id_area: String(component.state.manual.id_area.nombre),
              },
            });
          });
        })
        .catch((e) => {
          console.log(e);
        });
    }

    async function getAreas() {
      getRequest(
        `${process.env.REACT_APP_API_URL}/api/curriculum_escolar/areas/`
      ).then((response) => {
        const res_areas = response.data;
        res_areas.unshift({ codigo: 0, nombre: "Seleccionar" });
        component.setState({ areas: res_areas });
      });
    }
    await getAreas();
    await getManual();
  }

  onChange = (e) => {
    this.setState({
      manual: {
        ...this.state.manual,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleUpload = async (e) => {
    e.preventDefault();
    let isValid = true;
    let errors = {
      titulo: "",
      autor: "",
      id_area: "",
      descripcion: "",
    };
    if (!this.state.manual.titulo) {
      errors.titulo = "Debe ingresar un título";
      isValid = false;
    }
    if (this.state.manual.titulo.length > 150) {
      errors.titulo = "Puede ingresar hasta 150 caracteres";
      isValid = false;
    }
    if (this.state.manual.descripcion.length > 1000) {
      errors.descripcion = "Puede ingresar hasta 1000 caracteres";
      isValid = false;
    }
    if (this.state.manual.id_area === "Seleccionar") {
      errors.id_area = "Debe ingresar una area";
      isValid = false;
    }
    if (!this.state.manual.descripcion) {
      errors.descripcion = "Debe ingresar una descripción";
      isValid = false;
    }
    this.setState({ errors: errors });
    if (isValid) {
      this.setState({ loading: true });
      let response;
      try {
        var data = new FormData();
        data.append("id", this.state.manual.id);
        data.append("titulo", this.state.manual.titulo);
        data.append("descripcion", this.state.manual.descripcion);
        data.append("id_area", this.state.manual.id_area);

        response = await putRequest(
          `${process.env.REACT_APP_API_URL}/api/manuales/`,
          data
        );

        if (response.status === 204) {
          this.setState({
            submited: true,
          });
          this.props.enqueueSnackbar(
            `Manual ${this.state.manual.titulo} editado exitosamente!`
          );
        }
      } catch (e) {
        console.log(e);
        this.props.enqueueSnackbar("No se pudo editar el manual");
      } finally {
        this.setState({ loading: false });
      }
    }
  };

  render() {
    const { theme } = this.props;
    let componente;
    if (this.state.loading) {
      componente = (
        <div className="root loading">
          <CircularProgress />
          <h3>Subiendo cambios</h3>
        </div>
      );
    } else if (this.state.submited) {
      return <Redirect to={`/manuales/${this.state.manual.id}`} />;
    } else {
      componente = (
        <div className="root">
          <Card variant="outlined" className="container">
            <form className="form_manuales" onSubmit={this.handleUpload}>
              <Grid container spacing={2} justifyContent="center">
                <Grid container item justifyContent="center">
                  <p className="label">Título</p>
                  <TextField
                    variant="outlined"
                    size="small"
                    name="titulo"
                    value={this.state.manual.titulo}
                    onChange={this.onChange}
                    className="input"
                    helperText={this.state.errors.titulo}
                    error={Boolean(this.state.errors.titulo)}
                  />
                </Grid>

                <Grid container item justifyContent="center">
                  <p className="label">Autor/a</p>
                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    disabled
                    variant="outlined"
                    size="small"
                    name="autor"
                    value={this.state.autor}
                    className="input"
                  />
                </Grid>
                <Grid container item justifyContent="center">
                  <p className="label">Área</p>
                  <TextField
                    name="id_area"
                    onChange={this.onChange}
                    select
                    value={this.state.manual.id_area}
                    defaultValue={this.state.manual.id_area}
                    variant="outlined"
                    size="small"
                    className="input"
                    error={Boolean(this.state.errors.id_area)}
                    helperText={this.state.errors.id_area}
                  >
                    {this.state.areas.map((area) => {
                      return (
                        <option key={area.codigo} value={area.nombre}>
                          {area.nombre}
                        </option>
                      );
                    })}
                  </TextField>
                </Grid>
                <Grid container item justifyContent="center">
                  <p className="label">Descripción</p>
                  <TextField
                    variant="outlined"
                    size="small"
                    multiline
                    rows={7}
                    name="descripcion"
                    value={this.state.manual.descripcion}
                    onChange={this.onChange}
                    className="input"
                    error={Boolean(this.state.errors.descripcion)}
                    helperText={this.state.errors.descripcion}
                  />
                </Grid>
                {/*
                <Grid container item justifyContent="center">
                  <p className="label">Palabras claves</p>
                  <TextField
                    variant="outlined"
                    size="small"
                    multiline
                    rows={7}
                    name="descripcion"
                    value={this.state.manual.palabrasclaves}
                    onChange={this.onChange}
                    className="input"
                  />
                </Grid>*/}
              </Grid>
            </form>
            <div className="button__Container">
              <CustomerButton
                type="submit"
                color={theme.guardar}
                handle={(e) => this.handleUpload(e)}
                text={"Guardar"}
              />
              <Button
                color="secondary"
                variant="contained"
                component={Link}
                to={`/manuales/${this.state.manual.id}`}
              >
                Cancelar
              </Button>
            </div>
          </Card>
        </div>
      );
    }
    return (
      <Contenido
        componente={componente}
        seccion={"Editar manual"}
        descripcion={"Manual pedagógico para docentes"}
        backgroundColor={theme.manual}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  usuario: state.auth.usuario,
});

export default compose(
  connect(mapStateToProps, {}),
  withStyles(styles, { withTheme: true })
)(withSnackbar(EditarManual));
