import React from "react";
import { postRequest } from "../../utils/axios";
import { connect } from "react-redux";
import { compose } from "redux";
import { TextField, Button } from "@mui/material";
import { withStyles } from "@mui/styles";
import {
  AccountCircle,
  ArrowDropDown,
  ArrowDropUp,
} from "@mui/icons-material";
import "./Comentarios.css";
import { deltaTime } from "../../utils/utils";

const styles = (theme) => ({});

class Comentarios extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      usuario: this.props.usuario,
      tipo_recurso: this.props.tipo_recurso, // id_manual  id_capsula id_material
      id_recurso: this.props.id_recurso,
      comentarios_base: this.props.comentarios_base,
      comentarios_hijos: this.props.comentarios_hijos,
      urlPost: this.props.urlPost,
    };
  }

  addCommentRoot = async (body) => {
    try {
      const response = await postRequest(`${this.state.urlPost}`, body);
      if (response.status === 201) {
        this.state.comentarios_base.unshift(response.data);
        this.setState({ comentarios_base: this.state.comentarios_base });
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  };

  render() {
    return (
      <div>
        <h4>Comentarios</h4>
        <MiComentario
          usuario={this.state.usuario}
          isRoot={true}
          addCommentRoot={this.addCommentRoot}
          tipo_recurso={this.state.tipo_recurso}
          id_recurso={this.state.id_recurso}
        />
        {this.state.comentarios_base.map((comentario) => (
          <NodoComentario
            key={comentario.id}
            comentario={comentario}
            comentarios_hijos={this.state.comentarios_hijos}
            usuario={this.state.usuario}
            urlPost={this.state.urlPost}
            tipo_recurso={this.state.tipo_recurso}
            id_recurso={this.state.id_recurso}
          />
        ))}
      </div>
    );
  }
}

class NodoComentario extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      usuario: this.props.usuario,
      comentario: this.props.comentario,
      comentarios_hijos: this.props.comentarios_hijos,
      comentarios_respuesta: [],
      tipo_recurso: this.props.tipo_recurso,
      urlPost: this.props.urlPost,
      id_recurso: this.props.id_recurso,
      mostrarComentarios: false,
      responder: false,
    };
    this.handleShowComments = this.handleShowComments.bind(this);
    this.handleReply = this.handleReply.bind(this);
  }

  componentDidMount() {
    const comentarios_respuesta = this.state.comentarios_hijos.filter(
      (comentarioHijo) =>
        comentarioHijo.id_respuesta_comentario.id === this.state.comentario.id
    );
    this.setState({ comentarios_respuesta });
  }

  handleShowComments() {
    this.setState({ mostrarComentarios: !this.state.mostrarComentarios });
  }

  handleReply(value) {
    this.setState({
      responder: value,
    });
  }

  adCommentNodeResponse = async (body) => {
    try {
      body["id_respuesta_comentario"] = this.state.comentario.id;
      const response = await postRequest(`${this.state.urlPost}`, body);
      if (response.status === 201) {
        this.setState(
          {
            comentarios_respuesta: [
              response.data,
              ...this.state.comentarios_respuesta,
            ],
          },
          () => {
            this.setState({ mostrarComentarios: true });
          }
        );
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  };

  render() {
    return (
      <div className="Comentario__Container">
        <div className="Comentario__imagen">
          {" "}
          {this.state.comentario.id_usuario.foto_perfil &&
            this.state.comentario.id_usuario.foto_perfil !== "" ? (
            <img
              src={`${process.env.REACT_APP_API_URL}/miniaturas/usuarios_min/${this.state.comentario.id_usuario.id}`}
              className="usuario_icon"
              alt="foto"
            />
          ) : (
            <AccountCircle style={{ fontSzize: "43px !important" }} />
          )}
        </div>
        <div className="Comentario__info">
          <div>
            <span className="Comentario__usuario">
              {this.state.comentario.id_usuario.primer_nombre +
                " " +
                this.state.comentario.id_usuario.apellido_paterno}
            </span>
            <span className="Comentario__fecha">
              {`• ${deltaTime(this.state.comentario.fecha_creacion)}`}
            </span>
          </div>
          <span className="Comentario__contenido">
            {this.state.comentario.contenido}
          </span>
          <span>
            {" "}
            <Button
              color="secondary"
              className="Comentario__boton_responder"
              onClick={() => this.handleReply(true)}
            >
              Responder
            </Button>
          </span>
          {this.state.responder ? (
            <MiComentario
              usuario={this.state.usuario}
              isRoot={false}
              tipo_recurso={this.state.tipo_recurso}
              id_recurso={this.state.id_recurso}
              handleReply={this.handleReply}
              adCommentNodeResponse={this.adCommentNodeResponse}
            />
          ) : null}
          {this.state.comentarios_respuesta.length <= 0 ? null : this.state
            .mostrarComentarios ? (
            <span>
              {" "}
              <Button
                className="Comentario__boton_respuestas"
                onClick={this.handleShowComments}
              >
                <ArrowDropUp />
                Ocultar {this.state.comentarios_respuesta.length} respuestas
              </Button>
              <div>
                {this.state.comentarios_respuesta.map(
                  (comentarioRespuesta, i) => {
                    return (
                      <NodoRespuesta
                        key={i}
                        comentario={comentarioRespuesta}
                      />
                    );
                  }
                )}
              </div>
            </span>
          ) : (
            <span>
              <Button
                className="Comentario__boton_respuestas"
                onClick={this.handleShowComments}
              >
                <ArrowDropDown /> Ver{" "}
                {this.state.comentarios_respuesta.length} respuestas
              </Button>
            </span>
          )}
        </div>
      </div>
    );
  }
}

class NodoRespuesta extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { comentario } = this.props;
    return (
      <div className="Comentario__Container">
        <div className="Comentario__imagen">
          {" "}
          {comentario.id_usuario.foto_perfil &&
            comentario.id_usuario.foto_perfil !== "" ? (
            <img
              src={`${process.env.REACT_APP_API_URL}/miniaturas/usuarios_min/${comentario.id_usuario.id}`}
              className="usuario_icon"
              alt="foto"
            />
          ) : (
            <AccountCircle style={{ fontSzize: "43px !important" }} />
          )}
        </div>
        <div className="Comentario__info">
          <div>
            <span className="Comentario__usuario">
              {comentario.id_usuario.primer_nombre +
                " " +
                comentario.id_usuario.apellido_paterno}
            </span>
            <span className="Comentario__fecha">
              {`• ${deltaTime(comentario.fecha_creacion)}`}
            </span>
          </div>
          <span className="Comentario__contenido">
            {comentario.contenido}
          </span>
        </div>
      </div>
    );
  }
}

class MiComentario extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: { contenido: "" },
      errors: { contenido: "" },
      usuario: this.props.usuario,
      tipo_recurso: this.props.tipo_recurso,
      id_recurso: this.props.id_recurso,
      handleReply: this.props.handleReply,
      isRoot: this.props.isRoot,
      addCommentRoot: this.props.addCommentRoot,
      adCommentNodeResponse: this.props.adCommentNodeResponse,
    };
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  onChange = (e) => {
    this.setState({
      form: {
        contenido: e.target.value,
      },
    });
  };

  handleCancel() {
    this.setState({
      form: {
        contenido: "",
      },
    });
    if (!this.state.isRoot) {
      this.state.handleReply(false);
    }
  }

  async handleSubmit(e) {
    e.preventDefault();

    let isValid = true;
    let errors = {
      contenido: "",
    };
    if (!this.state.form.contenido) {
      errors.contenido = "Debe ingresar un comentario";
      isValid = false;
    }
    this.setState({ errors });
    if (isValid) {
      let form;
      form = {
        id_usuario: this.state.usuario.id,
        contenido: this.state.form.contenido,
      };
      form[this.state.tipo_recurso] = this.state.id_recurso;
      if (this.state.isRoot) {
        await this.state.addCommentRoot(form);
      } else {
        await this.state.adCommentNodeResponse(form);
      }
      this.handleCancel();
    }
  }
  render() {
    return (
      <div className="Comentario__Container">
        <div className="Comentario__imagen">
          {" "}
          {this.state.usuario.foto_perfil &&
            this.state.usuario.foto_perfil !== "" ? (
            <img
              src={`${process.env.REACT_APP_API_URL}/miniaturas/usuarios_min/${this.state.usuario.id}`}
              className="usuario_icon"
              alt="foto"
            />
          ) : (
            <AccountCircle style={{ fontSzize: "43px !important" }} />
          )}
        </div>
        <div className="Comentario__info">
          <span className="Comentario__usuario">
            {this.state.usuario.primer_nombre +
              " " +
              this.state.usuario.apellido_paterno}
          </span>
          <form onSubmit={this.handleSubmit}>
            <span className="Comentario__contenido">
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                name="nuevo_comentario"
                label="Agrega un comentario..."
                onChange={this.onChange}
                value={this.state.form.contenido}
                helperText={this.state.errors.contenido}
                error={Boolean(this.state.errors.contenido)}
              />
            </span>
          </form>
          <div className="Comentario__botones">
            <Button
              color="secondary"
              variant="contained"
              onClick={this.handleCancel}
              className="comentar"
            >
              Cancelar
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={this.handleSubmit}
            >
              Comentar
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  usuario: state.auth.usuario,
});

export default compose(
  connect(mapStateToProps, {}),
  withStyles(styles, { withTheme: true })
)(Comentarios);
