import React from "react";
import { DrawerContext } from "../../context/DrawerContext";
import { compose } from "redux";
import { connect } from "react-redux";
import { getRequest } from "../../utils/axios";
import Contenido from "../../components/Common/Contenido";
import { TextField, Button, Grid, Divider } from "@mui/material";
import { withStyles } from "@mui/styles";
import "./ComunidadDocente.css";
import Pagination from '@mui/material/Pagination'; // Paginamiento
import Profesor from "../../components/ComunidadDocente/Profesor";
import SkeletonProfesor from "../../components/ComunidadDocente/SkeletonProfesor";

const styles = () => ({});

class Comunidad extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buscador: {
        profesor: "",
        colegio: "",
        area: "",
        pagina: 1,
        tamano_pagina: 10,
        num_pages: -1,
        num_results: -1,
        usuario: this.props.usuario.id,
      },
      areas: [{ codigo: 0, nombre: "Seleccionar" }],
      colegios: [],
      profesores: [],
      tamano_pagina_opciones: [
        { id: 0, valor: 5 },
        { id: 1, valor: 10 },
        { id: 2, valor: 20 },
        { id: 3, valor: 50 },
        { id: 4, valor: 100 },
      ],
      cargando: true,
    };
  }

  componentDidMount() {
    this.context.setCurrentDrawerItem("Comunidad Docente");
    let component = this;

    async function getColegios() {
      await getRequest(`${process.env.REACT_APP_API_URL}/api/colegios/`)
        .then((response) => {
          const res_colegios = response.data;
          res_colegios.unshift({ id: 0, nombre: "Seleccionar" });
          component.setState({ colegios: res_colegios });
        })
        .catch((e) => {
          console.log(e);
        });
    }
    async function getAreas() {
      const url = `${process.env.REACT_APP_API_URL}/api/curriculum_escolar/areas/`;
      await getRequest(url).then((response) => {
        const res_areas = response.data;
        res_areas.unshift({ codigo: 0, nombre: "Seleccionar" });
        component.setState({ areas: res_areas });
      });
    }
    async function getProfesores() {
      const url = `${process.env.REACT_APP_API_URL}/api/usuarios/profesores/`;
      await getRequest(url, {
        pagina: 1,
        tamano_pagina: 10,
        usuario: component.state.buscador.usuario,
      })
        .then((response) => {
          component.setState({
            profesores: response.data.profesor,
            buscador: {
              ...component.state.buscador,
              num_pages: response.data.num_pages,
              num_results: response.data.num_results,
            },
            cargando: false,
          });
        })
        .catch((e) => {
          console.log(e);
          component.setState({ cargando: false });
        });
    }
    getColegios();
    getAreas();
    getProfesores();
  }

  onChange = (e) => {
    this.setState({
      buscador: {
        ...this.state.buscador,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleSearch = async (e) => {
    if (e != null) e.preventDefault();
    let query = this.state.buscador;
    if (query.colegio === "Seleccionar") {
      query.colegio = "";
    }
    if (query.area === "Seleccionar") {
      query.area = "";
    }
    await getRequest(
      `${process.env.REACT_APP_API_URL}/api/usuarios/profesores/`,
      query
    ).then((response) => {
      this.setState({
        profesores: response.data.profesor,
        buscador: {
          ...this.state.buscador,
          num_pages: response.data.num_pages,
          num_results: response.data.num_results,
        },
      });
    });
  };

  handlePagination = (e, value) => {
    this.setState(
      { buscador: { ...this.state.buscador, pagina: parseInt(value) } },
      () => this.handleSearch()
    );
  };

  handleResultadosPorPagina = (e) => {
    this.setState(
      {
        buscador: {
          ...this.state.buscador,
          pagina: 1,
          tamano_pagina: parseInt(e.target.value),
        },
      }, // vuelta a pagina 1, pero cambiando tamano_pagina
      () => this.handleSearch()
    ); // Se realiza nueva bpusqueda
  };

  resetSearch = () => {
    this.setState(
      {
        buscador: {
          profesor: "",
          colegio: "",
          area: "",
          pagina: 1,
          tamano_pagina: 10,
          num_pages: -1,
          num_results: -1,
          usuario: this.props.usuario.id,
        },
      },
      () => this.handleSearch()
    );
  };

  displayProfesores = () => {
    if (this.state.cargando) {
      const array = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
      return (
        <div className="profesores">
          {array.map((i) => (
            <SkeletonProfesor key={i} />
          ))}
        </div>
      );
    } else if (this.state.profesores.length > 0)
      return (
        <div className="profesores">
          {this.state.profesores.map((profesor) => (
            <Profesor key={profesor.usuario.id} profesor={profesor} />
          ))}
        </div>
      );
    return (
      <div className="center">
        <p>No se encontraron profesores</p>
      </div>
    );
  };

  render() {
    const { theme } = this.props;
    const componente = (
      <div>
        {/* Buscador */}
        <div className="Comunidad__container">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <h2 className="Buscador__titulo">Buscador</h2>
            </Grid>
            <Grid item xs={12}>
              <form>
                <Grid container spacing={2}>
                  {/* <Grid item xs={12} md={4}>
                    <TextField
                      variant="outlined"
                      size="small"
                      className="Buscador__buscador"
                      label="Nombre profesor"
                      name="profesor"
                      onChange={this.onChange}
                      value={this.state.buscador.profesor}
                    />
                  </Grid> */}
                  <Grid item xs={12} md={4}>
                    <TextField
                      label="Colegio"
                      name="colegio"
                      onChange={this.onChange}
                      select
                      SelectProps={{ native: true }}
                      value={this.state.buscador.colegio}
                      variant="outlined"
                      size="small"
                      className="Buscador__area"
                      InputLabelProps={{ shrink: true }}
                    >
                      {this.state.colegios.map((colegio) => {
                        return (
                          <option key={colegio.id} value={colegio.id}>
                            {colegio.nombre}
                          </option>
                        );
                      })}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <TextField
                      label="Area"
                      name="area"
                      onChange={this.onChange}
                      select
                      SelectProps={{ native: true }}
                      value={this.state.buscador.area}
                      variant="outlined"
                      size="small"
                      className="Buscador__area"
                      InputLabelProps={{ shrink: true }}
                    >
                      {this.state.areas.map((area) => (
                        <option key={area.codigo} value={area.id}>
                          {area.nombre}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    md={2}
                    className="Buscador__botonBuscarContainer"
                  >
                    <Button
                      className="Buscador__botonBuscar"
                      color="primary"
                      variant="contained"
                      type="submit"
                      onClick={this.handleSearch}
                      fullWidth
                    >
                      Buscar
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    md={1}
                    className="Buscador__botonBuscarContainer"
                  >
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={this.resetSearch}
                      fullWidth
                    >
                      Limpiar
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
            <Grid item xs={12} className="Buscador__divider">
              <Divider />
            </Grid>
          </Grid>
        </div>
        <div className="Comunidad__Resultados__padding">
          {this.displayProfesores()}

          {/* Paginamiento */}
          <div className="pagination__center">
            <Pagination
              count={this.state.buscador.num_pages}
              page={this.state.buscador.pagina}
              onChange={this.handlePagination}
              className="paginamiento"
            />
            <div className="Container_resultados__pagina">
              <p> Resultados por página</p>
              <TextField
                name="resultados_por_pagina"
                onChange={this.handleResultadosPorPagina}
                select
                SelectProps={{ native: true }}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                size="small"
                defaultValue={10}
                className="resultados__pagina"
              >
                {this.state.tamano_pagina_opciones.map((tamano) => (
                  <option key={tamano.id} value={tamano.valor}>
                    {tamano.valor}
                  </option>
                ))}
              </TextField>
            </div>
          </div>
        </div>
      </div>
    );
    return (
      <Contenido
        componente={componente}
        seccion={"Comunidad Docente"}
        descripcion={"Conectando a profesores en nuestra red"}
        backgroundColor={theme.comunidad}
      />
    );
  }
}
Comunidad.contextType = DrawerContext;

const mapStateToProps = (state) => ({
  usuario: state.auth.usuario,
});

export default compose(
  connect(mapStateToProps, {}),
  withStyles(styles, { withTheme: true })
)(Comunidad);
