import React from "react";
import { Typography, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import "./Footer.css";


const useStyles = makeStyles((theme) => ({
  logo_footer_fch: {
    width: "300px"
  },

  logo_footer_core: {
    width: "165px"
  },

  logo_footer_gore: {
    width: "200px"
  }
}));


export function Footer() {
  const classes = useStyles();

  return (
    <footer>
      <Grid Container justifyContent="space-around" padding={4} alignItems="center" textAlign="center">
        <Grid item xs={12} md={12}>
          <Typography
            variant="body"
            component="p"
            className=""
          >Linkki Aula Innova Tarapacá es un software construido por la Facultad de Ciencias Humanas de Universidad Arturo Prat y es financiado por el Gobierno Regional de Tarapacá, en base a los requerimientos y necesidades de los equipos docentes tarpaqueños, el cual constituye una herramienta pedagógica para el apoyo a la innovación metodológica curricular y el trabajo en red en búsqueda de la mejora continua del proceso de enseñanza aprendizaje de las aulas tarapaqueñas.</Typography>
        </Grid>
        <Grid container alignItems="center">
          <Grid item xs={12} md={4} lg={4}>
            <img src="/footer/logo-gore-tarapaca.png" alt="logo-gore-tarapaca" className={classes.logo_footer_gore} />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <img src="/footer/logo-core-tarapaca.png" alt="logo-core-tarapaca" className={classes.logo_footer_core} />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <img src="/footer/logo-facultad-ciencias-humanas.png" alt="logo-facultad-ciencias-humanas" className={classes.logo_footer_fch} />
          </Grid>
        </Grid>
      </Grid>
    </footer>
  );
};
