import React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogActions,
  DialogContent,
  Grid,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { CustomerButton } from "../../../common/buttons";
import { postRequest } from "../../../utils/axios";
import { withSnackbar } from "notistack";
import "./EditarPerfil.css";

import AvatarEditor from 'react-avatar-editor'
import Slider from '@mui/material/Slider';

const styles = (theme) => ({});

class EditarFoto extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: this.props.openModal,
      handleClick: this.props.handleClick,
      foto: this.props.usuario.foto_perfil,
      newFoto: false,
      scale: 1.0,
      rotate: 0,
      editor: null,
      newImage: null,
      name: "",
      type: ""
    };
  }

  handleClose = () => {
    this.state.handleClick(false);
  };

  submitPhoto = async () => {
    if (this.state.editor) {
      const canvas = this.state.editor.getImage().toDataURL();
      let b;
      fetch(canvas)
        .then(res => res.blob())
        .then(async blob => {
          b = new File([blob], this.state.name);
          var data = new FormData();

          data.append("foto", b);
          try {
            const res = await postRequest(
              `${process.env.REACT_APP_API_URL}/api/usuarios/${this.props.usuario.id}/foto/`,
              data
            );
            if (res.status === 201) {
              //Actualizar foto del perfil
              await this.props.updateUsuario();
              var image = document.getElementById("image_profile");
              image.src = `${process.env.REACT_APP_API_URL}/miniaturas/usuarios/${this.props.usuario.id
                }?${new Date().getTime()};`;
              var navImage = document.getElementById("navbar_profile_photo");
              navImage.src = image.src
              this.props.enqueueSnackbar(
                `Tu foto de perfil se actualizo correctamente!`
              );
            } else {
              this.props.enqueueSnackbar(
                `Tu foto de perfil no se pudo actualizar :(`
              );
            }
          }
          catch (err) {
            this.props.enqueueSnackbar(
              `Tu foto de perfil no se pudo actualizar :(`
            );
          }

        });
      this.setState({ foto: b, newFoto: false, scale: 1.0, rotate: 0, editor: null, newImage: null, name: "", type: "" })
    }
    this.handleClose();
  };

  onChange = (e) => {
    const file = e.target.files[0];
    var imageType = /image.*/;
    var image = document.getElementById("edit_profile_photo");
    if (file.type.match(imageType)) {
      var reader = new FileReader();
      reader.onload = function (e) {
        image.src = reader.result;
      };
      reader.readAsDataURL(file);
      this.setState({ foto: e.target.files[0], newFoto: true, name: e.target.files[0].name, type: e.target.files[0].type });
    } else {
      console.log("Foto no soportada");
    }
  };

  handleChangeScale = (event, newValue) => {
    this.setState({ scale: newValue })
  };

  rotarIzquierda = () => {
    this.setState({ rotate: (this.state.rotate - 90) % 360 })
  }

  rotarDerecha = () => {
    this.setState({ rotate: (this.state.rotate + 90) % 360 })
  }

  setEditorRef = (ed) => this.setState({ editor: ed })

  render() {
    return (
      <div>
        <Dialog
          open={this.state.openModal}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
          fullWidth={true}
          maxWidth="md"
        >
          <DialogTitle id="responsive-dialog-title">
            Editar foto de perfil
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              En la foto de perfil se debe ver tu rostro.
            </DialogContentText>
            <Grid container spacing={1}>
              <Grid item xs={12} className="EditarFoto__grid_item">
                {this.state.newFoto ? (
                  <Grid container className="EditarFoto__grid_item">
                    <Grid item xs={12} className="EditarFoto__grid_item">
                      <AvatarEditor
                        ref={this.setEditorRef}
                        image={this.state.foto}
                        width={200}
                        height={200}
                        border={0}
                        borderRadius={100}
                        color={[255, 255, 255, 0.6]} // RGBA
                        scale={this.state.scale}
                        rotate={this.state.rotate}
                        // className="EditarFoto__account_image"
                        alt="foto"
                        style={{ border: "1px solid #757577" }}
                      />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: "10px" }}>
                      <Grid container spacing={1} style={{ paddingLeft: "25%", paddingRight: "25%" }}>
                        <Grid item xs={12} style={{ textAlign: "center", fontWeight: 700 }}>
                          Zoom
                        </Grid>
                        <Grid item xs={12} style={{ paddingLeft: "5px", paddingRight: "5px" }}>
                          <Slider
                            value={this.state.scale}
                            onChange={this.handleChangeScale}
                            aria-labelledby="continuous-slider"
                            min={1}
                            max={3}
                            step={0.00000001}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={1} style={{ paddingLeft: "25%", paddingRight: "25%" }}>
                        <Grid item xs={12} style={{ textAlign: "center", fontWeight: 700 }} >
                          Rotar
                        </Grid>
                        <Grid item xs={12} >
                          <Button
                            onClick={this.rotarIzquierda}
                            color="secondary"
                            variant="contained"
                            autoFocus
                            style={{ width: "calc(50% - 5px)", marginRight: "5px" }}
                          >
                            Izquierda
                          </Button>
                          <Button
                            onClick={this.rotarDerecha}
                            color="secondary"
                            variant="contained"
                            autoFocus
                            style={{ width: "calc(50% - 5px)", marginLeft: "5px" }}
                          >
                            Derecha
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  <img
                    id="edit_profile_photo"
                    src={`${process.env.REACT_APP_API_URL}/miniaturas/usuarios/${this.props.usuario.id}?${Date.now()}`} // El ? Date.now() fuerza que se cargue de nuevo la imagen al hcer click en Editar, sino se muestra imagen anterior https://stackoverflow.com/questions/47922687/force-react-to-reload-an-image-file/47923081
                    className="EditarFoto__account_image"
                    alt="foto"
                  />
                )}
              </Grid>

              <Grid item xs={12} className="EditarFoto__grid_item" style={{ marginTop: "10px" }}>
                <input id="foto_file" type="file" onChange={this.onChange} />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <CustomerButton
              text="Guardar"
              handle={this.submitPhoto}
              color={this.props.theme.guardar}
            />
            <Button
              onClick={this.handleClose}
              color="secondary"
              variant="contained"
              autoFocus
            >
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withSnackbar(EditarFoto)
);
