import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { getRequest } from "../../utils/axios";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  Grid,
  Divider,
  TextField,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { withStyles } from "@mui/styles";
import Pagination from '@mui/material/Pagination'; // Paginamiento
import Contenido from "../../components/Common/Contenido";
import { LinkButton } from "../../common/buttons";
import Capsula from "../../components/Capsulas/Capsula";
import CapsulaProcessing from "../../components/Capsulas/CapsulaProcessing";
import { DrawerContext } from "../../context/DrawerContext";
import "./Capsulas.css";
import SkeletonCapsula from "../../components/Capsulas/SkeletonCapsula";

const styles = () => ({});

class Capsulas extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buscador: {
        titulo: "",
        area: "",
        metodologia: "",
        nivel: "",
        pagina: 1,
        tamano_pagina: 10,
        num_pages: -1,
        num_results: -1,
        order: "fecha",
      },
      areas: [{ codigo: 0, nombre: "Seleccionar" }],
      metodologias: [{ nombre: "Seleccionar" }],
      niveles: [{ nombre: "Seleccionar", codigo: "-1" }],
      capsulas: [],
      capsulas_procesando: [],
      orders: [
        { nombre: "Fecha", codigo: "fecha" },
        { nombre: "Número de visitas", codigo: "n_visitas" },
      ],
      tamano_pagina_opciones: [
        { id: 0, valor: 5 },
        { id: 1, valor: 10 },
        { id: 2, valor: 20 },
        { id: 3, valor: 50 },
        { id: 4, valor: 100 },
      ],
      mostrar_capsulas_procesando: true,
      buscado: false,
      cargando: true,
    };
  }

  componentDidMount() {
    this.context.setCurrentDrawerItem("Cápsulas Docentes");
    let component = this;

    async function getCapsulas() {
      await getRequest(`${process.env.REACT_APP_API_URL}/api/capsulas/`, {
        pagina: 1,
        tamano_pagina: 10,
      })
        .then((response) => {
          component.setState({
            capsulas: response.data.capsula,
            buscador: {
              ...component.state.buscador,
              num_pages: response.data.num_pages,
              num_results: response.data.num_results,
            },
            cargando: false,
          });
        })
        .catch((e) => {
          console.log(e);
          component.setState({ cargando: false });
        });
    }
    async function getAreas() {
      getRequest(
        `${process.env.REACT_APP_API_URL}/api/curriculum_escolar/areas/`
      ).then((response) => {
        const res_areas = response.data;
        res_areas.unshift({ codigo: 0, nombre: "Seleccionar" });
        component.setState({ areas: res_areas });
      });
    }
    async function getMetodologias() {
      getRequest(`${process.env.REACT_APP_API_URL}/api/metodologias/`).then(
        (response) => {
          const res_metodologias = response.data;
          res_metodologias.unshift({ nombre: "Seleccionar" });
          component.setState({ metodologias: res_metodologias });
        }
      );
    }
    async function getNiveles() {
      getRequest(
        `${process.env.REACT_APP_API_URL}/api/curriculum_escolar/niveles/`
      ).then((response) => {
        const res_niveles = response.data;
        res_niveles.unshift({
          codigo: "-1",
          nombre: "Seleccionar",
          ciclo: 0,
        });
        component.setState({ niveles: res_niveles });
      });
    }

    async function getMyProcessingCapsulas() {
      await getRequest(
        `${process.env.REACT_APP_API_URL}/api/capsulas/procesando`,
        {
          usuario_id: component.props.usuario.id,
        }
      )
        .then((response) => {
          component.setState({
            capsulas_procesando: response.data.capsulas,
          });
        })
        .catch((e) => {
          console.log(e);
        });
    }

    getCapsulas();
    getMyProcessingCapsulas();
    getAreas();
    getMetodologias();
    getNiveles();
  }

  onChange = (e) => {
    this.setState(
      {
        buscador: {
          ...this.state.buscador,
          [e.target.name]: e.target.value,
        },
      },
      () => {
        if (e.target.name === "order") this.handleSearch();
      }
    );
  };

  handleSearch = (e) => {
    if (e != null) e.preventDefault();
    let query = this.state.buscador;

    if (query.area === "Seleccionar") {
      query.area = "";
    }
    if (query.metodologia === "Seleccionar") {
      query.metodologia = "";
    }
    if (query.nivel === "-1") {
      query.nivel = "";
    }
    getRequest(`${process.env.REACT_APP_API_URL}/api/capsulas/`, query).then(
      (response) => {
        this.setState({
          capsulas: response.data.capsula,
          buscador: {
            ...this.state.buscador,
            num_pages: response.data.num_pages,
            num_results: response.data.num_results,
          },
          buscado: true,
        });
      }
    );
  };

  handlePagination = (e, value) => {
    this.setState(
      { buscador: { ...this.state.buscador, pagina: parseInt(value) } },
      () => this.handleSearch()
    );
  };

  handleResultadosPorPagina = (e) => {
    this.setState(
      {
        buscador: {
          ...this.state.buscador,
          pagina: 1,
          tamano_pagina: parseInt(e.target.value),
        },
      }, // vuelta a pagina 1, pero cambiando tamano_pagina
      () => this.handleSearch()
    ); // Se realiza nueva bpusqueda
  };

  resetSearch = () => {
    this.setState(
      {
        buscador: {
          titulo: "",
          area: "",
          metodologia: "",
          nivel: "",
          pagina: 1,
          tamano_pagina: 10,
          num_pages: -1,
          num_results: -1,
          order: "fecha",
        },
      },
      () => this.handleSearch()
    );
  };

  handleDisplayCapsulasProcessing = () => {
    this.setState({
      mostrar_capsulas_procesando: !this.state.mostrar_capsulas_procesando,
    });
  };

  displayCapsulasProcessing = () => {
    return (
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Accordion
              expanded={this.state.mostrar_capsulas_procesando}
              onChange={this.handleDisplayCapsulasProcessing}
              className="Capsulas__procesando__acordeon"
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <h2 className="Buscador__titulo">Capsulas procesando</h2>
              </AccordionSummary>
              <AccordionDetails className="capsulas">
                {this.state.capsulas_procesando.map((capsula) => (
                  <CapsulaProcessing
                    key={capsula.id}
                    capsula={capsula}
                    colorCapsula={this.props.theme.capsula}
                  />
                ))}
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  displayCapsulas = (theme) => {
    if (this.state.cargando) {
      const array = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
      return (
        <div className="capsulas">
          {" "}
          {array.map((i) => (
            <SkeletonCapsula key={i} />
          ))}
        </div>
      );
    } else if (this.state.capsulas.length > 0)
      return (
        <div className="capsulas">
          {this.state.capsulas.map((capsula) => (
            <Capsula
              key={capsula.id}
              capsula={capsula}
              colorCapsula={theme.capsula}
            />
          ))}{" "}
        </div>
      );

    return (
      <div className="center">
        <p>No se encontraron cápsulas</p>
      </div>
    );
  };

  render() {
    const { theme } = this.props;
    const componente = (
      <div>
        {this.props.usuario && this.props.usuario.groups.includes("admin") ? (
          <div style={{ width: "100%" }}>
            <div className="boton__subirContainer">
              <LinkButton
                className="Boton__subirCapsula"
                text="Subir"
                to="/capsulas/subir"
                color={theme.capsula}
                child={
                  <CloudUploadIcon className="Boton__subirCapsulaIcon" />
                }
              />
            </div>
          </div>
        ) : null}
        {/* Buscador */}
        <div className="Capsulas__container__padding">
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <h2 className="Buscador__titulo">Buscador</h2>
            </Grid>
            <Grid item xs={12}>
              <form>
                <Grid container spacing={2}>
                  {/* <Grid item xs={12} md={3}>
                    <TextField
                      variant="outlined"
                      size="small"
                      label="Título de video"
                      name="titulo"
                      value={this.state.buscador.titulo}
                      onChange={this.onChange}
                      className="Buscador__input"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid> */}
                  <Grid item xs={6} md={3}>
                    <TextField
                      label="Area"
                      name="area"
                      onChange={this.onChange}
                      select
                      SelectProps={{ native: true }}
                      value={this.state.buscador.area}
                      variant="outlined"
                      size="small"
                      className="Buscador__input"
                      InputLabelProps={{ shrink: true }}
                    >
                      {this.state.areas.map((area) => (
                        <option key={area.codigo} value={area.id}>
                          {area.nombre}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <TextField
                      label="Metodologías activas"
                      name="metodologia"
                      onChange={this.onChange}
                      select
                      SelectProps={{ native: true }}
                      value={this.state.buscador.metodologia}
                      variant="outlined"
                      size="small"
                      className="Buscador__input"
                      InputLabelProps={{ shrink: true }}
                    >
                      {this.state.metodologias.map((metodologia) => (
                        <option
                          key={metodologia.nombre}
                          value={metodologia.id}
                        >
                          {metodologia.nombre}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      label="Nivel"
                      name="nivel"
                      onChange={this.onChange}
                      select
                      SelectProps={{ native: true }}
                      value={this.state.buscador.nivel}
                      variant="outlined"
                      size="small"
                      className="Buscador__input"
                      InputLabelProps={{ shrink: true }}
                    >
                      {this.state.niveles.map((nivel) => (
                        <option key={nivel.codigo} value={nivel.codigo}>
                          {nivel.nombre}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    md={2}
                    className="Buscador__botonBuscarContainer"
                  >
                    <Button
                      color="primary"
                      className="Buscador__botonBuscar"
                      fullWidth
                      variant="contained"
                      type="submit"
                      onClick={this.handleSearch}
                    >
                      Buscar
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    md={1}
                    className="Buscador__botonBuscarContainer"
                  >
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={this.resetSearch}
                      fullWidth
                    >
                      Limpiar
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
            <Grid item xs={12}>
              <div className="Buscador__divider">
                <Divider />
              </div>
            </Grid>
            {/* Order */}
            {this.state.capsulas_procesando.length > 0
              ? this.displayCapsulasProcessing()
              : null}

            <Grid item xs={12}>
              <Grid
                container
                style={{ justifyContent: "flex-end !important" }}
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                  md={8}
                  xl={8}
                  sx={{ alignSelf: "flex-start !important" }}
                >
                  <h2 className="Buscador__titulo">
                    {this.state.buscado ? "Resultados" : "Novedades"}
                  </h2>
                </Grid>
                <Grid item xs={12} md={2} xl={2}>
                  <TextField
                    label="Orden de resultados"
                    name="order"
                    onChange={this.onChange}
                    select
                    SelectProps={{ native: true }}
                    InputLabelProps={{ shrink: true }}
                    //value={this.state.nivel}
                    variant="filled"
                    size="small"
                    className="Buscador__input"
                  >
                    {this.state.orders.map((order) => (
                      <option key={order.codigo} value={order.codigo}>
                        {order.nombre}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} md={2} xl={2}>
                  <TextField
                    label="Resultados por página"
                    name="resultados_por_pagina"
                    onChange={this.handleResultadosPorPagina}
                    select
                    SelectProps={{ native: true }}
                    InputLabelProps={{ shrink: true }}
                    variant="filled"
                    size="small"
                    className="Buscador__input"
                    defaultValue={10}
                  >
                    {this.state.tamano_pagina_opciones.map((tamano) => (
                      <option key={tamano.id} value={tamano.valor}>
                        {tamano.valor}
                      </option>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            </Grid>

            {/* <Grid item xs={12} className="Buscador__divider">
              <Divider />
            </Grid>
             */}
          </Grid>
        </div>
        {/* Resultados */}
        <div className="Capsulas__Resultados__padding">
          {this.displayCapsulas(theme)}

          {/* Paginamiento */}
          <div className="center">
            <Pagination
              count={this.state.buscador.num_pages}
              page={this.state.buscador.pagina}
              onChange={this.handlePagination}
              className="paginamiento"
            />
          </div>
        </div>
      </div>
    );
    return (
      <Contenido
        componente={componente}
        seccion={"Cápsulas Docentes"}
        descripcion={"Material pedagógico audiovisual"}
        backgroundColor={theme.capsula}
      ></Contenido>
    );
  }
}
Capsulas.contextType = DrawerContext;

const mapStateToProps = (state) => ({
  usuario: state.auth.usuario,
});

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, {})
)(Capsulas);
