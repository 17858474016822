import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import { AccountCircle } from "@mui/icons-material";
import {
    Grid,
    TextField,
    Button,
} from "@mui/material";
import { deltaTime, thousandSeparatorShort } from "../../../utils/utils";
import { getRandomColor } from "../../../utils/styleUtils";
import { ForoIconPost } from "../../../components/Foro/ForoIcon";
import "./Conversacion.css";
import { getRequest, postRequest } from "../../../utils/axios";
import { createStyles } from "@mui/styles";
import CommentIcon from '@mui/icons-material/Comment';
import AddCommentIcon from '@mui/icons-material/AddComment';

import RespuestasConversacionPadre from "../../../components/Foro/RespuestasConversacionPadre"

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: 0,
        maxWidth: "100%",
    },
});

class Conversacion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nuevo_comentario: "",
            submited: false,
            loading: false,
            categoria: {
                id: -1,
                nombre: "",
                descripcion: "",
                url_imagen: "",
                n_me_gusta: 0,
                n_gran_idea: 0,
                n_me_interesa: 0,
                n_lo_utilizare: 0,
                n_me_asombra: 0,
                n_conversaciones: 0,
            },
            conversacion: {
                id: 0,
                titulo: "",
                contenido: "",
                n_visitas: 0,
                n_me_gusta: 0,
                n_gran_idea: 0,
                n_me_interesa: 0,
                n_lo_utilizare: 0,
                n_me_asombra: 0,
                fecha_creacion: "",
                n_comentarios: 0,
                n_vistas: 0,
                autor: { id: 0, foto_perfil: null, nombre: "" },
                n_total_reacciones: 0,
                foto_perfil: ""
            },

            comentarios: [],
            lista_reacciones: [], // likes del usuario a la conversacion
        };
    }


    componentDidMount() {
        let component = this;

        async function getCategoria() {
            await getRequest(
              `${process.env.REACT_APP_API_URL}/api/foro/categorias/${component.props.match.params.idCategoria}/`
            ).then((response) => {
                component.setState({
                    categoria: response.data,
                })
            })
        }

        async function getConversacion() {
            await getRequest(
              `${process.env.REACT_APP_API_URL}/api/foro/conversacion/${component.props.match.params.id}/`
            )
              .then((response) => {
                  component.setState({
                      conversacion: response.data,
                  });
              })
              .catch((e) => {
                  console.log(e);
              });
        }
        // TODO: ORDENAR POR NUMERO DE LIKES, O DE REACCIONES ANTES DE SETEARLOS EN EL STATE
        async function getComentarios() {
            await getRequest(
              `${process.env.REACT_APP_API_URL}/api/foro/conversacion/${component.props.match.params.id}/respuestas/`
            )
              .then((response) => {
                  component.setState({
                      comentarios: response.data,
                  });
              })
              .catch((e) => {
                  console.log(e);
              });
        }
        async function getListaReacciones() {
            await getRequest(
              `${process.env.REACT_APP_API_URL}/api/foro/conversacion/likes_usuario/`,
              {
                  id_conversacion: component.props.match.params.id
              }
            )
              .then((response) => {
                  component.setState({
                      lista_reacciones: response.data.lista_reacciones,
                  });
              })
              .catch((e) => {
                  console.log(e);
              });
        }

        getCategoria();
        getConversacion();
        getComentarios();
        getListaReacciones();
    }

    handleLikeConversacion = async (id_like) => {
        if (this.state.lista_reacciones.includes(id_like)) { // Handle dislike
            await postRequest(
              `${process.env.REACT_APP_API_URL}/api/foro/conversacion/like/`,
              {
                  id_conversacion: parseInt(this.props.match.params.id),
                  id_like: id_like,
                  action: "remove_like"
              }
            )
              .then(async (response) => {
                  if (response.status === 200) {
                      let lista_actual_reacciones = this.state.lista_reacciones
                      lista_actual_reacciones.splice(lista_actual_reacciones.indexOf(id_like), 1)
                      switch (response.data.id_like) {
                          case 1:
                              this.setState({ lista_reacciones: [...lista_actual_reacciones], conversacion: { ...this.state.conversacion, n_me_gusta: response.data.contador } })
                              break
                          case 2:
                              this.setState({ lista_reacciones: [...lista_actual_reacciones], conversacion: { ...this.state.conversacion, n_gran_idea: response.data.contador } })
                              break
                          case 3:
                              this.setState({ lista_reacciones: [...lista_actual_reacciones], conversacion: { ...this.state.conversacion, n_me_interesa: response.data.contador } })
                              break
                          case 4:
                              this.setState({ lista_reacciones: [...lista_actual_reacciones], conversacion: { ...this.state.conversacion, n_lo_utilizare: response.data.contador } })
                              break
                          case 5:
                              this.setState({ lista_reacciones: [...lista_actual_reacciones], conversacion: { ...this.state.conversacion, n_me_asombra: response.data.contador } })
                              break
                          default:
                              break
                      }
                  }
              })
              .catch((e) => {
                  console.log(e);
              });
        } else { // Handle like
            await postRequest(
              `${process.env.REACT_APP_API_URL}/api/foro/conversacion/like/`,
              {
                  id_conversacion: parseInt(this.props.match.params.id),
                  id_like: id_like,
                  action: "like"
              }
            )
              .then(async (response) => {
                  if (response.status === 200) {
                      let lista_actual_reacciones = this.state.lista_reacciones
                      lista_actual_reacciones.push(id_like)
                      switch (response.data.id_like) {
                          case 1:
                              this.setState({ lista_reacciones: [...lista_actual_reacciones], conversacion: { ...this.state.conversacion, n_me_gusta: response.data.contador } })
                              break
                          case 2:
                              this.setState({ lista_reacciones: [...lista_actual_reacciones], conversacion: { ...this.state.conversacion, n_gran_idea: response.data.contador } })
                              break
                          case 3:
                              this.setState({ lista_reacciones: [...lista_actual_reacciones], conversacion: { ...this.state.conversacion, n_me_interesa: response.data.contador } })
                              break
                          case 4:
                              this.setState({ lista_reacciones: [...lista_actual_reacciones], conversacion: { ...this.state.conversacion, n_lo_utilizare: response.data.contador } })
                              break
                          case 5:
                              this.setState({ lista_reacciones: [...lista_actual_reacciones], conversacion: { ...this.state.conversacion, n_me_asombra: response.data.contador } })
                              break
                          default:
                              break
                      }
                  }
              })
              .catch((e) => {
                  console.log(e);
              });
        }
    }

    getConversacion = async () => {
        await getRequest(
          `${process.env.REACT_APP_API_URL}/api/foro/conversacion/${this.props.match.params.id}/`
        )
          .then((response) => {
              this.setState({
                  conversacion: response.data,
              });
          })
          .catch((e) => {
              console.log(e);
          });
    }

    getListaReacciones = async () => {
        await getRequest(
          `${process.env.REACT_APP_API_URL}/api/foro/conversacion/likes_usuario/`,
          {
              id_conversacion: this.props.match.params.id
          }
        )
          .then((response) => {
              this.state.setState({
                  lista_reacciones: response.data.lista_reacciones,
              });
          })
          .catch((e) => {
              console.log(e);
          });
    }

    getComentarios = async () => {
        await getRequest(
          `${process.env.REACT_APP_API_URL}/api/foro/conversacion/${this.props.match.params.id}/respuestas/`
        )
          .then((response) => {
              this.setState({
                  comentarios: response.data,
              })
          })
    }

    handleEscribirNuevoComentario = (e) => {
        this.setState({ nuevo_comentario: e.target.value })
    }

    handlePostNuevoComentario = async (e) => {
        e.preventDefault();
        this.setState({ loading: true });
        let response;
        try {

            response = await postRequest(
              `${process.env.REACT_APP_API_URL}/api/foro/conversacion/comentar/`,
              {
                  id_conversacion: this.state.conversacion.id,
                  id_categoria: this.state.categoria.id,
                  contenido: this.state.nuevo_comentario,
                  comentario_padre: null
              }
            );

            if (response.status === 201) {
                this.setState({
                    submited: true,
                });
                // this.props.enqueueSnackbar(
                //     `Comentario agregado exitosamente!`
                // );
            }
        } catch (e) {
            console.log(e);
            // this.props.enqueueSnackbar("Hubo un error al subir el comentario");
        } finally {
            this.setState({ loading: false, nuevo_comentario: "" }, () => {
                this.getComentarios()
            });
        }
    }

    render() {
        const { theme, usuario } = this.props;
        const styles = createStyles({
            ContainerPost_2: {
                width: "100%",
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                alignItems: "center",
                justifyContent: "space-between",
                borderRadius: "10px",
                boxShadow:
                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important",
                fontSize: 16,
                fontWeight: 600,
                backgroundColor: "transparent",
                padding: "10px 20px 10px 20px",
                margin: "20px 0px 10px 0px",
                textAlign: "left",
                border: "2px solid " + getRandomColor(this.state.conversacion.autor.id),
            },
            ContainerNewPost: {
                width: "100%",
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                alignItems: "center",
                justifyContent: "space-between",
                borderRadius: "10px",
                boxShadow:
                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important",
                fontSize: 16,
                fontWeight: 600,
                backgroundColor: "transparent",
                padding: "10px 20px 10px 20px",
                margin: "10px 0px 10px 0px",
                textAlign: "left",
                border: "2px solid black",
            }
        });

        return (
          <Grid container spacing={0} style={{ marginTop: "20px" }}>
              {/*FIRST ROW: BUTTON + TITLE*/}
              <Grid item xs={1} sm={1} md={1} lg={1} xl={1} />

              <Grid item xs={10} sm={10} md={1} lg={1} xl={1} style={{ display: "flex", alignItems: "center" }}>
                  <Button
                    style={{ backgroundColor: theme.gris, color: "black", fontSize: "16px" }}
                    variant="contained"
                    component={Link}
                    to={`/foro/categorias/${this.state.categoria.id}`}
                  >
                      Volver
                  </Button>
              </Grid>
              <Grid item xs={1} sm={1} className="hide_above_sm" />

              <Grid item xs={1} sm={1} className="hide_above_sm" />
              <Grid item xs={10} sm={10} md={9} lg={8} xl={7} className="Head__titulo" style={{ textAlign: "initial", color: theme.foro }}>
                  {this.state.categoria.nombre}
              </Grid>
              <Grid item xs={1} sm={1} md={1} lg={2} xl={3} />

              <Grid item xs={1} sm={1} md={1} lg={2} xl={3} />
              <Grid container item xs={10} sm={10} md={10} lg={8} xl={6} style={styles.ContainerPost_2}>
                  <Grid item xs={12} >
                      {/* Perfil de quien postea */}
                      <div className="head_post_container">
                          {/* Autor y fecha */}
                          <div className="autor_fecha_container_post">
                              <div className="autor_fecha_subcontainer_post">
                                  <div className={"post__IconContainer"}>
                                      {!["", null, undefined].includes(this.state.conversacion.autor.foto_perfil) ? (
                                        <img
                                          src={`${process.env.REACT_APP_API_URL}/miniaturas/usuarios_min/${this.state.conversacion.autor.id}`}
                                          className="usuario_icon foro_profile_comment"
                                          alt="foto"
                                        />
                                      ) : (
                                        <AccountCircle style={{ height: "100%", fontSize: "60px" }} />
                                      )}
                                  </div>
                                  <div >
                                      <div className={"post__NombreAutor"}>
                                          {this.state.conversacion.autor.nombre}
                                          <Button
                                            color="primary"
                                            variant="contained"
                                            component={Link}
                                            className="ver_perfil_button_post"
                                            style={{ marginLeft: "5px !important" }}
                                            to={`/perfil/${this.state.conversacion.autor.id}`}
                                          >
                                              {/* {" "} */}
                                              Ver perfil
                                              {/* <PersonIcon/> */}
                                          </Button>
                                      </div>
                                      {/* <br /> */}
                                      <div className={"post__VisitasFecha"}>
                                          {`${thousandSeparatorShort(
                                            this.state.conversacion.n_visitas
                                          )} visitas • ${deltaTime(this.state.conversacion.fecha_creacion)}`}

                                      </div>
                                  </div>
                              </div>
                          </div>
                          {/* Likes y comentarios */}
                          <div className="post__icons_container">
                              <ForoIconPost
                                type="Me gusta"
                                colored={this.state.lista_reacciones.includes(1)}
                                cantidad={this.state.conversacion.n_me_gusta !== undefined ? this.state.conversacion.n_me_gusta : 0}
                                onClick={() => this.handleLikeConversacion(1)}
                                style={{ cursor: "pointer" }}
                              />
                              <ForoIconPost
                                type="Gran idea"
                                colored={this.state.lista_reacciones.includes(2)}
                                cantidad={this.state.conversacion.n_gran_idea !== undefined ? this.state.conversacion.n_gran_idea : 0}
                                onClick={() => this.handleLikeConversacion(2)}
                                style={{ cursor: "pointer" }}
                              />
                              <ForoIconPost
                                type="Me interesa"
                                colored={this.state.lista_reacciones.includes(3)}
                                cantidad={this.state.conversacion.n_me_interesa !== undefined ? this.state.conversacion.n_me_interesa : 0}
                                onClick={() => this.handleLikeConversacion(3)}
                                style={{ cursor: "pointer" }}
                              />
                              <ForoIconPost
                                type="Lo utilizaré"
                                colored={this.state.lista_reacciones.includes(4)}
                                cantidad={this.state.conversacion.n_lo_utilizare !== undefined ? this.state.conversacion.n_lo_utilizare : 0}
                                onClick={() => this.handleLikeConversacion(4)}
                                style={{ cursor: "pointer" }}
                              />
                              <ForoIconPost
                                type="Me asombra"
                                colored={this.state.lista_reacciones.includes(5)}
                                cantidad={this.state.conversacion.n_me_asombra !== undefined ? this.state.conversacion.n_me_asombra : 0}
                                onClick={() => this.handleLikeConversacion(5)}
                                style={{ cursor: "pointer" }}
                              />
                          </div>
                      </div>

                  </Grid>


                  {/* THIRD ROW: TITLE AND CONTENT*/}
                  <Grid item xs={12} style={{ paddingTop: "20px" }}>
                      <hr style={{ margin: "0px 0px 10px 0px" }} />
                      <div className={"post__title"}>
                          {this.state.conversacion.titulo}
                      </div>

                      <div className={"post__content"} dangerouslySetInnerHTML={{ __html: this.state.conversacion.contenido }}>
                      </div>
                  </Grid>
              </Grid>
              <Grid item xs={1} sm={1} md={1} lg={2} xl={3} />



              <Grid item xs={1} sm={1} md={1} lg={2} xl={3} />
              <Grid container item xs={10} sm={10} md={10} lg={8} xl={6}>
                  <AddCommentIcon style={{ height: "100%", fontSize: "20px", marginRight: "10px", color: theme.guardar }} />
                  {
                      <h2 className="resultados__titulo">{`Añadir comentario`}</h2>
                  }
              </Grid>
              <Grid item xs={1} sm={1} md={1} lg={2} xl={3} />



              <Grid item xs={1} sm={1} md={1} lg={2} xl={3} />
              <Grid container item xs={10} sm={10} md={10} lg={8} xl={6} style={styles.ContainerNewPost}>
                  <div className="autor_fecha_container_nuevocomentario">
                      <div className="autor_fecha_subcontainer_nuevocomentario">
                          <div className={"post__IconContainer"}>
                              {!["", null, undefined].includes(this.props.usuario.foto_perfil) ? (
                                <img
                                  src={`${process.env.REACT_APP_API_URL}/miniaturas/usuarios_min/${this.props.usuario.id}`}
                                  className="usuario_icon foro_profile_comment"
                                  alt="foto"
                                />
                              ) : (
                                <AccountCircle style={{ height: "100%", fontSize: "60px" }} />
                              )}
                          </div>
                          <div className={"post__nuevoComentarioAutorComentario__Container"}>
                              <div className={"post__nuevoComentarioAutor"}>
                                  {this.props.usuario.primer_nombre + " " + this.props.usuario.apellido_paterno}
                              </div>
                              {/* <br /> */}
                              <div className={"post__nuevoComentario"}>
                                  <TextField
                                    // label="Agregar comentario"
                                    style={{ margin: "0px 10px 0px 0px" }}
                                    placeholder="Escribe algo ..."
                                    fullWidth
                                    // margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="standard"
                                    multiline
                                    value={this.state.nuevo_comentario}
                                    onChange={this.handleEscribirNuevoComentario}
                                  />
                              </div>
                          </div>
                      </div>
                  </div>
                  {this.state.nuevo_comentario !== "" ?
                    <div className={"post__nuevoComentarioButton__Container"}>
                        <Button
                          color="secondary"
                          variant="contained"
                          style={{ maxWidth: "fit-content", margin: "10px 10px 0px 0px" }}
                          onClick={() => this.setState({ nuevo_comentario: "" })}
                        >
                            Borrar
                        </Button>
                        <Button
                          color="primary"
                          variant="contained"
                          style={{ maxWidth: "fit-content", marginTop: "10px" }}
                          onClick={this.handlePostNuevoComentario}
                        >
                            Comentar
                        </Button>
                    </div>
                    : null
                  }
              </Grid>
              <Grid item xs={1} sm={1} md={1} lg={2} xl={3} />

              <Grid item xs={1} sm={1} md={1} lg={2} xl={3} />
              <Grid container item xs={10} sm={10} md={10} lg={8} xl={6}>
                  <CommentIcon style={{ height: "100%", fontSize: "20px", marginRight: "10px", color: theme.primary }} />
                  {
                      this.state.comentarios.length === 0 ?
                        <h2 className="resultados__titulo">{`No hay comentarios`}</h2>
                        :
                        <h2 className="resultados__titulo">{`Comentarios (${this.state.comentarios.length})`}</h2>
                  }
              </Grid>
              <Grid item xs={1} sm={1} md={1} lg={2} xl={3} />


              {this.state.comentarios.map(comentario => {
                  return (
                    <Grid container spacing={0} key={comentario.id} style={{ width: "100%" }}>
                        <Grid item xs={1} sm={1} md={1} lg={2} xl={3} />
                        <RespuestasConversacionPadre comentario={comentario} categoria={this.state.categoria} conversacion={this.state.conversacion} getComentarios={this.getComentarios} />
                        <Grid item xs={1} sm={1} md={1} lg={2} xl={3} />
                    </Grid>)
              })}

          </Grid>
        );
    }
}

const mapStateToProps = (state) => ({
    usuario: state.auth.usuario,
});

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, {})
)(Conversacion);
