import React, { useContext, useEffect } from "react";
import { DrawerContext } from "../../context/DrawerContext";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import {
  QuestionAnswer as QuestionAnswerIcon,
  FileCopy as FileCopyIcon,
  //Spellcheck as SpellcheckIcon,
  Help as HelpIcon,
} from "@mui/icons-material";

import "./LandingPage.css";
import Contenido from "../Common/Contenido";
import { cssVariables } from "./../../theme";
import {
  ManualesSVG,
  CapsulasSVG,
  BibliotecaDocenteSVG,
  ComunidadDocenteSVG,
} from "../../common/svgIcon";

export default function LandingPage() {
  const DrawerCxt = useContext(DrawerContext);
  useEffect(() => {
    DrawerCxt.setCurrentDrawerItem("Inicio");
  }, [DrawerCxt]);
  const TextoSeccion = "Bienvenido a Linkki";
  const TextoDescripcion = "Selecciona una sección para comenzar";

  const TextoCapsulas =
    "Aquí podrás encontrar cápsulas pedagógicas con entretenidas metodologías de enseñanza";
  const TextoManuales =
    "Esta sección contiene manuales con estrategias metodológicas de enseñanza que te apoyarán en la mejora de tus clases";
  const TextoBibliotecaDocente =
    "Aquí hallarás planificaciones, guías didácticas y otros materiales que pueden ser útiles para la preparación de tus clases";
  const TextoDocentes =
    "En esta sección podrás ubicar y contactar a otros colegas de la Red Linkki Aulainnova Tarapacá";
  const TextoForo =
    "¿Tienes alguna idea o consulta sobre docencia? En este foro podrás conversarlo con la comunidad docente de la Red Linkki Aulainnova Tarapacá";
  const TextoBibliotecaAcademica =
    "Aquí ponemos a disposición material académico relacionado con la educación y la enseñanza";
  //const TextoVocabularioCientifico ="Aquí se encuentran definiciones, casos de uso y recursos audiovisuales para usar en tus clases";
  const TextoAyuda = "Contáctanos para resolver tus dudas o algun problema con la plataforma";

  const componente = (
    <div className={"Landing__content"}>
      <Item
        titulo={"Cápsulas Docentes"}
        texto={TextoCapsulas}
        backgroundColor={cssVariables.capsula}
        Icon={CapsulasSVG}
        path={"/capsulas/"}
      />
      <Item
        titulo={"Manuales Pedagógicos"}
        texto={TextoManuales}
        backgroundColor={cssVariables.manual}
        Icon={ManualesSVG}
        path={"/manuales/"}
      />
      <Item
        titulo={"Biblioteca Docente"}
        texto={TextoBibliotecaDocente}
        backgroundColor={cssVariables.bdocente}
        Icon={BibliotecaDocenteSVG}
        path={"/biblioteca-docente/"}
      />
      <Item
        titulo={"Comunidad Docente"}
        texto={TextoDocentes}
        backgroundColor={cssVariables.comunidad}
        Icon={ComunidadDocenteSVG}
        path={"/comunidad/"}
      />

      <Item
        titulo={"Foro"}
        texto={TextoForo}
        backgroundColor={cssVariables.foro}
        Icon={QuestionAnswerIcon}
        path={"/foro/"}
      />
      {/* <Item
        titulo={"Biblioteca Académica"}
        texto={TextoBibliotecaAcademica}
        backgroundColor={cssVariables.bacademica}
        Icon={FileCopyIcon}
        path={"/biblioteca-academica/"}
      /> */}
      {/*
      <Item
        titulo={"Vocabulario Científico"}
        texto={TextoVocabularioCientifico}
        backgroundColor={cssVariables.vocabulario}
        Icon={SpellcheckIcon}
        path={"/vocabulario-cientifico/"}
      />
*/}
      <Item
        titulo={"Ayuda"}
        texto={TextoAyuda}
        backgroundColor={"#b1b1b1"}
        Icon={HelpIcon}
        path={"/ayuda"}
      />
    </div>
  );

  return (
    <Contenido
      componente={componente}
      seccion={TextoSeccion}
      descripcion={TextoDescripcion}
      backgroundColor={cssVariables.primary}
    />
  );
}

function Item(props) {
  const { titulo, texto, backgroundColor, Icon, path } = props;
  const history = useHistory();
  const handleClick = (path) => {
    history.push(path);
  };

  const estilos = makeStyles((theme) => ({
    Item__overContainer: {
      backgroundColor: "#FFFFFF",
      display: "flex",
      justifyContent: "center",
      "&:hover": {
        cursor: "pointer",
        background: backgroundColor,
        opacity: "1",
      },
    },
    Item__head: {
      overflow: "visible",
      width: "100%",
      height: "40%",
      backgroundColor: backgroundColor,
      opacity: "0.8",
    },
    Item__icon: {
      width: "100% !important",
      height: "40px !important",
      color: backgroundColor,
      stroke: backgroundColor,
      fill: backgroundColor,
      opacity: "1",
    },
    "@media (max-width:480px)": {
      // eslint-disable-line no-useless-computed-key
      Item__overContainer: {
        width: "100%",
      },
    },
    "@media (max-width:1000px) and (min-width:480px)": {
      // eslint-disable-line no-useless-computed-key
    },
  }));

  const classes = estilos();

  return (
    <div className={classes.Item__overContainer}>
      <div
        name={titulo}
        className={"Item__container"}
        onClick={() => handleClick(path)}
      >
        {/* Barra de color */}
        <div className={classes.Item__head}></div>
        {/* Caja de ícono */}
        <div className={"Item__iconBoxContainer"}>
          <div className={"Item__iconBox"}>
            <Icon className={classes.Item__icon} />
          </div>
        </div>
        {/* Título */}
        <div className={"Item__titulo"}>{titulo}</div>
        {/* Descripción */}
        <div className={"Item__texto"}>
          <p className={"Item__textPadding"}>{texto}</p>
        </div>
      </div>
    </div>
  );
}
