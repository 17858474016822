import React from "react";
import { Switch } from "react-router-dom";
import PrivateRoute from "../../common/PrivateRoute";
import LandingPage from "../LandingPage/LandingPage";
import {
  Capsulas,
  SubirCapsula,
  DetalleCapsula,
  EditarCapsula,
} from "../../views/Capsulas";
import {
  BibliotecaDocente,
  SubirMaterialDocente,
  DetalleMaterialDocente,
  EditarMaterialDocente,
} from "../../views/BibliotecaDocente";
import { ComunidadDocente, Contactar } from "../../views/ComunidadDocente";
import { Perfil, EditarPerfil } from "../../views/Perfil";
import {
  Manuales,
  SubirManual,
  DetalleManual,
  EditarManual,
} from "../../views/Manuales";
/*
import {
  BibliotecaAcademica,
  DetalleMaterialAcademico,
  SubirMaterialAcademico,
  EditarMaterialAcademico
} from "../../views/BibliotecaAcademica";*/
import {
  Foro,
  Categoria_Conversaciones,
  Nueva_Conversacion,
  Conversacion,
  EditarCategoria,
  CrearCategoria
} from "../../views/Foro";
import "./Page.css";
import Ayuda from "../../views/Ayuda";
// import FooterPage from "./Footer";

export default class Page extends React.Component {
  render() {
    return (
      <div className="Page_Container">
        <Switch>
          <PrivateRoute exact path="/" component={LandingPage} />
          <PrivateRoute exact path="/ayuda" component={Ayuda} />
          {/* Manuales */}
          <PrivateRoute exact path="/manuales/" component={Manuales} />
          <PrivateRoute
            exact
            path="/manuales/subir"
            component={SubirManual}
          />
          <PrivateRoute
            exact
            path="/manuales/:id"
            component={DetalleManual}
          />
          <PrivateRoute
            exact
            path="/manuales/editar/:id"
            component={EditarManual}
          />
          {/* Capsulas */}
          <PrivateRoute exact path="/capsulas/" component={Capsulas} />
          <PrivateRoute
            exact
            path="/capsulas/subir"
            component={SubirCapsula}
          />
          <PrivateRoute
            exact
            path="/capsulas/:id"
            component={DetalleCapsula}
          />
          <PrivateRoute
            exact
            path="/capsulas/editar/:id"
            component={EditarCapsula}
          />
          {/* Biblioteca */}
          <PrivateRoute
            exact
            path="/biblioteca-docente/subir"
            component={SubirMaterialDocente}
          />
          <PrivateRoute
            exact
            path="/biblioteca-docente/"
            component={BibliotecaDocente}
          />
          <PrivateRoute
            exact
            path="/biblioteca-docente/:id"
            component={DetalleMaterialDocente}
          />

          <PrivateRoute
            exact
            path="/biblioteca-docente/editar/:id"
            component={EditarMaterialDocente}
          />
          {/* Foro */}
          <PrivateRoute exact path="/foro/" component={Foro} />
          <PrivateRoute
            exact
            path="/foro/categorias/editar/:id"
            component={EditarCategoria}
          />
          <PrivateRoute
            exact
            path="/foro/categorias/crear"
            component={CrearCategoria}
          />
          <PrivateRoute
            exact
            path="/foro/categorias/:id"
            component={Categoria_Conversaciones}
          />
          <PrivateRoute
            exact
            path="/foro/categorias/:idCategoria/conversaciones/nueva"
            component={Nueva_Conversacion}
          />
          <PrivateRoute
            exact
            path="/foro/categorias/:idCategoria/conversacion/:id"
            component={Conversacion}
          />
          {/* Comunidad */}
          <PrivateRoute
            exact
            path="/comunidad/"
            component={ComunidadDocente}
          />
          <PrivateRoute
            exact
            path="/comunidad/:id/contactar"
            component={Contactar}
          />
          {/* Biblioteca Academica */}
          {/*
          <PrivateRoute
            exact
            path="/biblioteca-academica/"
            component={BibliotecaAcademica}
          />
          <PrivateRoute
            exact
            path="/biblioteca-academica/subir"
            component={SubirMaterialAcademico}
          />
          <PrivateRoute
            exact
            path="/biblioteca-academica/:id"
            component={DetalleMaterialAcademico}
          />

          <PrivateRoute exact path="/foro/" component={Foro} />
          <PrivateRoute
            exact
            path="/biblioteca-academica/editar/:id"
            component={EditarMaterialAcademico}
          />
           */}
          {/* Perfil */}
          <PrivateRoute exact path="/perfil/" component={Perfil} />
          <PrivateRoute
            exact
            path="/perfil/editar"
            component={EditarPerfil}
          />
          <PrivateRoute exact path="/perfil/:id" component={Perfil} />
          <PrivateRoute
            exact
            path="/perfil/:id/contactar"
            component={Contactar}
          />
        </Switch>
        {/* <FooterPage ></FooterPage> */}
      </div>
    );
  }
}
