import React from "react";
import Skeleton from "react-loading-skeleton";

class SkeletonDocente extends React.Component {
  render() {
    return <Skeleton className="skeleton__docente" />;
  }
}

export default SkeletonDocente;
