import React from "react";
import Skeleton from "react-loading-skeleton";

class SkeletonProfesor extends React.Component {
  render() {
    return <Skeleton className="skeleton__profesor" />;
  }
}

export default SkeletonProfesor;
