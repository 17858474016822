import React from "react";
import moment from "moment";
import { Grid, Button, Divider, Typography } from "@mui/material";
import {
  AccountCircle,
  ThumbUpAlt as LikeIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { withStyles } from "@mui/styles";
import { connect } from "react-redux";
import { compose } from "redux";
import { withSnackbar } from "notistack";
import {
  getRequest,
  getFileRequest,
  postRequest,
  deleteRequest,
} from "../../../utils/axios";
import Contenido from "../../../components/Common/Contenido";
import Comentarios from "../../../components/Comentarios/Comentarios";
import { thousandSeparator } from "../../../utils/utils";
import CustomerLinearProgress from "../../../common/linearProgress";
import ReactQuillCustom from "../../../common/ReactQuill";
import "./DetalleManual.css";
import { LinkButton, CustomerButton } from "../../../common/buttons";

const styles = (theme) => ({});

class DetalleManual extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      usuario: props.usuario,
      id_manual: this.props.match.params.id,
      manual: null,
      n_likes: 0,
      comentarios_base: [],
      comentarios_hijos: [],
      like: false,
      loading: true,
    };
  }

  async componentDidMount() {
    let component = this;
    let manual;
    let user_en_lista = false;
    async function getManual() {
      await getRequest(
        `${process.env.REACT_APP_API_URL}/api/manuales/${component.state.id_manual}/`
      )
        .then((response) => {
          manual = response.data;
          for (let index = 0; index < manual.likes.length; index++) {
            const element = manual.likes[index];
            if (element.id === component.state.usuario.id) {
              user_en_lista = true;
              break;
            }
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }

    async function getComentarios() {
      await getRequest(
        `${process.env.REACT_APP_API_URL}/api/manuales/comentarios/${component.state.id_manual}/`
      )
        .then((response) => {
          component.setState({
            manual,
            comentarios_base: response.data.comentarios_base,
            comentarios_hijos: response.data.comentarios_hijos,
            n_likes: manual.likes.length,
            like: user_en_lista,
          });
        })
        .catch((e) => {
          console.log(e);
        });
    }
    await getManual();
    await getComentarios();
    this.setState({ loading: false });
  }

  handleLike = async (e) => {
    if (e != null) e.preventDefault();
    var data = {
      id_usuario: this.state.usuario.id,
      id_manual: this.state.manual.id,
      add: !this.state.like,
    };

    const response = await postRequest(
      `${process.env.REACT_APP_API_URL}/api/manuales/like/`,
      data
    );
    if (response.status === 200) {
      this.setState({
        like: !this.state.like,
        n_likes:
          !this.state.like === true
            ? this.state.n_likes + 1
            : this.state.n_likes - 1,
      });
    }
  };

  handleDownload = async () => {
    try {
      await getFileRequest(
        `${process.env.REACT_APP_API_URL}/api/manuales/${this.state.id_manual}/descargar/`,
        {},
        this.state.manual.titulo,
        "pdf"
      );
    } catch (e) {
      //SHOW SNACK ERROR
      this.props.enqueueSnackbar(
        `No se pudo descargar el manual ${this.state.manual.titulo}`
      );
    }
  };

  handleDelete = async () => {
    try {
      await deleteRequest(
        `${process.env.REACT_APP_API_URL}/api/manuales/${this.state.manual.id}/`
      );

      this.props.enqueueSnackbar(
        `Manual ${this.state.manual.titulo} eliminado con exito`
      );
      this.props.history.push("/manuales");
    } catch (e) {
      console.log(e);
      //SHOW SNACK ERROR
      this.props.enqueueSnackbar(
        `No se pudo eliminar la manual ${this.state.manual.titulo}`
      );
    }
  };

  render() {
    let componente;
    const { theme } = this.props;
    if (this.state.loading) {
      return <CustomerLinearProgress />;
    } else if (!this.state.manual) {
      componente = null;
    } else {
      componente = (
        <div className="root">
          <Grid container spacing={2}>
            {this.state.usuario.id === this.state.manual.id_usuario.id ? (
              <div style={{ width: "100%" }}>
                <div className="boton__subirContainerD">
                  <LinkButton
                    className="boton__subirMaterialD"
                    text="Editar"
                    to={`/manuales/editar/${this.state.manual.id}`}
                    color={theme.manual}
                    child={<EditIcon className="boton__subirMaterialIconD" />}
                  />
                  <CustomerButton
                    className="boton__subirMaterialC"
                    text="Eliminar"
                    handle={this.handleDelete}
                    color={theme.eliminar}
                    child={
                      <DeleteIcon className="boton__subirMaterialIconC" />
                    }
                  />
                </div>
              </div>
            ) : null}
            <Grid
              item
              xs={12}
              md={4}
              className="Manual__detalle_img_container"
            >
              <img
                src={`${process.env.REACT_APP_API_URL}/miniaturas/manuales/${this.state.manual.id}/`}
                alt="manual"
                className="Manual__detalle_img"
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <Grid item>
                <h2 className="Manual__detalle_titulo">
                  {this.state.manual.titulo}
                </h2>
              </Grid>
              {/* Fecha, visitas, likes */}
              <Grid item xs={12}>
                <Grid container spacing={2} justifyContent="space-between">
                  <Grid item>
                    <p>
                      {" "}
                      {thousandSeparator(this.state.manual.n_visitas)} vistas
                      • Se publicó el{" "}
                      {moment(this.state.manual.fecha_creacion).format("ll")}
                    </p>
                  </Grid>
                  <Grid item style={{ justifyContent: "flex-end" }}>
                    <Button
                      className="Capsula__detalle_likeButton"
                      onClick={this.handleLike}
                    >
                      <LikeIcon
                        className="Capsula__detalle_likeIcon"
                        style={{
                          color: this.state.like
                            ? this.props.theme.likeBlue
                            : "grey",
                        }}
                      />
                      {thousandSeparator(this.state.n_likes)}
                    </Button>{" "}
                  </Grid>
                </Grid>
              </Grid>
              {/* Divider */}
              <Grid
                item
                xs={12}
                style={{ marginTop: "10px", paddingBottom: "10px" }}
              >
                <Divider />
              </Grid>
              <Grid container spacing={2}>
                {/* Autor */}
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item style={{ width: "70px", margin: 0 }}>
                      {this.state.manual.id_usuario.foto_perfil &&
                        this.state.manual.id_usuario.foto_perfil !== "" ? (
                        <img
                          src={`${process.env.REACT_APP_API_URL}/miniaturas/usuarios_min/${this.state.manual.id_usuario.id}`}
                          // className="usuario_icon"
                          style={{
                            height: "50px !important",
                            borderRadius: "50%",
                          }}
                          alt="foto"
                        />
                      ) : (
                        <AccountCircle style={{ fontSize: "50px" }} />
                      )}
                    </Grid>
                    <Grid
                      item
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Typography
                        variant="body2"
                        component="p"
                        className="Capsula__detalle__texto_secundario autor"
                      >
                        <span>
                          {this.state.manual.id_usuario.primer_nombre +
                            " " +
                            this.state.manual.id_usuario.apellido_paterno}
                        </span>
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "10px",
                      }}
                    >
                      {/* <Button
                        color="primary"
                        variant="contained"
                        component={Link}
                        to={`/perfil/${this.state.manual.id_usuario.id}`}
                      >
                        {" "}
                        Ver perfil
                      </Button> */}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  <h3 className="Manual__detalle_titulo">Resumen</h3>
                  <div className="Manual__detalle_descripcion">
                    <ReactQuillCustom
                      value={this.state.manual.descripcion}
                      readOnly={true}
                      theme={"bubble"}
                      style={{ height: "100% important!" }}
                    />
                  </div>
                </Grid>

                {/* Divider */}
                <Grid
                  item
                  xs={12}
                  style={{ marginTop: "10px", paddingBottom: "10px" }}
                >
                  <Divider />
                </Grid>
                {/* Propiedades */}
                <Grid item xs={12}>
                  <Grid container justifyContent="center">
                    <Grid
                      item
                      style={{
                        display: "flex",
                        alignItems: "center",
                        borderRadius: "25px",
                        backgroundColor: "#9510ac",
                        padding: "10px",
                        margin: "10px",
                        color: "white",
                      }}
                    >
                      {this.state.manual.id_area.nombre}
                    </Grid>
                  </Grid>
                </Grid>
                {/* Divider */}
                <Grid
                  item
                  xs={12}
                  style={{ marginTop: "10px", paddingBottom: "10px" }}
                >
                  <Divider />
                </Grid>
              </Grid>{" "}
              {/* Descargar */}
              <Grid
                item
                xs={12}
                className="Manual__detalle_descargarContainer"
              >
                <Button
                  color="primary"
                  variant="contained"
                  style={{
                    width: "100%",
                    margin: "0px",
                    alignSelf: "flex-end",
                    display: "flex",
                  }}
                  onClick={this.handleDownload}
                >
                  {" "}
                  Descargar
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Comentarios
            urlPost={`${process.env.REACT_APP_API_URL}/api/manuales/comentarios/`}
            comentarios_base={this.state.comentarios_base}
            comentarios_hijos={this.state.comentarios_hijos}
            id_recurso={this.state.id_manual}
            tipo_recurso="id_manual"
          />
        </div>
      );
    }

    return (
      <Contenido
        componente={componente}
        seccion={"Detalle Manual Pedagógico"}
        descripcion={"Manual pedagógico para docentes"}
        backgroundColor={theme.manual}
      ></Contenido>
    );
  }
}

const mapStateToProps = (state) => ({
  usuario: state.auth.usuario,
});

export default compose(
  connect(mapStateToProps, {}),
  withStyles(styles, { withTheme: true })
)(withSnackbar(DetalleManual));
