import React from "react";
import { connect } from "react-redux";
import { DrawerContext } from "../../context/DrawerContext";
import { getRequest } from "../../utils/axios";
import Autocomplete from '@mui/material/Autocomplete';
import { Grid, Divider, TextField, Button } from "@mui/material";
import { withStyles } from "@mui/styles";
import Contenido from "../../components/Common/Contenido";
import Pagination from '@mui/material/Pagination'; // Paginamiento
import CategoriaForo from "./Categorias/CategoriaForo";
import { CustomerButton, LinkButton } from "../../common/buttons";
import {
  Edit as EditIcon,
  Visibility,
  Add as CreateIcon
} from "@mui/icons-material";
import "./Foro.css";
import { withRouter } from "react-router-dom";
import SkeletonCategoria from "../../components/Foro/SkeletonCategoria";

const styles = (theme) => ({});

class Foro extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buscador: {
        nombre: "",
        pagina: 1,
        tamano_pagina: 10,
        num_pages: -1,
        num_results: -1,
        order: "fecha",
      },
      categorias: [],
      categoryNames: [],
      tamano_pagina_opciones: [
        { id: 0, valor: 5 },
        { id: 1, valor: 10 },
        { id: 2, valor: 20 },
        { id: 3, valor: 50 },
        { id: 4, valor: 100 },
      ],
      orders: [
        { nombre: "Fecha de primer post", codigo: "fecha_primer_post" },
        { nombre: "Fecha de último post", codigo: "fecha_ultimo_post" },
        { nombre: "Número de conversaciones", codigo: "n_conversaciones" },
        { nombre: "Número de comentarios", codigo: "n_comentarios" },
        { nombre: "Número de visitas", codigo: "n_visitas" },
        { nombre: "Número de reacciones", codigo: "n_total_reacciones" },
      ],
      buscado: false, // Para cambiar Novedades por Resultados,
      cargando: true,
      edit: false
    };
  }

  componentDidMount() {
    this.context.setCurrentDrawerItem("Foro");
    let component = this;

    async function getCategorias() {
      await getRequest(
        `${process.env.REACT_APP_API_URL}/api/foro/categorias/`,
        {
          pagina: component.state.buscador.pagina,
          tamano_pagina: component.state.buscador.tamano_pagina,
          get_for_edit: component.edit ? true : false
        }
      )
        .then((response) => {
          component.setState({
            categorias: response.data.categorias,
            categoryNames: response.data.todas,
            buscador: {
              ...component.state.buscador,
              num_pages: response.data.num_pages,
              num_results: response.data.num_results,
            },
            cargando: false,
          });
        })
        .catch((e) => {
          component.setState({ cargando: false });
          console.log(e);
        });
    }
    getCategorias();
  }

  onChange = (e) => {
    this.setState(
      {
        buscador: {
          ...this.state.buscador,
          [e.target.name]: e.target.value,
        },
      },
      () => {
        if (e.target.name === "order") this.handleSearch();
      }
    );
  };

  handleSearch = (e) => {
    if (e != null) e.preventDefault();
    getRequest(
      `${process.env.REACT_APP_API_URL}/api/foro/categorias/`,
      { ...this.state.buscador, get_for_edit: this.state.edit ? true : false }
    ).then((response) => {
      this.setState({
        categorias: response.data.categorias,
        buscador: {
          ...this.state.buscador,
          num_pages: response.data.num_pages,
          num_results: response.data.num_results,
        },
        buscado: true,
      });
    });
  };

  handlePagination = (e, value) => {
    this.setState(
      { buscador: { ...this.state.buscador, pagina: parseInt(value) } },
      () => this.handleSearch()
    );
  };

  handleResultadosPorPagina = (e) => {
    this.setState(
      {
        buscador: {
          ...this.state.buscador,
          pagina: 1,
          tamano_pagina: parseInt(e.target.value),
        },
      }, // vuelta a pagina 1, pero cambiando tamano_pagina
      () => this.handleSearch()
    ); // Se realiza nueva bpusqueda
  };

  setEdit = () => {
    this.setState({ edit: !this.state.edit }, () => this.handleSearch())
  }

  displayCategorias = (theme) => {
    if (this.state.cargando) {
      return (
        <div className="fororesultados__resultadoscontainer">
          {[0, 1, 2, 3, 4, 5, 6].map((i) => (
            <SkeletonCategoria key={i} />
          ))}
        </div>
      );
    } else if (this.state.categorias.length > 0)
      return (
        <div className="fororesultados__resultadoscontainer">
          {this.state.categorias.map((categoria) => {
            return (
              <CategoriaForo
                key={categoria.id}
                categoria={categoria}
                theme={theme}
                usuario={this.props.usuario}
                edit={this.state.edit}
              />
            );
          })}
        </div>
      );

    return (
      <div className="center">
        <p>No se encontraron categorias</p>
      </div>
    );
  };

  render() {
    const { theme } = this.props;
    const componente = (
      <div>
        {this.props.usuario.groups.includes('admin') ?
          <Grid container className="boton__subirContainer_categoria">
            <CustomerButton
              className="boton__agregar_categoria"
              text={this.state.edit ? 'Visualizar' : 'Editar'}
              color={theme.foro}
              child={
                this.state.edit ? <Visibility className="Boton__subirCapsulaIcon" /> : <EditIcon className="Boton__subirCapsulaIcon" />
              }
              handle={this.setEdit}
            />
            <LinkButton
              className="boton__agregar_categoria"
              text='Crear categoria'
              color={theme.foro}
              child={
                <CreateIcon className="Boton__subirCapsulaIcon" />
              }
              to={'/foro/categorias/crear'}
            />
          </Grid> : null}
        {/* Buscador */}
        <div className="Comunidad__container">
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <h2 className="buscador__titulo">Buscador</h2>
            </Grid>
            <Grid item xs={12} >
              <form>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={9}>
                    <Autocomplete
                      freeSolo
                      options={this.state.categoryNames}
                      getOptionLabel={(option) => option.nombre}
                      renderInput={(params) => <TextField  {...params} size="small" variant="outlined" className="buscador__buscador"
                      />}
                      onChange={(ev) => this.onChange({ target: { name: 'nombre', value: ev.target.value } })}
                      onSelect={(ev) => this.onChange({ target: { name: 'nombre', value: ev.target.value } })}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={3}
                    className="buscador__botonBuscarContainer"
                  >
                    <Button
                      className="buscador__botonBuscar"
                      style={{
                        background: theme.primary,
                        width: "100%",
                      }}
                      variant="contained"
                      type="submit"
                      onClick={this.handleSearch}
                    >
                      Buscar
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
            <Grid item xs={12}>
              <div className={"buscador__divider"}>
                <Divider />
              </div>
            </Grid>
            {/* Order */}
            <Grid item xs={12}>
              <Grid
                container
                style={{ justifyContent: "flex-end !important" }}
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                  md={8}
                  xl={8}
                  sx={{ alignSelf: "felx-start !important" }}
                >
                  <h2 className="resultados__titulo">
                    {this.state.buscado ? "Resultados" : "Novedades"}
                  </h2>
                </Grid>
                <Grid item xs={12} md={2} xl={2}>
                  <TextField
                    label="Orden de resultados"
                    name="order"
                    onChange={this.onChange}
                    select
                    SelectProps={{ native: true }}
                    InputLabelProps={{ shrink: true }}
                    //value={this.state.nivel}
                    variant="filled"
                    size="small"
                    style={{ width: "100%" }}
                  >
                    {this.state.orders.map((order) => (
                      <option key={order.codigo} value={order.codigo}>
                        {order.nombre}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} md={2} xl={2}>
                  <TextField
                    label="Resultados por página"
                    name="resultados_por_pagina"
                    onChange={this.handleResultadosPorPagina}
                    select
                    SelectProps={{ native: true }}
                    InputLabelProps={{ shrink: true }}
                    variant="filled"
                    size="small"
                    style={{ width: "100%" }}
                    defaultValue={10}
                  >
                    {this.state.tamano_pagina_opciones.map((tamano) => (
                      <option key={tamano.id} value={tamano.valor}>
                        {tamano.valor}
                      </option>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        {/* Resultados */}
        <div className="resultados__padding">
          {this.displayCategorias(theme)}
          {/* Paginamiento */}
          <div className="pagination__center">
            <Pagination
              count={this.state.buscador.num_pages}
              page={this.state.buscador.pagina}
              onChange={this.handlePagination}
              className="paginamiento"
            />
          </div>
        </div>
      </div>
    );

    return (
      <Contenido
        componente={componente}
        seccion={"Foro"}
        descripcion={"Bienvenidos y bienvenidas al Foro Docente. Este es un espacio destinado al fortalecimiento del trabajo en red entre los profesores y profesoras tarapaqueños/as, donde podrán compartir experiencias, buenas prácticas, dudas, entre otros temas propios del quehacer pedagógico, en la búsqueda de mejora continua del proceso de enseñanza aprendizaje al interior de las aulas tarapaqueñas."}
        backgroundColor={"#7a81c9"}
      />
    );
  }
}

Foro.contextType = DrawerContext;

const mapStateToProps = (state) => ({
  usuario: state.auth.usuario,
});

export default withRouter(
  connect(mapStateToProps, {})(withStyles(styles, { withTheme: true })(Foro))
);
