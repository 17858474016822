import { createTheme } from '@mui/material/styles';
import MyriadProRegular from "./fonts/MyriadProRegular.ttf";

export const cssVariables = {
  // Paleta principal
  negro: "#303030",  // negro oscuro
  primary: "#267EBF", // celeste   "#2d9bf0"
  secondary: "#909497", // gris
  gris: "#e8e8e8",
  grisOscuro: "#bcbcbc", // gris oscuro para dividers por ejemplo
  grisMuyOscuro: "#757577", // gris para fecha de comentarios

  // Secciones
  capsula: "#F67E66",
  manual: "#A497C9",
  bdocente: "#A1AD90",
  foro: "#627EA3",
  comunidad: "#D3A644",
  bacademica: "#CE9FB5B2",
  vocabulario: "#67A5C5",
  ayuda: "#b1b1b1",

  // Botones
  subir: "#EAC17E",  // naranjo damasco
  guardar: "#77B487", // Verde
  editar: "#EBF07E",   // amarillo opaco
  eliminar: "#F64343",    // rojo warning

  // Boton Like
  likeRed: "#ed4956",
  likeBlue: "#677BFF",

  // Colores de areas dados por profe Irene
  fisica: "#E24949",
  quimica: "#F78B2B",
  biologia: "#EFC825",
  cienciasNaturales: "#49CC29",
  matematicas: "#33a6d3",
  tecnologia: "#8962e8",
  dorado: "#c49e46",

  // Colores de extensiones
  pdf: "#FF4E4E",
  docx: "#77A0FF",
  xlsx: "#31bd7d",
};

const myriadPro = {
  fontFamily: "MyriadPro",
  fontStyle: "regular",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
    local('MyriadProRegular'),
    url(${MyriadProRegular}) format('ttf')
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

export const theme = createTheme({
  palette: {
    primary: {
      500: "#267EBF",
    },
    secondary: {
      main: "#909497",
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [myriadPro],
      },
    },
  },
});

theme.overrides = {
  MuiButton: {
    root: {
      textTransform: "none",
      color: "white",
      margin: "0 0px",
    },
    contained: {
      color: "white",
    },
    containedPrimary: {},
    containedSecondary: {},
  },
  MuiDialogActions: {
    root: {
      justifyContent: "space-evenly",
      padding: "30px",
    },
  },
};
