import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import { compose } from "redux";
import { withStyles } from "@mui/styles";
import Contenido from "../../../components/Common/Contenido";
import { getRequest } from "../../../utils/axios";
import { AccountCircle, MenuBook, School } from "@mui/icons-material";
import { Button, Grid } from "@mui/material";
import { DrawerContext } from "../../../context/DrawerContext";
import {
  EditarFoto,
  EditarInformacion,
  EditarEspecialidades,
  EditarAreas,
} from "./index";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Slider from "../../../components/Slider/Slider";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel2(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps2(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const styles = (theme) => ({
  root: {
    margin: 0,
    paddingLeft: "calc(5% + 20px)",
    paddingRight: "calc(5% + 20px)",
    maxWidth: "100%",
    textAlign: "left",
    paddingBottom: "30px",
  },
  box: {
    margin: "10px 0",
    padding: "15px",
    borderRadius: "8px",
    border: "2px solid #EEEEED",
  },
  row: {
    justifyContent: "space-between",
    alignItems: "center",
  },
  row_icon: {
    display: "flex",
    alignItems: "center",
    margin: "5px",
  },
  boton__editar: {
    height: "fit-content",
    width: "200px",
  },
  imageSection: {
    paddingLeft: "16px",
  },
  imageSection2: {
    paddingLeft: "16px",
    paddingTop: "16px",
  },
  imageAccount: {
    height: "140px",
  },
  iconAccount: {
    fontSize: "140px",
  },
  contenido: {
    paddingLeft: "32px",
    display: "flex",
  },
  texto_negro: {
    fontWeight: "bold",
  },
  icon: {
    fontSize: "30px",
    marginRight: "30px",
  },
});

class Foto extends React.Component {
  state = {
    openModal: false,
    urlPhoto: `${process.env.REACT_APP_API_URL}/miniaturas/usuarios/${this.props.usuario.id}`,
  };

  handleClick = (value) => {
    this.setState({ openModal: value });
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        {this.state.openModal ? (
          <EditarFoto
            openModal={this.state.openModal}
            handleClick={this.handleClick}
            usuario={this.props.usuario}
            updateUsuario={this.props.updateUsuario}
          />
        ) : null}

        <Grid container className={classes.imageSection}>
          <Grid item xs={12} md={12}>
            {this.props.usuario.foto_perfil ? (
              <img
                id="image_profile"
                src={this.state.urlPhoto}
                className="EditarFoto__account_image"
                alt="foto"
              />
            ) : (
              <AccountCircle className={"EditarFoto__account_image"} />
            )}
          </Grid>
        </Grid>
        <Grid container className={classes.imageSection2}>
          <Grid item xs={12} sm={12} md={12}>
            <Button
              color="primary"
              variant="contained"
              className={classes.boton__editar}
              onClick={() => this.handleClick(true)}
            >
              Editar
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }
}

class InformacionPersonal extends React.Component {
  state = {
    openModal: false,
    updateUsuario: this.props.updateUsuario,
  };

  handleClick = (value) => {
    this.setState({ openModal: value });
  };

  render() {
    const { classes, usuario } = this.props;
    return (
      <div
      // className={classes.box}
      >
        {this.state.openModal ? (
          <EditarInformacion
            openModal={this.state.openModal}
            handleClick={this.handleClick}
            usuario={this.props.usuario}
            updateUsuario={this.state.updateUsuario}
          />
        ) : null}
        <Grid
          container
          spacing={1}
          // className={classes.contenido}
          className={classes.imageSection}
        >
          <Grid item xs={12} md={12}>
            <span className={classes.texto_negro}>Nombre:</span>{" "}
            {`${usuario.primer_nombre} ${usuario.apellido_paterno} ${usuario.apellido_materno}`}
          </Grid>
          <Grid item xs={12} md={12}>
            <span className={classes.texto_negro}> Correo: </span>{" "}
            {usuario.email}
          </Grid>
          <Grid item xs={12} md={12}>
            <span className={classes.texto_negro}> Rut:</span> {usuario.rut}
          </Grid>
          <Grid item xs={12} md={12}>
            <span className={classes.texto_negro}> Género:</span>{" "}
            {usuario.genero}
          </Grid>
          <Grid item xs={12} md={12}>
            <span className={classes.texto_negro}> Nacionalidad:</span>{" "}
            {usuario.nacionalidad}
          </Grid>

          <Grid item xs={12} md={12}>
            <span className={classes.texto_negro}>Teléfono:</span>{" "}
            {usuario.telefono}
          </Grid>
          <Grid item xs={12} md={12}>
            <span className={classes.texto_negro}>Fecha nacimiento:</span>{" "}
            {moment(usuario.fecha_nacimiento).format("L")}
          </Grid>
        </Grid>
        <Grid
          container
          // className={classes.row}
          className={classes.imageSection2}
        >
          <Grid item xs={12} sm={12} md={12}>
            <Button
              color="primary"
              variant="contained"
              className={classes.boton__editar}
              onClick={() => this.handleClick(true)}
            >
              Editar
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }
}

class Especialidades extends React.Component {
  state = {
    openModal: false,
    updateUsuario: this.props.updateUsuario,
  };

  handleClick = (value) => {
    this.setState({ openModal: value });
  };

  render() {
    const { classes } = this.props;
    const especialidades = this.props.usuario.usuario_asociado.especialidad;
    return (
      <div
      // className={classes.box}
      >
        {this.state.openModal ? (
          <EditarEspecialidades
            openModal={this.state.openModal}
            handleClick={this.handleClick}
            usuario={this.props.usuario}
            updateUsuario={this.state.updateUsuario}
          />
        ) : null}
        <Grid
          container
          // className={classes.contenido}
          className={classes.imageSection}
        >
          {especialidades.map((especialidad) => (
            <Grid
              item
              xs={12}
              md={12}
              key={especialidad}
              className={classes.row_icon}
            >
              <School className={classes.icon} /> {especialidad}
            </Grid>
          ))}
        </Grid>
        <Grid
          container
          // className={classes.row}
          className={classes.imageSection2}
        >
          <Grid item xs={12} sm={12} md={12}>
            <Button
              color="primary"
              variant="contained"
              className={classes.boton__editar}
              onClick={() => this.handleClick(true)}
            >
              Editar
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }
}

class Areas extends React.Component {
  state = {
    openModal: false,
    updateUsuario: this.props.updateUsuario,
  };

  handleClick = (value) => {
    this.setState({ openModal: value });
  };

  render() {
    const areas = this.props.usuario.usuario_asociado.area;
    const { classes } = this.props;
    return (
      <div
      // className={classes.box}
      >
        {this.state.openModal ? (
          <EditarAreas
            openModal={this.state.openModal}
            handleClick={this.handleClick}
            usuario={this.props.usuario}
            updateUsuario={this.state.updateUsuario}
          />
        ) : null}
        <Grid
          container
          // className={classes.contenido}
          className={classes.imageSection}
        >
          {areas.map((area) => (
            <Grid
              item
              xs={12}
              md={12}
              key={area}
              className={classes.row_icon}
            >
              <MenuBook className={classes.icon} /> {area}
            </Grid>
          ))}
        </Grid>
        <Grid
          container
          // className={classes.row}
          className={classes.imageSection2}
        >
          <Grid item xs={4} sm={2} md={1}>
            <Button
              color="primary"
              variant="contained"
              className={classes.boton__editar}
              onClick={() => this.handleClick(true)}
            >
              Editar
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }
}

class EditarPerfil extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      usuario: null,
      value: 0,
      value2: 0,
      capsulas: [],
      manuales: [],
      materialesDocentes: [],
    };
  }

  async getUsuario() {
    const url = `${process.env.REACT_APP_API_URL}/api/usuarios/${this.props.usuario.id}/`;
    const response = await getRequest(url);
    this.setState({ usuario: response.data });
  }

  async getContenidosSubidos(id) {
    const url = `${process.env.REACT_APP_API_URL}/api/usuarios/${id}/contenidos-subidos/`;
    await getRequest(url).then((response) => {
      this.setState({
        capsulas: response.data.capsulas,
        manuales: response.data.manuales,
        materialesDocentes: response.data.materialesDocentes,
      });
    });
  }

  async componentDidMount() {
    const id = this.props.usuario.id;

    this.context.setCurrentDrawerItem("Perfil");
    this.getUsuario();
    await this.getContenidosSubidos(id);
  }

  updateUsuario = async () => {
    await this.getUsuario();
  };

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  handleChange2 = (event, newValue) => {
    this.setState({ value2: newValue });
  };

  render() {
    if (!this.state.usuario) {
      return null;
    }
    const { classes, theme } = this.props;

    const componente = (
      <div className={classes.root}>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={this.state.value}
              onChange={this.handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Información" {...a11yProps(0)} />
              <Tab label="Mis publicaciones" {...a11yProps(1)} />
            </Tabs>
          </Box>
          {/* Informacion Panel */}
          <TabPanel value={this.state.value} index={0}>
            <Box
              sx={{
                flexGrow: 1,
                bgcolor: "background.paper",
                display: "flex",
                height: "100%",
                paddingTop: "20px",
              }}
            >
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={this.state.value2}
                onChange={this.handleChange2}
                aria-label="Vertical tabs example"
                sx={{ borderRight: 1, borderColor: "divider" }}
              >
                <Tab label="Foto de perfil" {...a11yProps2(0)} />
                <Tab label="Información personal" {...a11yProps2(1)} />
                <Tab label="Especialidades" {...a11yProps2(2)} />
                <Tab label="Areas" {...a11yProps2(3)} />
              </Tabs>
              <TabPanel2
                value={this.state.value2}
                index={0}
                style={{ width: "100%" }}
              >
                <Foto
                  classes={classes}
                  usuario={this.state.usuario}
                  updateUsuario={this.updateUsuario}
                />
              </TabPanel2>
              <TabPanel2
                value={this.state.value2}
                index={1}
                style={{ width: "100%" }}
              >
                <InformacionPersonal
                  classes={classes}
                  usuario={this.state.usuario}
                  updateUsuario={this.updateUsuario}
                />
              </TabPanel2>
              {this.state.usuario.rol === "Profesor" &&
                this.state.usuario.usuario_asociado ? (
                <TabPanel2
                  value={this.state.value2}
                  index={2}
                  style={{ width: "100%" }}
                >
                  <Especialidades
                    classes={classes}
                    usuario={this.state.usuario}
                    updateUsuario={this.updateUsuario}
                  />
                </TabPanel2>
              ) : null}
              {this.state.usuario.rol === "Profesor" &&
                this.state.usuario.usuario_asociado ? (
                <TabPanel2
                  value={this.state.value2}
                  index={3}
                  style={{ width: "100%" }}
                >
                  <Areas
                    classes={classes}
                    usuario={this.state.usuario}
                    updateUsuario={this.updateUsuario}
                  />
                </TabPanel2>
              ) : null}
            </Box>
          </TabPanel>
          {/* Mis publicaciones */}
          <TabPanel value={this.state.value} index={1}>
            <div className=" Perfil__Slider">
              <h4>Manuales subidos</h4>
              <Slider
                items={this.state.manuales}
                type={"manual"}
                usuario={this.state.usuario}
              />
            </div>
            <div className=" Perfil__Slider">
              <h4>Capsulas subidas</h4>
              <Slider
                items={this.state.capsulas}
                type={"capsula"}
                usuario={this.state.usuario}
              />
            </div>
            <div className="Perfil__Slider">
              <h4>Material Docente subido</h4>
              <Slider
                items={this.state.materialesDocentes}
                type={"materialDocente"}
                usuario={this.state.usuario}
              />
            </div>
          </TabPanel>
        </Box>
      </div>
    );
    return (
      <Contenido
        componente={componente}
        seccion={"Mi perfil"}
        descripcion={" "}
        backgroundColor={theme.comunidad}
      />
    );
  }
}

EditarPerfil.contextType = DrawerContext;

const mapStateToProps = (state) => ({
  usuario: state.auth.usuario,
});

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, {})
)(EditarPerfil);
