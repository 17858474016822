import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "./slider.css";
import Capsula from "../../components/Capsulas/Capsula";
import Manual from "../../components/Manuales/Manual";
import Material from "../../components/BibliotecaDocente/Material";

export default function SimpleSlider({ items, type, usuario }) {
  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
  };

  const renderItems = () => {
    if (items.length === 0) {
      return <div>No hay</div>;
    }
    if (type === "capsula") {
      return items.map((item) => (
        <Capsula
          key={item.id}
          capsula={item}
          colorCapsula={"#d3d3d3"}
          usuario={usuario}
        />
      ));
    } else if (type === "manual") {
      return items.map((item) => (
        <Manual
          key={item.id}
          manual={item}
          theme={"#d3d3d3"}
          usuario={usuario}
        />
      ));
    } else if (type === "materialDocente") {
      return items.map((item) => (
        <Material key={item.id} material={item} usuario={usuario} />
      ));
    }
  };

  return (
    <Slider {...settings} className="slider_css">
      {renderItems()}
    </Slider>
  );
}
