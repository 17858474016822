import React from "react";
import moment from "moment";
import {
  Dialog,
  DialogTitle,
  Grid,
  DialogActions,
  Button,
  DialogContent,
  TextField,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { validateEmail, validatePhone } from "../../../utils/validator";
import { putRequest } from "../../../utils/axios";

const styles = (theme) => ({
  texto_negro: {
    fontWeight: "bold",
  },
  contenido: {
    paddingLeft: "32px",
    display: "flex",
    justifyContent: "center",
    height: "370px",
  },
  save_button: {
    backgroundColor: theme.guardar,
  },
  "@media (max-width:480px)": {
    // eslint-disable-line no-useless-computed-key
    contenido: {
      padding: "0",
    },
  },
});

class EditarInformacion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: this.props.openModal,
      handleClick: this.props.handleClick,
      usuario: this.props.usuario,
      updateUsuario: this.props.updateUsuario,
      generos: [
        { id: 1, name: "Masculino" },
        { id: 2, name: "Femenino" },
        { id: 3, name: "Otro" },
      ],
      form: {
        usuario: this.props.usuario.id,
        nombre: `${this.props.usuario.primer_nombre} ${this.props.usuario.segundo_nombre} ${this.props.usuario.apellido_paterno} ${this.props.usuario.apellido_materno}`,
        rut: this.props.usuario.rut,
        email: this.props.usuario.email,
        genero: this.props.usuario.genero,
        nacionalidad: this.props.usuario.nacionalidad,
        telefono: this.props.usuario.telefono,
        fecha_nacimiento: moment(this.props.usuario.fecha_nacimiento).format(
          "L"
        ),
      },
      errors: {
        email: "",
        telefono: "",
      },
    };
  }

  handleClose = () => {
    this.state.handleClick(false);
  };

  onChange = (e) => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const form = this.state.form;
    let errors = {
      email: "",
      telefono: "",
      genero: "",
    };

    let isValid = true;

    for (const p in errors) {
      if (!form[p]) {
        errors[p] = `Debe ingresar ${p}`;
        isValid = false;
      }
    }

    if (!validateEmail(form.email)) {
      errors["email"] = "Debe ingresar un email válido";
      isValid = false;
    }

    if (!validatePhone(form.telefono)) {
      errors["telefono"] = "Debe ingresar un telefono válido";
      isValid = false;
    }

    this.setState({ errors });
    if (isValid) {
      //PUT
      const response = await putRequest(
        `${process.env.REACT_APP_API_URL}/api/usuarios/${this.state.usuario.id}/`,
        this.state.form
      );

      if (response.status === 200) {
        await this.state.updateUsuario();
      }
      this.handleClose();
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Dialog
          open={this.state.openModal}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
          fullWidth={true}
          maxWidth="md"
        >
          <DialogTitle id="responsive-dialog-title">
            Editar Información Personal
          </DialogTitle>
          <DialogContent>
            <form>
              <Grid container className={classes.contenido}>
                <Grid container spacing={1}>
                  <Grid item xs={2} md={2} className={classes.texto_negro}>
                    Nombre:
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      size="small"
                      name="nombre"
                      fullWidth={true}
                      value={this.state.form.nombre}
                      InputProps={{
                        readOnly: true,
                      }}
                      disabled
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={2} md={2} className={classes.texto_negro}>
                    Correo:
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth={true}
                      name="email"
                      value={this.state.form.email}
                      onChange={this.onChange}
                      error={Boolean(this.state.errors.email)}
                      helperText={this.state.errors.email}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={2} md={2} className={classes.texto_negro}>
                    Rut:
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth={true}
                      name="rut"
                      value={this.state.form.rut}
                      InputProps={{
                        readOnly: true,
                      }}
                      disabled
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={2} md={2} className={classes.texto_negro}>
                    Género:
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      size="small"
                      select
                      fullWidth={true}
                      SelectProps={{ native: true }}
                      InputLabelProps={{ shrink: true }}
                      name="genero"
                      value={this.state.form.genero}
                      onChange={this.onChange}
                    >
                      {this.state.generos.map((genero, index) => (
                        <option key={genero.id} value={genero.name}>
                          {genero.name}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={2} md={2} className={classes.texto_negro}>
                    Nacionalidad:
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth={true}
                      name="nacionalidad"
                      value={this.state.form.nacionalidad}
                      InputProps={{
                        readOnly: true,
                      }}
                      disabled
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={2} md={2} className={classes.texto_negro}>
                    Teléfono:
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth={true}
                      name="telefono"
                      value={this.state.form.telefono}
                      onChange={this.onChange}
                      error={Boolean(this.state.errors.telefono)}
                      helperText={this.state.errors.telefono}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={2} md={2} className={classes.texto_negro}>
                    Fecha nacimiento:
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      size="small"
                      name="fecha_nacimiento"
                      fullWidth={true}
                      value={this.state.form.fecha_nacimiento}
                      disabled
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={this.handleSubmit}
              variant="contained"
              type="submit"
              className={classes.save_button}
            >
              Guardar
            </Button>
            <Button
              onClick={this.handleClose}
              color="secondary"
              variant="contained"
              autoFocus
            >
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(EditarInformacion);
