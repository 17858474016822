import {cssVariables} from "../theme/index";
var md5 = require('md5');

export function getAreaColor(area) {
    switch (area) {
        case "Matemáticas":
            return cssVariables.matematicas;
        case "Física":
            return cssVariables.fisica;
        case "Química":
            return cssVariables.quimica;
        case "Biología":
            return cssVariables.biologia;
        case "Ciencias naturales":
            return cssVariables.cienciasNaturales;
        case "Tecnología":
            return cssVariables.tecnologia;
        default:
            return "#5a5a5a";
    }
}

export function getRandomColor(idUsuario) {
    var n = parseInt(md5(idUsuario), 16) % 5
    switch (n) {
        case 0:
            return cssVariables.matematicas;
        case 1:
            return cssVariables.fisica;
        case 2:
            return cssVariables.quimica;
        case 3:
            return cssVariables.biologia;
        case 4:
            return cssVariables.cienciasNaturales;
        case 5:
            return cssVariables.tecnologia;
        default:
            return "#5a5a5a";
    }
}

export function getBackgroundColor(extension) {
    switch (extension) {
        case "application/pdf":
        case "pdf":
        case ".pdf":
            return cssVariables.pdf;
        case "docx":
        case ".docx":
            return cssVariables.docx;
        case "xlsx":
        case ".xlsx":
            return cssVariables.xlsx;
        default:
            return "#000000";
    }
}

// module.exports = {
//     getAreaColor,
//     getBackgroundColor
// };
