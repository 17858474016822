import React from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { login } from "../../actions/auth";
import {
  Card,
  CardContent,
  CardHeader,
  Button,
  Grid,
  TextField,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import { publicPutRequest, publicGetRequest } from "../../utils/axios";
import { withStyles } from "@mui/styles";
import { AccountCircle, Lock } from "@mui/icons-material";
import { validatePassword } from "../../utils/validator";
import "./index.css";

const styles = (theme) => ({});
export class Register extends React.Component {
  state = {
    token: this.props.match.params.token,
    validToken: true,
    loading: false,
    checkingToken: true,
    correo: "fic@unap.cl",
    password: "",
    confirmPassword: "",
    errors: {
      password: "",
      confirmPassword: "",
      form: "",
    },
  };

  componentDidMount() {
    let component = this;
    async function validateToken() {
      try {
        const response = await publicGetRequest(
          `${process.env.REACT_APP_API_URL}/api/usuarios/activar-cuenta/`,
          {
            token: component.state.token,
          }
        );
        if (response.status !== 200) {
          component.setState({ validToken: false, checkingToken: false });
        } else {
          component.setState({
            validToken: true,
            checkingToken: false,
            correo: response.data.email,
          });
        }
      } catch (e) {
        component.setState({ validToken: false, checkingToken: false });
      }
    }
    validateToken();
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onSubmit = async (e) => {
    e.preventDefault();
    let isValid = true;
    let errors = {
      password: "",
      confirmPassword: "",
    };

    if (this.state.password === "") {
      errors.password = "Debe ingresar una contraseña";
      isValid = false;
    }
    if (this.state.confirmPassword === "") {
      errors.confirmPassword = "Debe ingresar una contraseña";
      isValid = false;
    } else if (this.state.password !== this.state.confirmPassword) {
      errors.password = "Contraseñas no coinciden";
      errors.confirmPassword = "Contraseñas no coinciden";
      isValid = false;
    } else if (
      this.state.password.length < 8 ||
      this.state.password.length > 100
    ) {
      errors.password = "La contraseña debe tener entre 8 a 100 caracteres";
      isValid = false;
    } else if (!validatePassword(this.state.password)) {
      errors.password =
        "Contraseña debe incluir mayúsculas, minúsculas y numeros";
      isValid = false;
    }
    this.setState({ errors });
    if (isValid) {
      this.setState({ loading: true });
      try {
        const response = await publicPutRequest(
          `${process.env.REACT_APP_API_URL}/api/usuarios/activar-cuenta/`,
          {
            password: this.state.password,
            password_confirmation: this.state.confirmPassword,
            token: this.state.token,
          }
        );
        if (response.status === 204 || response.status === 200) {
          this.props.login(this.state.correo, this.state.password);
        } else {
          this.setState({
            loading: false,
            errors: {
              ...this.state.errors,
              form: "No se pudo activar la cuenta",
            },
          });
        }
      } catch (e) {
        let errorForm;
        if (e.response) {
          if (e.response.status === 404 || e.response.status === 400) {
            errorForm = "Token no válido";
          }
        } else {
          errorForm = "Hubo un error, no se pudo activar la cuenta";
        }
        this.setState({
          loading: false,
          errors: {
            ...this.state.errors,
            form: errorForm,
          },
        });
      }
    }
  };

  render() {
    if (this.props.isAuthenticated) {
      return <Redirect to="/" />;
    }

    if (!this.state.token) {
      return <Redirect to="/ingresar" />;
    }

    if (this.state.checkingToken) {
      return (
        <div className="root__login">
          <Card className="card__login">
            <CircularProgress />
          </Card>
        </div>
      );
    }

    if (!this.state.validToken) {
      return (
        <div className="root__login">
          <Card className="card__login">
            <p>Token inválido o expirado</p>
            <Button
              color="primary"
              variant="contained"
              component={Link}
              to="/ingresar"
            >
              Ingresar
            </Button>
          </Card>
        </div>
      );
    }

    return (
      <div className="root__login">
        <Card className="card__login">
          <CardHeader
            title="Bienvenido"
            variant="h2"
            subheader="Para acceder al sistema, primero debes crear una contraseña. La contraseña debe contener entre 8 a 100 caracteres, debe contener mayúsculas, minúsculas y números. Se permiten caracteres especiales"
            classes={{ title: "title__login" }}
          />
          <CardContent padding="0">
            <form onSubmit={this.onSubmit}>
              <Grid container justifyContent="center" spacing={3}>
                <h4>Crear contraseña</h4>
              </Grid>
              <Grid
                container
                justifyContent="center"
                spacing={2}
                className="Registration__form"
              >
                <Grid item xs={12} md={12}>
                  <TextField
                    disabled
                    variant="outlined"
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountCircle style={{ color: "gray" }} />
                        </InputAdornment>
                      ),
                    }}
                    fullWidth={true}
                    name="correo"
                    label="Correo"
                    type="email"
                    value={this.state.correo}
                  />
                </Grid>

                <Grid item xs={12} md={12}>
                  <TextField
                    fullWidth={true}
                    variant="outlined"
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Lock style={{ color: "gray" }} />
                        </InputAdornment>
                      ),
                    }}
                    type="password"
                    name="password"
                    label="Contraseña"
                    onChange={this.onChange}
                    value={this.state.password}
                    error={Boolean(this.state.errors.password)}
                    helperText={this.state.errors.password}
                  />
                </Grid>

                <Grid item xs={12} md={12}>
                  <TextField
                    type="password"
                    variant="outlined"
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Lock style={{ color: "gray" }} />
                        </InputAdornment>
                      ),
                    }}
                    fullWidth={true}
                    name="confirmPassword"
                    label="Confirmar Contraseña"
                    onChange={this.onChange}
                    value={this.state.confirmPassword}
                    error={Boolean(this.state.errors.confirmPassword)}
                    helperText={this.state.errors.confirmPassword}
                  />
                </Grid>
              </Grid>
              <p className="errorForm">{this.state.errors.form}</p>
              {this.state.loading ? <CircularProgress /> : null}
              <div className="formGroup">
                <Button
                  color="primary"
                  type="submit"
                  variant="contained"
                  className="button"
                >
                  Crear contraseña y acceder
                </Button>
              </div>
            </form>
          </CardContent>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  error: state.auth.error,
});

export default compose(
  connect(mapStateToProps, { login }),
  withStyles(styles)
)(Register);
