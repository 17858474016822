import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import PropTypes from "prop-types";
import { login } from "../../actions/auth";
import { Link, Redirect } from "react-router-dom";
import {
  Card,
  CardContent,
  Button,
  Grid,
  TextField,
  InputAdornment,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { AccountCircle, Lock } from "@mui/icons-material";
import "./index.css";
import { validateEmail } from "../../utils/validator";
import { createStyles } from "@mui/styles";

const styles = (theme) => createStyles({
  root: {
    "& .MuiFilledInput-root": {
      backgroundColor: "rgb(232, 241, 250)"
    },
    "& .MuiFilledInput-root:hover": {
      backgroundColor: "rgb(250, 232, 241)",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "rgb(232, 241, 250)"
      }
    },
    "& .MuiFilledInput-root.Mui-focused": {
      backgroundColor: "rgb(250, 241, 232)"
    }
  }
});

export class Login extends React.Component {
  state = {
    correo: "",
    contraseña: "",
    errors: {
      correo: "",
      contraseña: "",
    },
    errorForm: "",
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onSubmit = async (e) => {
    e.preventDefault();
    let errors = { correo: "", contraseña: "" };
    let isValid = true;
    if (!this.state.correo) {
      errors.correo = "Debe ingresar un correo";
      isValid = false;
    } else if (!validateEmail(this.state.correo)) {
      errors.correo = "Debe ingresar un correo válido";
      isValid = false;
    }
    if (!this.state.contraseña) {
      errors.contraseña = "Debe ingresar una contraseña";
      isValid = false;
    }
    this.setState({ errors, errorForm: "" });
    if (isValid) {
      await this.props.login(this.state.correo, this.state.contraseña);
      if (this.props.error) {
        this.setState({
          errorForm: this.props.error,
        });
      }
    }
  };

  static propTypes = {
    login: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
    error: PropTypes.string,
  };

  render() {
    if (this.props.isAuthenticated) {
      return <Redirect to="/" />;
    }
    return (
      <div className="root__login">
        <div class="login_image_transition">
          <img class="image_transition" id="image_transition_1" src="/login/Imagen1.jpg" alt="imagen-login-1" />
          <img class="image_transition" id="image_transition_2" src="/login/Imagen2.jpg" alt="imagen-login-2" />
          <img class="image_transition" id="image_transition_3" src="/login/Imagen3.jpg" alt="imagen-login-3" />
          <img class="image_transition" id="image_transition_4" src="/login/Imagen4.jpg" alt="imagen-login-4" />
        </div>

        <Card className="card__login">
          {this.state.errorForm ? (
            <span className="errorForm">{this.state.errorForm}</span>
          ) : null}
          <CardContent>
            <form
              name="form"
              onSubmit={this.onSubmit}
              autoComplete="off"
            >
              <Grid container spacing={3} alignItems="flex-end">
                <Grid item xs={12} md={12}>
                  <img class="image_login_linkki" src="/logo.png" alt="imagen-login-linkki" />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountCircle style={{ color: "gray", }} />
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    size="small"
                    placeholder="Correo"
                    name="correo"
                    helperText={this.state.errors.correo}
                    error={this.state.errors.correo ? true : false}
                    onChange={this.onChange}
                    value={this.state.correo}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Lock style={{ color: "gray" }} />
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    size="small"
                    type="password"
                    name="contraseña"
                    helperText={this.state.errors.contraseña}
                    error={this.state.errors.contraseña ? true : false}
                    placeholder="Contraseña"
                    onChange={this.onChange}
                    value={this.state.contraseña}
                  />
                </Grid>

                <Grid item xs={12} md={12}>
                  <Button
                    color="primary"
                    type="submit"
                    variant="contained"
                    className="button"
                    style={{ width: "calc(100% - 48px)", margin: "0px 24px", maxWidth: "227px" }}
                  >
                    Ingresar
                  </Button>
                </Grid>
              </Grid>

              <div className="formGroup">
                <Link to={"/reestablecer-contraseña/"} className="white-link">
                  ¿Olvidaste tu Contraseña?
                </Link>
              </div>
            </form>
          </CardContent>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  error: state.auth.error,
});

export default compose(
  connect(mapStateToProps, { login }),
  withStyles(styles)
)(Login);
