import React from "react";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

class CustomerButton extends React.Component {
  render() {
    const { handle, color, type, text, className, child } = this.props;
    const style = {
      backgroundColor: color,
      "&:hover": {
        backgroundColor: color,
      },
    };
    return (
      <Button
        className={className}
        type={type}
        variant="contained"
        onClick={handle ? (e) => handle(e) : () => { }}
        style={style}
      >
        {child ? child : null} {text}
      </Button>
    );
  }
}

class LinkButton extends React.Component {
  render() {
    const { to, color, text, className, child } = this.props;
    const style = {
      backgroundColor: color,
      "&:hover": {
        background: color,
      },
    };
    return (
      <Button
        className={className}
        variant="contained"
        component={Link}
        to={to}
        style={style}
      >
        {child ? child : null} {text}
      </Button>
    );
  }
}

export { CustomerButton, LinkButton }