import React from "react";
import { Link } from "react-router-dom";
import { Button, Grid } from "@mui/material";
import {
  AccountCircle,
  MenuBook,
  School,
  HomeWork,
  Email,
  Class
} from "@mui/icons-material";
import "./Profesor.css";
import { createStyles } from "@mui/styles";
import { withStyles } from "@mui/styles";
import { withSnackbar } from "notistack";
import { withRouter } from "react-router-dom";
import { getRandomColor } from "../../utils/styleUtils";
import { connect } from "react-redux";

const styles = (theme) => createStyles({});

class Profesor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profesor: this.props.profesor,
      hover: false
    };
  }

  displayColegios(colegios) {
    if (colegios.length > 1) {
      return `${this.state.profesor.colegio[0]} y ${this.state.profesor.colegio.length - 1
        } más`;
    } else if (colegios.length === 1) {
      return colegios;
    }
    return "Sin colegio";
  }

  toggleHover = () => {
    this.setState({ hover: !this.state.hover });
  };

  render() {
    const { theme } = this.props;

    const styles = createStyles({
      profesor__box: {
        borderRadius: "5px",
        border: this.state.hover
          ? "2px solid " + getRandomColor(this.state.profesor.usuario.id)
          : "2px solid #dbdbdb",
        "&:hover": {
          cursor: "pointer",
          border: "2px solid" + getRandomColor(this.state.profesor.usuario.id),
        },
      },
      profesor__barraColor: {
        width: "calc(100% + 0px)",
        height: "5px",
        backgroundColor: getRandomColor(this.state.profesor.usuario.id),
        borderRadius: "2px 2px 0px 0px",
        marginTop: "-0px",
        marginLeft: "-0px",
        border: 0,
      },
      profesor__tituloContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "5px 0px 5px 0px",
        backgroundColor: theme.gris,
        width: "100%",
        height: "25%",
      },
    });

    return (
      <Grid container spacing={1}
        style={styles.profesor__box}
        onMouseEnter={this.toggleHover}
        onMouseLeave={this.toggleHover}>
        {/* Barra superior */}
        <Grid
          item
          xs={12}
          style={styles.profesor__barraColor}
        />

        <Grid item xs={12} md={4} className="usuario__imageSection">
          {this.state.profesor.usuario.foto_perfil ? (
            <img
              src={`${process.env.REACT_APP_API_URL}/miniaturas/usuarios/${this.state.profesor.usuario.id}`}
              className="usuario__imageAccount"
              alt="foto"
            />
          ) : (
            <AccountCircle className="usuario__iconAccount" />
          )}
        </Grid>
        <Grid item xs={12} md={8} className="usuario__userInfo">
          <div className="usuario__nombre">
            {this.state.profesor.usuario.primer_nombre}{" "}
            {this.state.profesor.usuario.apellido_paterno}
            <Button
              color="primary"
              variant="contained"
              className="contact"
              component={Link}
              to={`/comunidad/${this.state.profesor.usuario.id}/contactar`}
            >
              {" "}
              <Email />
              {" "}
              Contactar
            </Button>
          </div>
          <p className="usuario__textInfo">
            <HomeWork className="usuario__icon" />
            {this.displayColegios(this.state.profesor.colegio)}
          </p>
          <p className="usuario__textInfo">
            <School className="usuario__icon" />
            {this.state.profesor.titulo_profesional}
          </p>
          <p className="usuario__textInfo">
            <MenuBook className="usuario__icon" />{" "}
            {this.state.profesor.especialidad}
          </p>
          <p className="usuario__textInfo">
            <Class className="usuario__icon" />{" "}
            {this.state.profesor.area[0]}
          </p>
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(
  connect()(withStyles(styles, { withTheme: true })(withSnackbar(Profesor)))
);