import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { ThemeProvider } from '@mui/material/styles';
import { makeStyles } from "@mui/styles";
import { SnackbarProvider } from "notistack";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import store from "./store/store";
import { loadUser } from "./actions/auth";
import Page from "./components/Layout/Page";
import {
  Login,
  RestorePassword,
  Register,
  ChangePassword,
} from "./views/Login";
import { NavBarCustumer } from "./components/Layout/Navbar";
import { DrawerCustumer } from "./components/Layout/Drawer";
import "./App.css";
import { cssVariables, theme } from "./theme";
import { DrawerContext } from "./context/DrawerContext";
import { Footer } from "./components/Layout/Footer";

const useStyles = makeStyles((estilo) => ({
  root: {
    width: "100%",
    minHeight: "100vh",
    zIndex: 1251,
    overflow: "hidden",
    fontFamily: estilo.fontFamily,
  },
  appFrame: {
    display: "flex",
    width: "100%",
    height: "100%",
  },
  content: {
    display: "flex",
    flexGrow: 1,
    width: "100%",
    minHeight: "calc(100vh - 48px) !important",
    overflowY: "auto",
    marginTop: 48,
    textAlign: "center",
    justifyContent: "center",
  },
  footer: {
    position: 'absolute',
    zIndex: "2000"
  }
}));

function App() {
  const [open, setOpen] = useState(false);
  const [currentDrawerItem, setCurrentDrawerItem] = useState("");
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);
  const classes = useStyles();

  const drawerSettings = {
    currentDrawerItem,
    setCurrentDrawerItem,
  };

  return (
    <Provider store={store}>
      <Router>
        <SnackbarProvider maxSnack={3}>
          <ThemeProvider theme={{ ...theme, ...cssVariables }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DrawerContext.Provider value={drawerSettings}>
                <div className={classes.root}>
                  <div className={classes.appFrame}>
                    <NavBarCustumer open={open} setOpen={setOpen} />
                    <DrawerCustumer open={open} setOpen={setOpen} />
                    <main className={classes.content}>
                      <Switch>
                        <Route
                          exact
                          path="/reestablecer-contraseña/"
                          component={RestorePassword}
                        />
                        <Route
                          exact
                          path="/cambiar-contraseña/:token"
                          component={ChangePassword}
                        />
                        <Route exact path="/ingresar/" component={Login} />
                        <Route
                          exact
                          path="/activar-cuenta/:token"
                          component={Register}
                        />
                        <Route
                          exact
                          path="/ingresar/reestablecer-contraseña/"
                          component={RestorePassword}
                        />
                        <Route exact path="/ingresar/" component={Login} />
                        <Route
                          exact
                          path="/registrar/"
                          component={Register}
                        />

                        <Route path="/" component={Page} />
                      </Switch>
                    </main>
                  </div>
                  <div className={classes.footer}>
                    <Footer />
                  </div>
                </div>
              </DrawerContext.Provider>
            </LocalizationProvider>
          </ThemeProvider>
        </SnackbarProvider>
      </Router>
    </Provider>
  );
}

export default App;
