import React from "react";
import { Link, useHistory } from "react-router-dom";
import { logout } from "../../actions/auth";
import { AppBar as MuiAppBar, IconButton, Typography, Toolbar } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Menu as MenuIcon, AccountCircle } from "@mui/icons-material";
import { connect } from "react-redux";
import "./Navbar.css";


const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
  },
  customerToolbar: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "20px",
    minHeight: "48px !important",
    height: "48px !important",
    background: theme.colorTema,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function LogedInView(props) {
  const { logout, usuario } = props;
  const history = useHistory();
  const handleClick = () => {
    history.push("/perfil/editar");
  }

  const handleHelpButton = () => {
    history.push("/ayuda");
  }
  return (
    <div className="linksContainer">
      <span
      >
        {usuario.foto_perfil &&
          usuario.foto_perfil !== "" ? (
          <img
            id="navbar_profile_photo"
            src={`${process.env.REACT_APP_API_URL}/miniaturas/usuarios_min/${usuario.id}`}
            className="usuario_icon"
            alt="foto"
          />
        ) : (
          <AccountCircle style={{ fontSzize: "43px !important" }} />
        )}
      </span>
      <Typography
        variant="body1"
        className="link profile"
        onClick={() => handleClick()}
      >
        {usuario.primer_nombre + " " + usuario.apellido_paterno}
      </Typography>
      <Typography variant="body1" onClick={handleHelpButton} className="link">
        Ayuda
      </Typography>
      <Typography variant="body1" onClick={logout} className="link">
        Cerrar Sesión
      </Typography>
    </div>
  );
}

function NavBar(props) {
  const classes = useStyles();
  const { isAuthenticated, usuario } = props.auth;
  const { logout, open, setOpen } = props;

  const authLinks = <LogedInView usuario={usuario} logout={logout} />;

  const handleDrawerChange = () => {
    setOpen(!open);
  };

  return (
    isAuthenticated ?
      <MuiAppBar>
        <Toolbar className={classes.customerToolbar}>
          {isAuthenticated ? (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerChange}
              edge="start"
              className="menuButton"
            >
              <MenuIcon />
            </IconButton>
          ) : (
            // Este Botón es un botón invisible que sólo se ve en la vista de login, para que no se mueva el logo de FIC en la Navbar
            <IconButton
              color="inherit"
              aria-label="empty button"
              edge="start"
              className="menuButton"
            >
              <MenuIcon style={{ opacity: "0" }} />
            </IconButton>
          )}

          <Link to="/" className="link">
            <img src="/logo.png" alt="Linkki-logo" className="logo" />
          </Link>

          {authLinks}
        </Toolbar>
      </MuiAppBar> : <span></span>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export var NavBarCustumer = connect(mapStateToProps, { logout })(NavBar);
