import React from "react";
import FormData from "form-data";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { getRequest, putRequest } from "../../../../utils/axios";
import {
  CircularProgress,
  Card,
  TextField,
  Grid,
  Button,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { withSnackbar } from "notistack";
import Contenido from "../../../../components/Common/Contenido";
import "../../../../App.css";
import { CustomerButton } from "../../../../common/buttons";
import FilesDropzone from "../../../../common/FileDropzone";
import mimeTypes from "./mimeTypes";

import "./editarCategoria.css";

const styles = (theme) => ({});

class EditarCategoria extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      categoria_id: this.props.match.params.id,
      categoria: {
        nombre: "",
        descripcion: "",
        activa: false,
        archivo: null
      },
      errors: {
        nombre: "",
        archivo: "",
        descripcion: "",
      },
      submited: false,
      loading: false,
    };
  }

  async componentDidMount() {
    let component = this;

    async function getCategoria() {
      await getRequest(
        `${process.env.REACT_APP_API_URL}/api/foro/categorias/${component.props.match.params.id}/`
      )
        .then((response) => {
          component.setState({ categoria: response.data });
        })
        .catch((e) => {
          console.log(e);
        });
    }
    await getCategoria();
  }

  onChange = (e) => {
    this.setState({
      categoria: {
        ...this.state.categoria,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleUpload = async (e) => {
    e.preventDefault();
    let isValid = true;
    let errors = {
      nombre: "",
      archivo: "",
      descripcion: "",
    };
    if (!this.state.categoria.nombre) {
      errors.nombre = "Debe ingresar un nombre";
      isValid = false;
    }
    if (this.state.categoria.nombre.length > 150) {
      errors.nombre = "Puede ingresar hasta 150 caracteres";
      isValid = false;
    }
    if (this.state.categoria.descripcion.length > 1000) {
      errors.descripcion = "Puede ingresar hasta 1000 caracteres";
      isValid = false;
    }

    if (this.state.categoria.archivo && !mimeTypes.includes(this.state.categoria.extension)) {
      errors.archivo =
        "El material no es válido. Debe subir un archivo word, pdf, ppt o excel.";
      isValid = false;
    }
    this.setState({ errors: errors });
    if (isValid) {
      this.setState({ loading: true });
      let response;
      try {
        var data = new FormData();
        data.append("nombre", this.state.categoria.nombre);
        data.append("descripcion", this.state.categoria.descripcion);
        data.append("activa", this.state.categoria.activa);
        if (this.state.categoria.archivo) {
          data.append("archivo", this.state.categoria.archivo);
        }

        response = await putRequest(
          `${process.env.REACT_APP_API_URL}/api/foro/categorias/${this.state.categoria_id}/`,
          data
        );

        if (response.status === 204) {
          this.setState({
            submited: true,
          });
          this.props.enqueueSnackbar(
            `Categoria ${this.state.categoria.nombre} editada exitosamente!`
          );
        }
      } catch (e) {
        console.log(e);
        this.props.enqueueSnackbar("No se pudo editar la categoria");
      } finally {
        this.setState({ loading: false });
      }
    }
  };

  setFileInfo = (extension, raw_file) => {
    this.setState({
      categoria: {
        ...this.state.categoria,
        extension: extension,
        archivo: raw_file,
      },
    });
  };

  render() {
    const { theme } = this.props;
    let componente;
    if (!this.props.usuario.groups.includes("admin")) {
      return componente = (
        <div className="root loading">
          <h3>No tienes permisos </h3>
        </div>
      );
    }
    if (this.state.loading) {
      componente = (
        <div className="root loading">
          <CircularProgress />
          <h3>Subiendo cambios</h3>
        </div>
      );
    } else if (this.state.submited) {
      return <Redirect to={'/foro'} />;
    } else {
      componente = (
        <div className="root">
          <Card variant="outlined" className="container">

            {this.state.errors.archivo ? (
              <p className="error">{this.state.errors.archivo}</p>
            ) : null}
            <FilesDropzone
              className="dropzone"
              setFileInfo={this.setFileInfo}
              color={theme.foro}
              archivo={this.state.categoria.archivo}
            />
            <form className="form_manuales" onSubmit={this.handleUpload}>
              <Grid container spacing={2} justifyContent="center">
                <Grid container item justifyContent="center">
                  <p className="label">Nombre</p>
                  <TextField
                    variant="outlined"
                    size="small"
                    name="nombre"
                    value={this.state.categoria.nombre}
                    onChange={this.onChange}
                    className="input"
                    helperText={this.state.errors.nombre}
                    error={Boolean(this.state.errors.nombre)}
                  />
                </Grid>

                <Grid container item justifyContent="center">
                  <p className="label">Descripción</p>
                  <TextField
                    variant="outlined"
                    size="small"
                    multiline
                    rows={7}
                    name="descripcion"
                    value={this.state.categoria.descripcion}
                    onChange={this.onChange}
                    className="input"
                    error={Boolean(this.state.errors.descripcion)}
                    helperText={this.state.errors.descripcion}
                  />
                </Grid>

                <Grid container item justifyContent="center">
                  <p className="label">Activa</p>
                  <TextField
                    variant="outlined"
                    size="small"
                    name="activa"
                    value={this.state.categoria.activa}
                    onChange={this.onChange}
                    className="input"
                    select
                    SelectProps={{ native: true }}
                  >
                    {[{ title: 'Si', value: true }, { title: 'No', value: false }].map((option) => (
                      <option key={option.title} value={option.value}>
                        {option.title}
                      </option>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            </form>
            <div className="button__Container">
              <CustomerButton
                type="submit"
                color={theme.guardar}
                handle={(e) => this.handleUpload(e)}
                text={"Guardar"}
              />
              <Button
                color="secondary"
                variant="contained"
                component={Link}
                to={'/foro'}
              >
                Cancelar
              </Button>
            </div>
          </Card>
        </div>
      );
    }
    return (
      <Contenido
        componente={componente}
        seccion={"Editar Categoria"}
        descripcion={"Bienvenidos y bienvenidas al Foro Docente. Este es un espacio destinado al fortalecimiento del trabajo en red entre los profesores y profesoras tarapaqueños/as, donde podrán compartir experiencias, buenas prácticas, dudas, entre otros temas propios del quehacer pedagógico, en la búsqueda de mejora continua del proceso de enseñanza aprendizaje al interior de las aulas tarapaqueñas."}
        backgroundColor={theme.foro}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  usuario: state.auth.usuario,
});

export default compose(
  connect(mapStateToProps, {}),
  withStyles(styles, { withTheme: true })
)(withSnackbar(EditarCategoria));
