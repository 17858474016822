import React from "react";
import { makeStyles } from "@mui/styles";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useHistory } from "react-router-dom";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer"; // Conversacion
import { Grid } from "@mui/material";
import ThumbUpAlt from "@mui/icons-material/ThumbUpAlt";

import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile"; // Icono archivo genérico
import "./Material.css";
import { cutText, countInArray } from "../../utils/utils";
import { getAreaColor, getBackgroundColor } from "../../utils/styleUtils";

export default function Material(props) {
    const { material } = props;
    const history = useHistory();

    const estilos = makeStyles({
        material__Container: {
            width: "250px",
            height: "400px",
            boxShadow:
                "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important",
            //boxShadow: "5px 5px 4px -1px rgb(0 0 0 / 50%)"
            borderRadius: "5px",
            border: "2px solid #dbdbdb",
            backgroundSize: "cover",

            "&:hover": {
                cursor: "pointer",
                border: "2px solid" + getAreaColor(material.id_area.nombre),
            },
        },
        material__BarraColor: {
            width: "calc(100% + 2px)",
            height: "25px",
            backgroundColor: getAreaColor(material.id_area.nombre),
            borderRadius: "3px 3px 0px 0px",
            marginTop: "-1px",
            marginLeft: "-1px",
            border: 0,
        },
        material__DocExtensionIconBot: {
            display: "flex",
            justifyContent: "center",
            color: getBackgroundColor(material.extension),
        },
    });

    const classes = estilos();

    return (
        <div
            className={classes.material__Container}
            onClick={() => history.push(`/biblioteca-docente/${material.id}`)}
        >
            <div className={classes.material__BarraColor}>
                <div className={"material__HeaderContainer"}>
                    <div className={"material__HeaderText"}>
                        {material.id_area.nombre}
                    </div>
                    <div className={"material__HeaderText"}>
                        {material.id_nivel.nombre}
                    </div>
                </div>
            </div>
            <div className={"material__TituloContainer"}>
                <div className={"material__Titulo"}>
                    {cutText(material.titulo, 77)}
                </div>
            </div>
            <div className={"material__Invisible"}>
                {/* <InsertDriveFileIcon className={"material__DocExtensionIconTop}/> */}
                <div className={"material__TipoContainer"}>
                    <div className={"material__TipoText"}>
                        {material.tipo_material_docente.nombre}
                    </div>
                </div>
            </div>
            <div className={"material__ImageContainer"}>
                <img
                    src={`${process.env.REACT_APP_API_URL}/miniaturas/bdocente/${material.id}/`}
                    alt="material"
                    className="material__ImagePreview"
                />
            </div>
            <div className={"material__FooterContainer"}>
                <div className={"material__IconAutorContainer"}>
                    <Grid container spacing={1}>
                        <Grid item xs={2}>
                            <div className={"material__IconContainer"}>
                                {material.id_usuario.foto_perfil && material.id_usuario.foto_perfil !== "" ? (
                                    <img
                                        src={`${process.env.REACT_APP_API_URL}/miniaturas/usuarios_min/${material.id_usuario.id}`}
                                        className="usuario_icon"
                                        alt="foto"
                                    />
                                ) : (
                                    <AccountCircleIcon style={{ height: "2em" }} />
                                )}
                            </div>
                        </Grid>
                        <Grid item xs={10} className={"material__AutorContainer"}>
                            {cutText(
                                material.id_usuario.primer_nombre +
                                " " +
                                material.id_usuario.apellido_paterno,
                                26
                            )}
                        </Grid>
                    </Grid>
                </div>
                <div className={"material__IconAutorContainer"}>
                    <Grid container spacing={1} className={"material__SpaceBetween"}>
                        <Grid item xs={2}>
                            <div className={"material__LikeIcon"}>
                                <ThumbUpAlt></ThumbUpAlt>
                            </div>
                        </Grid>
                        <Grid item xs={2} className={"material__LikeIconLabel"}>
                            {countInArray(material.likes)}
                        </Grid>

                        <Grid item xs={2}>
                            <div className={"material__ChatIcon"}>
                                <QuestionAnswerIcon></QuestionAnswerIcon>
                            </div>
                        </Grid>
                        <Grid item xs={2} className={"material__ChatIconLabel"}>
                            {countInArray(material.comentarios)}
                        </Grid>
                        <Grid item xs={2}>
                            <div className={classes.material__DocExtensionIconBot}>
                                <InsertDriveFileIcon></InsertDriveFileIcon>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    );
}
