import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useDropzone } from "react-dropzone";
// import CircularProgress from "@mui/material/CircularProgress";
import {
  // Button,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import FileCopyIcon from "@mui/icons-material/FileCopy";

const useStyles = makeStyles(theme => ({
  root: {},
  dropZone: props => ({
    margin: "auto",
    padding: theme.spacing(6),
    outline: "none",
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    alignItems: "center",
    background: "#ededed !important",
    border: `2px solid ${props.color}`,
    borderStyle: 'dotted',
    borderRadius: 8,
    "&:hover": {
      cursor: "pointer",
      background: "#e3e3e3 !important",
    },
  }),
  dragActive: {
    backgroundColor: theme.palette.action.active,
    opacity: 0.5,
  },
  actions: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-end",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
  "@media (max-width:480px)": {
    // eslint-disable-line no-useless-computed-key
    dropZone: {
      width: "auto%",
    },
  },
}));

function FilesDropzone({ className, setFileInfo, color, archivo, ...rest }) {
  const classes = useStyles({ color });
  const [file, setFile] = useState(archivo ? archivo : null);
  // const [loading, setLoading] = useState(false);

  const handleDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        setFile(acceptedFiles[0]);
        //pasar a subir manual, y enviar como file acceptedFiles[0]
        setFileInfo(acceptedFiles[0].type, acceptedFiles[0]);
      }
    },
    [setFileInfo]
  );

  // const handleRemove = () => {
  //   setFile(null);
  // };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    maxFiles: 1,
  });

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <div
        className={clsx({
          [classes.dropZone]: true,
          [classes.dragActive]: isDragActive,
        })}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <div>
          <Typography color="textPrimary" variant="body1">
            {file ? <div>
              <List className={classes.list}>
                <ListItem>
                  <ListItemIcon>
                    <FileCopyIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={file.name}
                    primaryTypographyProps={{ variant: "body2" }}
                  />
                </ListItem>
              </List>
              <div>
                <Link underline="always">Subir nuevo archivo</Link>
              </div>
            </div>
              :
              <div>Arrastra tu archivo o haz click para{" "}
                <Link underline="always">buscar</Link> en tu ordenador
              </div>
            }
          </Typography>
        </div>
      </div>
    </div>
  );
}

FilesDropzone.propTypes = {
  className: PropTypes.string,
};

FilesDropzone.defaultProps = {
  className: "",
};

export default FilesDropzone;
