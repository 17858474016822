import React from "react";
import Skeleton from "react-loading-skeleton";

class SkeletonManual extends React.Component {
  render() {
    return <Skeleton className="manual__skeleton" />;
  }
}

export default SkeletonManual;
