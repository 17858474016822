import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  TextField,
  Grid,
  MenuItem,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { getRequest, postRequest, deleteRequest } from "../../../utils/axios";
import { withSnackbar } from "notistack";
import { CustomerButton } from "../../../common/buttons";

const styles = (theme) => ({
  contenido: {
    paddingLeft: "32px",
  },
  add_button: {
    margin: "30px 0",
  },
  "@media (max-width:480px)": {
    // eslint-disable-line no-useless-computed-key
    contenido: {
      padding: "0",
    },
  },
});

class Area extends React.Component {
  state = {
    area: this.props.area,
  };
  render() {
    const { theme } = this.props;
    return (
      <Grid container style={{ margin: 5 }} alignItems="center">
        <Grid item xs={6} md={6}>
          <li>{this.state.area}</li>
        </Grid>
        <Grid item xs={6} md={6}>
          <CustomerButton
            type="submit"
            color={theme.eliminar}
            text={"Eliminar"}
            handle={() => this.props.handleDeleteArea(this.state.area)}
          />
        </Grid>
      </Grid>
    );
  }
}

class AgregarArea extends React.Component {
  state = {
    area: "Seleccionar",
    areas: [{ codigo: 0, nombre: "Seleccionar" }],
    myareas: this.props.areas,
    handleClose: this.props.handleClose,
    handleAddArea: this.props.handleAddArea,
    error: "",
  };

  componentDidMount() {
    const component = this;
    async function getAreas() {
      getRequest(
        `${process.env.REACT_APP_API_URL}/api/curriculum_escolar/areas/`
      ).then((response) => {
        const res_areas = response.data;
        res_areas.unshift({ codigo: 0, nombre: "Seleccionar" });
        const newAreas = component.getAreastoAdd(
          component.state.myareas,
          res_areas
        );
        component.setState({ areas: newAreas });
      });
    }
    getAreas();
  }

  getAreastoAdd(myareas, allAreas) {
    const filtered = allAreas.filter((area) => {
      return myareas.indexOf(area.nombre) === -1;
    });
    return filtered;
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleClose = (e) => {
    this.state.handleClose();
  };

  handleAddArea = async (area) => {
    if (area !== "Seleccionar") {
      await this.state.handleAddArea(area);
      this.setState({ error: "" });
    } else {
      this.setState({ error: "Debe seleccionar un area" });
    }
  };

  render() {
    const { theme } = this.props;
    return (
      <div>
        <h4>Nueva area</h4>
        <Grid container spacing={1}>
          <Grid item xs={6} md={6}>
            <TextField
              name="area"
              variant="outlined"
              size="small"
              select
              value={this.state.area}
              onChange={this.onChange}
              fullWidth={true}
              error={Boolean(this.state.error)}
              helperText={this.state.error}
            >
              {this.state.areas.map((area) => (
                <MenuItem key={area.codigo} value={area.nombre}>
                  {area.nombre}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6} md={6}>
            <CustomerButton
              variant="contained"
              color={theme.guardar}
              text={"Guardar"}
              handle={() => this.handleAddArea(this.state.area)}
            />
            <Button
              variant="contained"
              color="secondary"
              onClick={this.handleClose}
              style={{ marginLeft: 3 }}
            >
              Cancelar
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }
}
class EditarAreas extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: this.props.openModal,
      handleClick: this.props.handleClick,
      agregar: false,
      updateUsuario: this.props.updateUsuario,
    };
  }

  handleAddArea = async (area) => {
    const data = { area };
    const res = await postRequest(
      `${process.env.REACT_APP_API_URL}/api/usuarios/profesores/${this.props.usuario.id}/areas/`,
      data
    );
    if (res.status === 201) {
      await this.props.updateUsuario();
    } else {
      this.props.enqueueSnackbar(`No se pudo agregar el area ${area}`);
    }
    this.handleCloseAddArea();
  };

  handleDeleteArea = async (areaAEliminar) => {
    const res = await deleteRequest(
      `${process.env.REACT_APP_API_URL}/api/usuarios/profesores/${this.props.usuario.id}/areas/${areaAEliminar}/`
    );
    if (res.status === 200) {
      await this.props.updateUsuario();
    } else {
      this.props.enqueueSnackbar(
        `No se pudo eliminar el area ${areaAEliminar}`
      );
    }
  };

  handleCloseModal = () => {
    this.state.handleClick(false);
  };

  handleCloseAddArea = () => {
    this.setState({ agregar: false });
  };

  handleAddButton = () => {
    this.setState({ agregar: true });
  };

  render() {
    const { classes, theme } = this.props;
    const areas = this.props.usuario.usuario_asociado.area;
    return (
      <div>
        <Dialog
          open={this.state.openModal}
          onClose={this.handleCloseModal}
          aria-labelledby="responsive-dialog-title"
          fullWidth={true}
          maxWidth="md"
        >
          <DialogTitle id="responsive-dialog-title">Editar áreas</DialogTitle>
          <DialogContent>
            <Grid container className={classes.contenido}>
              {areas.map((area) => (
                <Area
                  key={area}
                  area={area}
                  theme={theme}
                  handleDeleteArea={this.handleDeleteArea}
                />
              ))}
            </Grid>
            {this.state.agregar ? (
              <AgregarArea
                theme={theme}
                areas={areas}
                handleClose={this.handleCloseAddArea}
                handleAddArea={this.handleAddArea}
              />
            ) : (
              <Grid container className={classes.add_button}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={this.handleAddButton}
                >
                  Agregar
                </Button>
              </Grid>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={this.handleCloseModal}
              variant="contained"
              color="secondary"
            >
              Salir
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withSnackbar(EditarAreas)
);
