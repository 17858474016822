import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  MenuItem,
  DialogContent,
  TextField,
  Grid,
} from "@mui/material";
import { getRequest, postRequest, deleteRequest } from "../../../utils/axios";
import { withStyles } from "@mui/styles";
import { CustomerButton } from "../../../common/buttons";

const styles = (theme) => ({
  contenido: {
    paddingLeft: "32px",
  },
  add_button: {
    margin: "30px 0",
  },
  link_text: {
    color: theme.primary,
    textDecoration: "underline",
    "&:hover, &:focus": {
      cursor: "pointer",
    },
  },
  "@media (max-width:480px)": {
    // eslint-disable-line no-useless-computed-key
    contenido: {
      padding: "0",
    },
  },
});

class Especialidad extends React.Component {
  state = {
    especialidad: this.props.especialidad,
  };
  render() {
    const { theme } = this.props;
    return (
      <Grid container spacing={1} style={{ margin: 5 }} alignItems="center">
        <Grid item>
          <li>{this.state.especialidad}</li>
        </Grid>
        <Grid item>
          <CustomerButton
            type="submit"
            color={theme.eliminar}
            text={"Eliminar"}
            handle={() =>
              this.props.handleDeleteEspecialidad(this.state.especialidad)
            }
          />
        </Grid>
      </Grid>
    );
  }
}

class AgregarEspecialidad extends React.Component {
  state = {
    especialidad: "Seleccionar",
    especialidades: [{ nombre: "Seleccionar" }],
    myespecialidades: this.props.especialidades,
    handleClose: this.props.handleClose,
    handleAddEspecialidad: this.props.handleAddEspecialidad,
    error: "",
    addNewEspecialidadForm: false,
  };

  componentDidMount() {
    this.getEspecialidades();
  }

  async getEspecialidades() {
    getRequest(
      `${process.env.REACT_APP_API_URL}/api/usuarios/profesores/especialidades/`
    ).then((response) => {
      const res_especialidades = response.data;
      res_especialidades.unshift({ nombre: "Seleccionar" });
      const newEspecialidades = this.getEspecialidadesToAdd(
        this.state.myespecialidades,
        res_especialidades
      );
      this.setState({ especialidades: newEspecialidades });
    });
  }

  getEspecialidadesToAdd(myespecialidades, allEspecialidades) {
    const filtered = allEspecialidades.filter((especialidad) => {
      return myespecialidades.indexOf(especialidad.nombre) === -1;
    });
    return filtered;
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleAddEspecialidad = async (especialidad) => {
    if (especialidad !== "Seleccionar") {
      if (
        this.state.addNewEspecialidadForm &&
        this.state.especialidad.length > 100
      ) {
        this.setState({ error: "Debe ser menor a 100 caracteres" });
        return;
      }
      if (
        this.state.addNewEspecialidadForm &&
        this.state.especialidad.length < 4
      ) {
        this.setState({ error: "Debe ser mayor a 4 caracteres" });
        return;
      }
      await this.state.handleAddEspecialidad(especialidad);
      if (this.state.addNewEspecialidadForm) {
        this.getEspecialidades();
      }
      this.setState({ error: "" });
    } else {
      this.setState({ error: "Debe seleccionar un especialidad" });
    }
  };

  changeForm = (boolean) => {
    const especialidad = boolean ? "" : "Seleccionar";
    this.setState({ addNewEspecialidadForm: boolean, especialidad });
  };

  render() {
    const { theme, classes } = this.props;
    return (
      <div>
        <h4>Nueva especialidad</h4>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            {this.state.addNewEspecialidadForm ? (
              <TextField
                name="especialidad"
                variant="outlined"
                size="small"
                value={this.state.especialidad}
                onChange={this.onChange}
                fullWidth={true}
                error={Boolean(this.state.error)}
                helperText={this.state.error}
              />
            ) : (
              <TextField
                name="especialidad"
                variant="outlined"
                size="small"
                select
                value={this.state.especialidad}
                onChange={this.onChange}
                fullWidth={true}
                error={Boolean(this.state.error)}
                helperText={this.state.error}
              >
                {this.state.especialidades.map((especialidad) => (
                  <MenuItem
                    key={especialidad.nombre}
                    value={especialidad.nombre}
                  >
                    {especialidad.nombre}
                  </MenuItem>
                ))}
              </TextField>
            )}
            {this.state.addNewEspecialidadForm ? (
              <p
                className={classes.link_text}
                onClick={() => this.changeForm(false)}
              >
                Seleccionar especialidad
              </p>
            ) : (
              <p
                className={classes.link_text}
                onClick={() => this.changeForm(true)}
              >
                Agregar nueva especialidad
              </p>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomerButton
              variant="contained"
              color={theme.guardar}
              text={"Guardar"}
              handle={() =>
                this.handleAddEspecialidad(this.state.especialidad)
              }
            />
            <Button
              variant="contained"
              color="secondary"
              onClick={this.state.handleClose}
              style={{ marginLeft: 3 }}
            >
              Cancelar
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }
}
class EditarAreas extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: this.props.openModal,
      handleClick: this.props.handleClick,
      agregar: false,
      updateUsuario: this.props.updateUsuario,
    };
  }

  handleAddEspecialidad = async (especialidad) => {
    const data = { especialidad };
    const res = await postRequest(
      `${process.env.REACT_APP_API_URL}/api/usuarios/profesores/${this.props.usuario.id}/especialidades/`,
      data
    );
    if (res.status === 201) {
      await this.props.updateUsuario();
    } else {
      this.props.enqueueSnackbar(
        `No se pudo agregar la especialidad ${especialidad} al perfil`
      );
    }
    this.handleAddEspecialidadButton(false);
  };

  handleDeleteEspecialidad = async (especialidadAEliminar) => {
    const res = await deleteRequest(
      `${process.env.REACT_APP_API_URL}/api/usuarios/profesores/${this.props.usuario.id}/especialidades/${especialidadAEliminar}/`
    );
    if (res.status === 200) {
      await this.props.updateUsuario();
    } else {
      this.props.enqueueSnackbar(
        `No se pudo eliminar la especialidad ${especialidadAEliminar} del perfil`
      );
    }
  };

  handleCloseModal = () => {
    this.state.handleClick(false);
  };

  handleAddEspecialidadButton = (status) => {
    this.setState({ agregar: status });
  };

  render() {
    const { classes, theme } = this.props;
    const especialidades = this.props.usuario.usuario_asociado.especialidad;
    return (
      <div>
        <Dialog
          open={this.state.openModal}
          onClose={this.handleCloseModal}
          aria-labelledby="responsive-dialog-title"
          fullWidth={true}
          maxWidth="md"
        >
          <DialogTitle id="responsive-dialog-title">
            Editar especialidades
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={1} className={classes.contenido}>
              {especialidades.map((especialidad) => (
                <Especialidad
                  key={especialidad}
                  theme={theme}
                  especialidad={especialidad}
                  handleDeleteEspecialidad={this.handleDeleteEspecialidad}
                />
              ))}
            </Grid>
            {this.state.agregar && especialidades.length < 5 ? (
              <AgregarEspecialidad
                theme={theme}
                classes={classes}
                especialidades={especialidades}
                handleClose={() => this.handleAddEspecialidadButton(false)}
                handleAddEspecialidad={this.handleAddEspecialidad}
              />
            ) : especialidades.length < 5 ? (
              <Grid container className={classes.add_button}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => this.handleAddEspecialidadButton(true)}
                >
                  Agregar
                </Button>
              </Grid>
            ) : null}
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={this.handleCloseModal}
              variant="contained"
              color="secondary"
            >
              Salir
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(EditarAreas);
