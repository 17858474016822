import React from "react";
import { Button, Grid, TextField } from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import { compose } from "redux";
import { createStyles } from "@mui/styles";
import { deltaTime, cutText, thousandSeparatorShort } from "../../utils/utils";
import { getRandomColor } from "../../utils/styleUtils";
import "./RespuestasConversacionHijoNivel1.css";
import {
    MeGustaSVG,
    MeInteresaSVG,
} from "../../common/svgIcon";
import RespuestasConversacionHijoNivel2 from "./RespuestasConversacionHijoNivel2"
import { getRequest, postRequest } from "../../utils/axios";

const styles = (theme) => ({

});

class RespuestasConversacionHijoNivel1 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nuevo_comentario: "",
            submited: false,
            loading: false,
            boton_responder: false,
            lista_reacciones: [],
            n_me_gusta: this.props.comentario.n_me_gusta,
            n_me_interesa: this.props.comentario.n_me_interesa,
        };
    }

    componentDidMount() {
        let component = this;

        async function getListaReacciones() {
            await getRequest(
              `${process.env.REACT_APP_API_URL}/api/foro/comentario/likes_usuario/`,
              {
                  id_comentario: component.props.comentario.id
              }
            )
              .then((response) => {
                  component.setState({
                      lista_reacciones: response.data.lista_reacciones,
                  });
              })
              .catch((e) => {
                  console.log(e);
              });
        }

        getListaReacciones();
    }

    handleLikeComentario = async (id_like) => {
        if (this.state.lista_reacciones.includes(id_like)) { // Handle dislike
            await postRequest(
              `${process.env.REACT_APP_API_URL}/api/foro/comentario/like/`,
              {
                  id_comentario: this.props.comentario.id,
                  id_like: id_like,
                  action: "remove_like"
              }
            )
              .then(async (response) => {
                  if (response.status === 200) {
                      let lista_actual_reacciones = this.state.lista_reacciones
                      lista_actual_reacciones.splice(lista_actual_reacciones.indexOf(id_like), 1)
                      switch (response.data.id_like) {
                          case 1:
                              this.setState({ lista_reacciones: [...lista_actual_reacciones], n_me_gusta: response.data.contador })
                              break
                          case 3:
                              this.setState({ lista_reacciones: [...lista_actual_reacciones], n_me_interesa: response.data.contador })
                              break
                          default:
                              break
                      }
                  }
              })
              .catch((e) => {
                  console.log(e);
              });
        } else { // Handle like
            await postRequest(
              `${process.env.REACT_APP_API_URL}/api/foro/comentario/like/`,
              {
                  id_comentario: this.props.comentario.id,
                  id_like: id_like,
                  action: "like"
              }
            )
              .then(async (response) => {
                  if (response.status === 200) {
                      let lista_actual_reacciones = this.state.lista_reacciones
                      lista_actual_reacciones.push(id_like)
                      switch (response.data.id_like) {
                          case 1:
                              this.setState({ lista_reacciones: [...lista_actual_reacciones], n_me_gusta: response.data.contador })
                              break
                          case 3:
                              this.setState({ lista_reacciones: [...lista_actual_reacciones], n_me_interesa: response.data.contador })
                              break
                          default:
                              break
                      }
                  }
              })
              .catch((e) => {
                  console.log(e);
              });
        }
    }

    getListaReacciones = async () => {
        await getRequest(
          `${process.env.REACT_APP_API_URL}/api/foro/comentario/likes_usuario/`,
          {
              id_comentario: this.props.comentario.id
          }
        )
          .then((response) => {
              this.setState({
                  lista_reacciones: response.data.lista_reacciones,
              });
          })
          .catch((e) => {
              console.log(e);
          });
    }

    handleEscribirNuevoComentario = (e) => {
        this.setState({ nuevo_comentario: e.target.value })
    }

    handlePostNuevoComentario = async (e) => {
        e.preventDefault();
        this.setState({ loading: true });
        let response;
        try {
            response = await postRequest(
              `${process.env.REACT_APP_API_URL}/api/foro/conversacion/comentar/`,
              {
                  id_conversacion: this.props.conversacion.id,
                  id_categoria: this.props.categoria.id,
                  contenido: this.state.nuevo_comentario,
                  comentario_padre: this.props.comentario.id
              }
            );

            if (response.status === 201) {
                this.setState({
                    submited: true,
                });
                // this.props.enqueueSnackbar(
                //     `Comentario agregado exitosamente!`
                // );
            }
        } catch (e) {
            console.log(e);
            // this.props.enqueueSnackbar("Hubo un error al subir el comentario");
        } finally {
            this.setState({ loading: false, nuevo_comentario: "", boton_responder: false }, () => {
                this.props.getComentarios()
            });
        }
    }

    render() {
        const { theme, usuario } = this.props;

        const styles = createStyles({
            ContainerComentarioHijoNivel1: {
                // padding: "20px 10px 20px 10px",
                width: "calc(100% - 70px)",
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                alignItems: "center",
                justifyContent: "space-between",
                fontSize: 16,
                fontWeight: 600,
                backgroundColor: "transparent",
                margin: "10px 0px 10px 0px",
                textAlign: "left",
            },
            ContainerNewPostHijo1: {
                width: "100%",
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                alignItems: "center",
                justifyContent: "space-between",
                fontSize: 16,
                fontWeight: 600,
                backgroundColor: "transparent",
                margin: "10px 0px 10px 0px",
                textAlign: "left",
            }
        });


        return (
          <div style={styles.ContainerComentarioHijoNivel1}>
              <div className="autor_fecha_container_nuevocomentario">
                  <div className="autor_fecha_subcontainer_nuevocomentario">
                      <div style={{ display: "flex", alignItems: "stretch", flexDirection: "row", flexWrap: "wrap", width: "60px", marginRight: "10px" }}>

                          {!["", null, undefined].includes(this.props.comentario.id_usuario.foto_perfil) ? (
                            <img
                              src={`${process.env.REACT_APP_API_URL}/miniaturas/usuarios_min/${this.props.comentario.id_usuario.id}`}
                              className="usuario_icon foro_profile_comment"
                              alt="foto"
                            />
                          ) : (
                            <AccountCircle style={{ fontSize: "60px", height: "fit-content !important" }} />
                          )}

                          {
                              this.props.comentario.respuestas.length === 0 ? <div style={{ width: "30px" }} />
                                :
                                <div style={{ display: "flex", height: "-webkit-fill-available", width: "30px", marginRight: "-1px", borderRight: `1px solid ${theme.grisMuyOscuro}` }} />
                          }

                      </div>
                      <div className={"post__nuevoComentarioAutorComentario__Container"}>
                          <div className={"post__nuevoComentarioAutor"} style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                              <p style={{ display: "flex", padding: 0, margin: "0px 5px 0px 0px" }}>{this.props.comentario.id_usuario.primer_nombre + " " + this.props.comentario.id_usuario.apellido_paterno}</p>
                              <p style={{ color: theme.grisMuyOscuro }} className="fecha_comentario_hijonivel1">{deltaTime(this.props.comentario.fecha_creacion)}</p>
                          </div>
                          {/* <br /> */}
                          {/* <div className={"post__VisitasFecha"}>
                            {deltaTime(this.props.comentario.fecha_creacion)}
                        </div> */}
                          <div className={"post__nuevoComentario"} style={{ display: "block", whiteSpace: "pre-line" }}>
                              {this.props.comentario.contenido}
                          </div>
                      </div>
                  </div>
              </div>
              <div className={"post__comentario__btns_hijonivel1"}>
                  {
                      this.props.lastChild | this.props.comentario.respuestas.length === 0 ? <div style={{ width: "29px" }} />
                        :
                        <div style={{ width: "29px", marginRight: "-1px", borderRight: `1px solid ${theme.grisMuyOscuro}` }} />
                  }
                  {
                      this.props.lastChild | this.props.comentario.respuestas.length === 0 ? <div style={{ width: "41px" }} />
                        :
                        <div style={{ width: "41px", marginRight: "2px", borderLeft: `1px solid transparent`, borderBottom: `1px solid transparent`, borderRadius: "0px 0px 0px 15px", height: "35px" }} />
                  }
                  <MeInteresaSVG style={{ height: "25px", cursor: "pointer", filter: this.state.lista_reacciones.includes(3) ? "contrast(100%)" : "contrast(0%)" }} onClick={() => this.handleLikeComentario(3)} />
                  <p style={{ display: "flex", padding: 0, margin: "0px 0px 0px 0px", color: theme.grisMuyOscuro, width: "54px", alignContent: "center", justifyContent: "center" }}>{thousandSeparatorShort(this.state.n_me_interesa)}</p>
                  <MeGustaSVG style={{ height: "25px", cursor: "pointer", filter: this.state.lista_reacciones.includes(1) ? "contrast(100%)" : "contrast(0%)" }} onClick={() => this.handleLikeComentario(1)} />
                  <p style={{ display: "flex", padding: 0, margin: "0px 0px 0px 0px", color: theme.grisMuyOscuro, width: "54px", alignContent: "center", justifyContent: "center" }}>{thousandSeparatorShort(this.state.n_me_gusta)}</p>

                  <a
                    style={{ minWidth: "152.66px", marginTop: "0px", marginLeft: "10px !improtant", color: theme.grisMuyOscuro, cursor: "pointer" }}
                    onClick={() => this.setState({ boton_responder: !this.state.boton_responder })}
                  >
                      Responder
                  </a>
              </div>
              {
                  this.state.boton_responder ?
                    (
                      <div style={styles.ContainerNewPostHijo1}>
                          <div style={{ width: "30px", marginRight: "-1px", borderRight: `1px solid ${theme.grisMuyOscuro}` }} />
                          <div style={{ width: "40px", marginRight: "2px", borderLeft: `1px solid transparent`, borderBottom: `1px solid transparent`, borderRadius: "0px 0px 0px 15px", height: "35px" }} />

                          <div className="autor_fecha_container_nuevocomentario">
                              <div className="autor_fecha_subcontainer_nuevocomentario">
                                  <div className={"post__IconContainer"}>
                                      {!["", null, undefined].includes(this.props.comentario.id_usuario.foto_perfil) ? (
                                        <img
                                          src={`${process.env.REACT_APP_API_URL}/miniaturas/usuarios_min/${this.props.comentario.id_usuario.id}`}
                                          className="usuario_icon foro_profile_comment"
                                          alt="foto"
                                        />
                                      ) : (
                                        <AccountCircle style={{ fontSize: "60px", height: "fit-content !important" }} />
                                      )}
                                  </div>
                                  <div className={"post__nuevoComentarioAutorComentario__Container"}>
                                      <div className={"post__nuevoComentarioAutor"}>
                                          {usuario.primer_nombre + " " + usuario.apellido_paterno}
                                      </div>
                                      {/* <br /> */}
                                      <div className={"post__nuevoComentario"}>
                                          <TextField
                                            // label="Agregar comentario"
                                            style={{ margin: "0px 10px 0px 0px" }}
                                            placeholder="Escribe algo ..."
                                            fullWidth
                                            // margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="standard"
                                            multiline
                                            value={this.state.nuevo_comentario}
                                            onChange={this.handleEscribirNuevoComentario}
                                          />
                                      </div>
                                  </div>
                              </div>
                          </div>
                          {this.state.nuevo_comentario !== "" ?
                            <div className={"post__nuevoComentarioButton__Container"}>
                                <Button
                                  color="secondary"
                                  variant="contained"
                                  style={{ maxWidth: "fit-content", margin: "10px 10px 0px 0px" }}
                                  onClick={() => this.setState({ nuevo_comentario: "", boton_responder: !this.state.boton_responder })}
                                >
                                    Borrar
                                </Button>
                                <Button
                                  color="primary"
                                  variant="contained"
                                  style={{ maxWidth: "fit-content", marginTop: "10px" }}
                                  onClick={this.handlePostNuevoComentario}
                                >
                                    Comentar
                                </Button>
                            </div>
                            : null
                          }
                      </div>
                    )
                    :
                    null
              }
              <div style={{ width: "100%" }}>
                  {this.props.comentario.respuestas.map((comentario, index) => {
                      return (
                        // <Grid container spacing={0} key={comentario.id} style={{ width: "100%" }}>
                        <div key={comentario.id} style={{ width: "100%", display: "flex", alignItems: "stretch", flexDirection: "row" }}>
                            <div style={{ width: "30px", marginRight: "-1px", borderRight: index === this.props.comentario.respuestas.length - 1 ? "1px solid transparent" : `1px solid ${theme.grisMuyOscuro}` }} />
                            <div style={{ width: "40px", marginRight: "2px", borderLeft: `1px solid ${theme.grisMuyOscuro}`, borderBottom: `1px solid ${theme.grisMuyOscuro}`, borderRadius: "0px 0px 0px 15px", height: "35px" }} />
                            <RespuestasConversacionHijoNivel2 style={{ width: "100%" }} comentario={comentario} comentario_padre={this.props.comentario} categoria={this.props.categoria} conversacion={this.props.conversacion} getComentarios={this.props.getComentarios} lastChild={index === this.props.comentario.respuestas.length - 1 ? true : false} />
                        </div>
                      )
                  })}
              </div>
          </div>
        )
    }
}

const mapStateToProps = (state) => ({
    usuario: state.auth.usuario,
});

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, {})
)(RespuestasConversacionHijoNivel1);

